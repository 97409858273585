// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { FormTemplate } from '@data-driven-forms/mui-component-mapper';
import { FormRenderer } from '@data-driven-forms/react-form-renderer';
import type { Schema } from '@data-driven-forms/react-form-renderer';
import { ThemeProvider } from '@material-ui/core/styles';
import type { Nullable, StudentProfileId } from '@polygence/common';
import * as surveyApi from '@polygence/common/api/survey';
import { useOtherParticipants } from '@polygence/common/hooks/selectors/useOtherParticipants';
import useSelectedProject from '@polygence/common/hooks/selectors/useSelectedProject';
import type { CounselorProfileId } from '@polygence/common/types/common';
import type { EditableForm } from '@polygence/common/types/survey';
import { Button, Icon, Card, Alert, Text, Heading } from '@polygence/components';
import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { CounselorStudentSelect } from 'src/components/counselor/CounselorStudentSelect';
import componentMapper from 'src/components/data-driven-forms/component-mapper';
import createPolygenceTheme from 'src/components/data-driven-forms/theme';
import styles from 'src/components/hermes/SupportWidget/SupportWidget.module.sass';
import { FormContextProvider } from 'src/providers/FormProvider';

const theme = createPolygenceTheme({
  overrides: {
    MuiInputLabel: {
      formControl: {
        marginRight: '1rem',
      },
    },
    MuiInput: {
      formControl: {
        'label + &': {
          marginTop: '1.5rem',
        },
      },
    },
  },
});

const SupportWidgetForm = ({
  formData,
  additionalData,
  showSuccessMessage,
}: {
  formData: EditableForm;
  additionalData: Record<string, unknown>;
  showSuccessMessage: () => void;
}) => {
  const project = useSelectedProject();
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (data: Record<string, unknown>) => {
    if (loading) {
      return;
    }

    setLoading(true);
    surveyApi
      .postSupportWidgetForm({
        data: { ...data, ...additionalData },
        projectId: project?.id,
      })
      .then(() => {
        showSuccessMessage();
      })
      .catch(() => toast.error('Failed to send form response.'))
      .finally(() => setLoading(false));
  };

  return (
    <FormContextProvider name={`support_request_${project?.id || ''}}`}>
      <ThemeProvider theme={theme}>
        <FormRenderer
          componentMapper={componentMapper}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          FormTemplate={FormTemplate}
          schema={formData.fields as Schema}
          onSubmit={onSubmit}
          clearOnUnmount
        />
      </ThemeProvider>
    </FormContextProvider>
  );
};

export const SupportWidget = ({
  formName = 'support-widget',
  className,
  pilotProjectId,
  counselorProfileId,
}: {
  formName?: string;
  className?: string;
  pilotProjectId?: number;
  counselorProfileId?: CounselorProfileId;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedPartnerStudentId, setSelectedPartnerStudentId] =
    useState<Nullable<StudentProfileId>>(null);
  const [formData, setFormData] = useState<EditableForm>();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const otherParticipants = useOtherParticipants();
  const names = otherParticipants.map(({ firstName }) => firstName).join(', ');

  useEffect(() => {
    surveyApi
      .getSupportWidgetForm(formName)
      .then((res) => setFormData(res.data))
      .catch(() => console.error('Error loading support form.'));
  }, [formName]);

  const handleSelectStudent = (studentProfileId: StudentProfileId) => {
    setSelectedPartnerStudentId(studentProfileId);
  };

  if (!formData) {
    return null;
  }

  return (
    <div className={classNames(styles['widgetContainer'], className)}>
      {open ? (
        <Card body className={styles['card']}>
          <Button
            variant="link"
            className={styles['close']}
            startIcon={
              <Icon
                id="x"
                onClick={() => {
                  setOpen(false);
                  setIsSubmitted(false);
                }}
              />
            }
          />
          {!counselorProfileId && <Heading size="h4">Contact us</Heading>}
          {names && <Heading size="h5">about project with {names}</Heading>}
          {counselorProfileId && !isSubmitted && (
            <>
              <Heading size="h5">I need help with one of my students</Heading>
              <div className="mt-10 mb-n3">
                <CounselorStudentSelect
                  counselorProfileId={counselorProfileId}
                  onChange={handleSelectStudent}
                />
              </div>
            </>
          )}
          {!isSubmitted ? (
            <SupportWidgetForm
              formData={formData}
              additionalData={{ pilotProjectId, counselorProfileId, selectedPartnerStudentId }}
              showSuccessMessage={() => setIsSubmitted(true)}
            />
          ) : (
            <Alert variant="success" className="text-center mt-5">
              <Icon id="check-circle" />
              <Text size="medium" fontWeight="bold" alignment="center">
                Thanks for reaching out
              </Text>
              Someone will get back to you soon
            </Alert>
          )}
        </Card>
      ) : (
        <Button
          variant="tertiary"
          startIcon={<Icon id="help-circle" />}
          onClick={() => {
            setOpen(true);
          }}
        >
          Support
        </Button>
      )}
    </div>
  );
};
