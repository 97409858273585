import type { UserInfoToken } from '..';

const ROLE_SUPER_ADMIN = 'super_admin';
const ROLE_MENTOR_AMBASSADOR = 'mentor_ambassador';
const ROLE_ADMISSION_ADVISOR = 'admission_advisor';
const ROLE_MENTORS_TAB = 'mentors_tab';
const ROLE_MENTORS_PIPELINE_TAB = 'mentors_pipeline_tab';
const ROLE_PIPELINE_TAB = 'pipeline_tab';
const IMPERSONATOR = 'impersonator';
const ROLE_CONTENT_EDITOR = 'content_editor';
const ROLE_PARTNERSHIP_MANAGER = 'partnership_manager';
const ROLE_ROLE_MANAGER = 'role_manager';
const ROLE_DIAGNOSTICIAN = 'diagnostician';
const ROLE_DISCOUNT_MANAGER = 'discount_manager';
const ROLE_ADMISSION_MANAGER = 'admission_manager';
const ROLE_POLYGPT_ADMIN = 'polygpt_admin';
const ROLE_SHOWCASING_EDITOR = 'showcasing_editor';
const ROLE_POLYPILOT_MENTOR = 'polypilot_mentor';
const ROLE_POLYPILOT_TESTER = 'polypilot_tester';
const DEVELOPER = 'developer';

export function hasSomeAuthorizedRoles(
  authorizedRoles: readonly string[],
  actualRoles: string[] | null,
): boolean {
  if (!actualRoles) {
    return false;
  }
  return actualRoles.some((role) => {
    return authorizedRoles.includes(role);
  });
}

export const isAdmin = ({ roles = [] }: { roles: string[] }) => {
  return hasSomeAuthorizedRoles([ROLE_SUPER_ADMIN], roles);
};

export const isImpersonator = ({ roles = [] }: { roles: string[] }) => {
  return hasSomeAuthorizedRoles([IMPERSONATOR], roles);
};

export const canAccessAdminStudentOverview = (roles: string[] = []): boolean => {
  return hasSomeAuthorizedRoles([ROLE_PIPELINE_TAB, ROLE_SUPER_ADMIN], roles);
};

export const isRoleManager = ({ roles = [] }: { roles: string[] }): boolean => {
  return hasSomeAuthorizedRoles([ROLE_ROLE_MANAGER], roles);
};

export const isIntern = (info: UserInfoToken) => {
  return info.is_intern;
};

export const isDiagnostician = ({ roles = [] }: { roles: string[] }): boolean => {
  return hasSomeAuthorizedRoles([ROLE_DIAGNOSTICIAN], roles);
};

export const isDiscountManager = ({ roles = [] }: { roles: string[] }): boolean => {
  return hasSomeAuthorizedRoles([ROLE_DISCOUNT_MANAGER], roles);
};

export const isAdmissionManager = ({ roles = [] }: { roles: string[] }): boolean => {
  return hasSomeAuthorizedRoles([ROLE_ADMISSION_MANAGER], roles);
};

export const isPolyGPTAdmin = ({ roles = [] }: { roles: string[] }): boolean => {
  return hasSomeAuthorizedRoles([ROLE_POLYGPT_ADMIN], roles);
};

export const isShowcasingEditor = ({ roles = [] }: { roles: string[] }): boolean => {
  return hasSomeAuthorizedRoles([ROLE_SHOWCASING_EDITOR], roles);
};

export const isPolyPilotMentor = ({ roles = [] }: { roles: string[] }): boolean => {
  return hasSomeAuthorizedRoles([ROLE_POLYPILOT_MENTOR], roles);
};

export const isPolyPilotTester = ({ roles = [] }: { roles: string[] }): boolean => {
  return hasSomeAuthorizedRoles([ROLE_POLYPILOT_TESTER], roles);
};

export const isDeveloper = ({ roles = [] }: { roles: string[] }): boolean => {
  return hasSomeAuthorizedRoles([DEVELOPER], roles);
};

export const hasAdminDashboardAccess = ({ roles = [] }: { roles: string[] }) => {
  return hasSomeAuthorizedRoles(
    [
      ROLE_SUPER_ADMIN,
      ROLE_MENTOR_AMBASSADOR,
      ROLE_ADMISSION_ADVISOR,
      ROLE_MENTORS_TAB,
      ROLE_MENTORS_PIPELINE_TAB,
      ROLE_PIPELINE_TAB,
      ROLE_CONTENT_EDITOR,
      ROLE_PARTNERSHIP_MANAGER,
      ROLE_ROLE_MANAGER,
      ROLE_DIAGNOSTICIAN,
      ROLE_ADMISSION_MANAGER,
      ROLE_POLYGPT_ADMIN,
      ROLE_SHOWCASING_EDITOR,
      ROLE_POLYPILOT_MENTOR,
      DEVELOPER,
    ],
    roles,
  );
};

export const roles = {
  SUPER_ADMIN: ROLE_SUPER_ADMIN,
  MENTOR_AMBASSADOR: ROLE_MENTOR_AMBASSADOR,
  ADMISSION_ADVISOR: ROLE_ADMISSION_ADVISOR,
  MENTORS_TAB: ROLE_MENTORS_TAB,
  MENTORS_PIPELINE_TAB: ROLE_MENTORS_PIPELINE_TAB,
  PIPELINE_TAB: ROLE_PIPELINE_TAB,
  IMPERSONATOR: IMPERSONATOR,
  CONTENT_EDITOR: ROLE_CONTENT_EDITOR,
  PARTNERSHIP_MANAGER: ROLE_PARTNERSHIP_MANAGER,
  ROLE_MANAGER: ROLE_ROLE_MANAGER,
  DIAGNOSTICIAN: ROLE_DIAGNOSTICIAN,
  DISCOUNT_MANAGER: ROLE_DISCOUNT_MANAGER,
  ADMISSION_MANAGER: ROLE_ADMISSION_MANAGER,
  POLYGPT_ADMIN: ROLE_POLYGPT_ADMIN,
  SHOWCASING_EDITOR: ROLE_SHOWCASING_EDITOR,
  POLYPILOT_MENTOR: ROLE_POLYPILOT_MENTOR,
  POLYPILOT_TESTER: ROLE_POLYPILOT_TESTER,
  DEVELOPER: DEVELOPER,
};
