import { UserType } from '@polygence/common';
import type { CartUserDataVerification } from '@polygence/common/types/payment';
import { Spacer, Text, Heading, Card, Button } from '@polygence/components';
import classNames from 'classnames';

import SuccessIcon from 'src/components/static/images/undraw_maker_launch_re.svg?react';
import styles from 'src/payment/PaymentSuccess/PaymentSuccess.module.scss';
import { urls } from 'src/urls';

export interface PaymentSuccessDefaultMessageProps {
  containsFlagship: boolean;
  cartUserData?: CartUserDataVerification;
}

export const PaymentSuccessDefaultMessage = ({
  containsFlagship,
  cartUserData,
}: PaymentSuccessDefaultMessageProps) => {
  return (
    <>
      <Heading size="h3" as="h1">
        {`Congratulations, let's start building your project!`}
      </Heading>
      <Spacer size={10} />
      <SuccessIcon />
      <Spacer size={9} />
      {!containsFlagship && (
        <Text size="large" alignment="center">
          We successfully received your payment.
        </Text>
      )}
      {containsFlagship && (
        <>
          <Text size="large" alignment="center">
            Thank you for your payment, we'll start looking for your mentor!
          </Text>
          {cartUserData?.userType === UserType.STUDENT && (
            <>
              <Spacer size={6} />
              <Text size="large" alignment="center">
                Confirm your information below to ensure we can find the best match possible.
              </Text>
              <Spacer size={8} />
              <Card
                className={classNames('d-flex flex-row gap-9 bg-primary-light p-9', styles['card'])}
              >
                <div>
                  <Text size="medium" fontWeight="bold">
                    Email
                  </Text>
                  <Text size="medium">{cartUserData.email}</Text>
                  <Spacer size={4} />
                  <Text size="medium" fontWeight="bold">
                    Phone number
                  </Text>
                  <Text size="medium">{cartUserData.phoneNumber}</Text>
                  <Spacer size={4} />
                  <Text size="medium" fontWeight="bold">
                    Time Zone
                  </Text>
                  <Text size="medium">{cartUserData.timeZone}</Text>
                </div>
                <div>
                  <Text size="medium" fontWeight="bold">
                    Information incorrect?
                  </Text>
                  <Spacer size={4} />
                  <Button variant="primary" href={urls.settingsPage()}>
                    Go to Settings
                  </Button>
                </div>
              </Card>
            </>
          )}
        </>
      )}
    </>
  );
};
