/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable sonarjs/cognitive-complexity */
import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';
import { externalLink } from '@polygence/common';
import { Button, Heading, Icon, InputField, Modal, Text, Tooltip } from '@polygence/components';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { usePersistentState } from 'src/hooks/usePersistentState';
import { usePolyGPTContext } from 'src/polygpt/PolyGPTContext';
import { useGetPolyPilotProjectDetailsQuery } from 'src/reducers/polyGptReducers';
import {
  polyPilotAdminApi,
  useGetValidationDeliverableOutcomeQuery,
} from 'src/reducers/polyPilotAdminReducers';
import { useAppDispatch } from 'src/store';
import type { ProjectStage } from 'src/types/polygence/polygpt';

const SEND_FEEDBACK_TOOLTIP_TIP =
  'Send student your comment but student will not progress to the next stage. PolyPilot will help them rework this stage and resubmit to you when ready.';

const APPROVE_WITH_FEEDBACK_TOOLTIP_TIP =
  'Send student your comment and progress the student to the next stage to continue working with PolyPilot.';

export const MentorFeedbackModal = NiceModal.create(
  ({ projectStage, projectId }: { projectStage: ProjectStage; projectId: number }) => {
    const [feedback, setFeedback] = usePersistentState<string | undefined>(
      '',
      `polypilot-feedback-stage-${projectStage.id}`,
    );
    const modal = useModal();
    const { data: project } = useGetPolyPilotProjectDetailsQuery(projectId);
    const { data: validationDeliverable } = useGetValidationDeliverableOutcomeQuery(
      projectStage.id,
    );
    const { websocketConnection } = usePolyGPTContext();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const projectTitle = project?.title;
    const studentName = project?.user?.fullName;

    useEffect(() => {
      if (!websocketConnection) {
        return;
      }

      function handleMessage(event: MessageEvent) {
        const data = JSON.parse(event.data);

        if (data.type === 'polygpt.mentor_feedback.success') {
          setIsLoading(false);
          setFeedback(undefined);
          toast.success('Feedback registered!');
          dispatch(polyPilotAdminApi.util.invalidateTags(['ProjectsAwaitingReview']));
          modal.hide().catch(console.error);
        }

        if (data.type === 'polygpt.mentor_feedback.error') {
          toast.error('Something went wrong.');
          setIsLoading(false);
        }
      }

      websocketConnection.addEventListener('message', handleMessage);

      return () => websocketConnection.removeEventListener('message', handleMessage);
    }, [websocketConnection, dispatch, modal, setFeedback]);

    const sendFeedback = (approved: boolean) => {
      if (isLoading || !websocketConnection) {
        return;
      }

      setIsLoading(true);

      websocketConnection.send(
        JSON.stringify({
          type: 'polygpt.mentor_feedback',
          approved,
          feedback,
          project_stage_id: projectStage.id,
        }),
      );
    };

    const [outcomeFile] = validationDeliverable?.outcomeFile ?? [];

    return (
      <Modal {...bootstrapDialog(modal)} body backdrop={isLoading ? 'static' : true}>
        <div className="d-flex flex-column gap-4">
          <Heading size="h5" as="h1">
            Feedback at {projectStage.stage.displayName}
          </Heading>
          <section className="bg-grayscale-3 rounded p-4">
            <Text size="medium" fontWeight="bold">
              {projectTitle}
            </Text>
            <Text size="medium" fontWeight="light">
              {studentName}
            </Text>
          </section>
          {validationDeliverable && (
            <section className="bg-grayscale-3 rounded p-4">
              <Text size="medium" fontWeight="bold">
                Validation deliverable{' '}
                <Tooltip tip={validationDeliverable.description}>
                  <Icon className="ms-3" id="help-circle" />
                </Tooltip>
              </Text>
              <Text size="medium" fontWeight="light">
                {validationDeliverable.outcome}
                {outcomeFile && (
                  <Button startIcon={<Icon id="file" />} href={outcomeFile?.url} {...externalLink}>
                    {projectStage.stage.displayFileName}
                  </Button>
                )}
              </Text>
            </section>
          )}
          <section>
            <InputField
              label="Feedback"
              floating={false}
              name="feedback"
              type="textarea"
              rows={15}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />
          </section>
          <section className="d-flex justify-content-end gap-3">
            <Tooltip placement="top-start" tip={SEND_FEEDBACK_TOOLTIP_TIP}>
              <Button variant="link" onClick={() => sendFeedback(false)} disabled={isLoading}>
                Send feedback
              </Button>
            </Tooltip>
            <Tooltip placement="top-start" tip={APPROVE_WITH_FEEDBACK_TOOLTIP_TIP}>
              <Button
                variant="primary"
                endIcon={isLoading ? <Icon id="loader" className="rotate" /> : <></>}
                onClick={() => sendFeedback(true)}
                disabled={isLoading}
              >
                Approve with feedback
              </Button>
            </Tooltip>
          </section>
        </div>
      </Modal>
    );
  },
);
