import { Button, Icon, Text } from '@polygence/components';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import type { CSSProperties, ElementRef } from 'react';
import type { Components } from 'react-markdown';
import type { Element } from 'react-markdown/lib';

import { useSyntaxHighlight } from 'src/components/aux/markdown/useSyntaxHighlight';

export const SyntaxHighlighter: Components['pre'] = (props) => {
  const { children, className, node, ...rest } = props;

  const isCode = node?.children?.[0]?.type === 'element' && node.children[0].tagName === 'code';
  const codeElement = isCode ? (node.children[0] as Element) : null;
  const codeString =
    codeElement?.children?.[0]?.type === 'text' ? codeElement.children[0].value : '';

  const codeRef = useRef<ElementRef<'code'>>(null);
  const match = /language-(\w+)/.exec(
    codeElement ? String(codeElement.properties['className']) : '',
  );
  const language = match ? match[1] : undefined;

  const [isHighlighted, languageGuess] = useSyntaxHighlight({
    language,
    rawCodeString: codeString,
    codeElementRef: codeRef,
  });

  if (!isCode) {
    return (
      <pre className={className} {...rest}>
        {children}
      </pre>
    );
  }

  return (
    <pre className={classNames(className, 'position-relative')}>
      {language && (
        <Text
          size="small"
          fontWeight="bold"
          className="uppercase text-muted mt-n2 mb-3 user-select-none"
        >
          {language}
        </Text>
      )}
      <CopyButton
        code={codeString}
        className="position-absolute top-0 end-0 mt-2 me-2"
        isHighlighted={isHighlighted}
      />
      <code
        className={classNames(
          codeElement?.properties['className'],
          languageGuess && `language-${languageGuess}`,
        )}
        {...rest}
        ref={codeRef}
      >
        {codeString}
      </code>
    </pre>
  );
};

const CopyButton = ({
  code,
  className,
  isHighlighted,
}: {
  code: string;
  className?: string;
  isHighlighted: boolean;
}) => {
  const [copied, setCopied] = useState(false);
  const copiedTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const onCopyClick = () => {
    setCopied(true);
    navigator.clipboard.writeText(code).catch(console.error);

    if (copiedTimeoutRef.current) {
      clearTimeout(copiedTimeoutRef.current);
    }

    copiedTimeoutRef.current = setTimeout(() => {
      setCopied(false);
    }, 1200);

    return () => clearTimeout(copiedTimeoutRef.current);
  };

  return (
    <div className={classNames('d-inline-flex align-items-center', className)}>
      {copied && (
        <Text size="small" fontWeight="bold" className="uppercase text-muted mb-1 user-select-none">
          COPIED
        </Text>
      )}
      <Button
        className="p-3"
        size="sm"
        variant="link"
        endIcon={<Icon id="copy" />}
        onClick={onCopyClick}
        style={
          {
            '--btn-color': 'var(--grayscale-6)',
            '--btn-color-hover': isHighlighted ? 'var(--grayscale-3)' : 'var(--grayscale-8)',
          } as CSSProperties
        }
      />
    </div>
  );
};
