export const FLAGSHIP_NAME = 'Flagship project';
export const PREMIUM_SHOWCASING_NAME = 'Premium Showcasing';
export const PREMIUM_SHOWCASING_COMPETING = 'Premium Showcasing - Competing';
export const PREMIUM_SHOWCASING_PRESENTING = 'Premium Showcasing - Presenting';
export const PREMIUM_SHOWCASING_OTHER = 'Premium Showcasing - Multimedia';
export const PATHFINDER_STANDALONE = 'Pathfinder Standalone';
export const PATHFINDER_LAUNCHPAD = 'Pathfinder Launchpad';
export const UCI_GATI = 'UCI x GATI';
export const PODS = 'Pods';
export const ADDITIONAL_SESSION = 'Additional Session';
export const ADDITIONAL_WRITING_FEEDBACK = 'Additional Writing Feedback';
export const ADVANCED_SCHOLAR_PROGRAM = 'Advanced Scholar Program';
export const PUBLIC_SPEAKING = 'Public Speaking & Interview Coaching';
export const ELEMENTS = 'Elements';
export const INTERNSHIP_PLACEMENT_CONSULTING = 'Internship Placement Consulting';
