import useCurrentUser from '@polygence/common/hooks/selectors/useCurrentUser';
import { UserType } from '@polygence/common/types/backend';
import { Button, Icon, Text, Tooltip } from '@polygence/components';
import classNames from 'classnames';
import { useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link, useLocation } from 'react-router-dom';

import styles from 'src/components/counselor/CounselorBanner.module.scss';
import { useLazyMyCompanyQuery } from 'src/reducers/partnershipsReducer';
import { getPartnerReferralLink } from 'src/urls';

const ReferralCard = ({ label, referralLink }: { label: string; referralLink: string | null }) => {
  return (
    <div
      className={classNames(styles['referralCard'], 'd-flex flex-row ps-6 py-4 align-items-center')}
    >
      <Icon size="sm" id="gift" color="white" className="me-3" />
      <Text size="medium" className="me-auto">
        {label}
      </Text>
      {referralLink != null && (
        <Tooltip placement="auto" tip="Copied to clipboard!" trigger={['click', 'focus']}>
          <CopyToClipboard text={referralLink}>
            <Button variant="link" size="sm">
              <Text size="medium" fontWeight="bold">
                Copy URL
              </Text>
            </Button>
          </CopyToClipboard>
        </Tooltip>
      )}
    </div>
  );
};

const ReferralCardApproved = ({ referralLink }: { referralLink: string }) => {
  return (
    <ReferralCard
      label="To apply your partner benefits, students must use this link:"
      referralLink={referralLink}
    />
  );
};

const ReferralCardUnapproved = () => {
  return (
    <ReferralCard
      label="Upon approval, you will find a link here to access partner benefits."
      referralLink={null}
    />
  );
};

const GoToStudentsSections = () => {
  const { pathname } = useLocation();

  return (
    <>
      {pathname !== '/counselor/students' ? (
        <Link
          to="/counselor/students"
          className={classNames(
            styles['goToStudentsSection'],
            'd-flex flex-row py-4 align-items-center justify-content-center',
          )}
        >
          <Icon size="sm" id="three-people" color="var(--primary-dark)" className="me-3" />
          <Text size="medium" fontWeight="semibold">
            See all My Polygence Students
          </Text>
        </Link>
      ) : (
        <a
          href="#inviteSection"
          className={classNames(
            styles['goToStudentsSection'],
            'd-flex flex-row py-4 align-items-center justify-content-center',
          )}
        >
          <Icon size="sm" id="three-people" color="var(--primary-dark)" className="me-3" />
          <Text size="medium" fontWeight="semibold">
            Invite a Student
          </Text>
        </a>
      )}
    </>
  );
};

export const CounselorBanner = () => {
  const currentUser = useCurrentUser();
  const [trigger, result] = useLazyMyCompanyQuery({});
  const { pathname } = useLocation();

  useEffect(() => {
    if (currentUser.userType === UserType.COUNSELOR) {
      void trigger();
    }
  }, [currentUser.userType, trigger]);

  if (!result.isSuccess || pathname === '/counselor/project-idea-generator') {
    return null;
  }

  const referralLink = getPartnerReferralLink(result.data.tag);

  return (
    <div className={classNames(styles['grid'], 'container mt-10')}>
      {currentUser.approved && <ReferralCardApproved referralLink={referralLink} />}
      {!currentUser.approved && <ReferralCardUnapproved />}
      <GoToStudentsSections />
    </div>
  );
};
