import { Heading } from '@polygence/components';

import logo from 'src/polygpt/PolyPilotLogo.png';

export const WelcomeHeader = () => {
  return (
    <div className="d-flex flex-column gap-4">
      <div className="row">
        <div className="col-lg-4 d-flex justify-content-center justify-content-lg-end align-items-center">
          <img src={logo} height={240} width={240} alt="PolyPilot bird mascot" />
        </div>
        <div className="col-lg-8 d-flex justify-content-center justify-content-lg-start align-items-center">
          <Heading size="h3" fontWeight="semibold" alignment="left" className="text-wrap-pretty">
            Introducing PolyPilot: <br />
            Your AI-Powered Research Mentor 🚀
          </Heading>
        </div>
      </div>
    </div>
  );
};
