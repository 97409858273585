/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { camelCase } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { FormInputWithValidation as FormInput } from 'src/components/FormInputWithValidation';
import { Icon } from 'src/components/Icon';

const engineeringUS = [
  'subject_engineering',
  'subject_physics',
  'subject_math',
  'subject_environmental_science',
  'subject_robotics',
  'subject_computer_science',
];
const engineeringCC = engineeringUS.map(camelCase);
const engineering = {
  us: engineeringUS,
  cc: engineeringCC,
};

const chemistryUS = ['subject_biology', 'subject_chemistry', 'subject_neuroscience'];
const chemistryCC = chemistryUS.map(camelCase);
const chemistry = {
  us: chemistryUS,
  cc: chemistryCC,
};

const artsUS = [
  'subject_fine_arts',
  'subject_architecture',
  'subject_creative_writing',
  'subject_journalism',
  'subject_fashion',
  'subject_film',
  'subject_music',
];
const artsCC = artsUS.map(camelCase);
const arts = {
  us: artsUS,
  cc: artsCC,
};

const lawUS = [
  'subject_law',
  'subject_politics',
  'subject_history',
  'subject_literature',
  'subject_social_sciences',
  'subject_humanities',
];
const lawCC = lawUS.map(camelCase);
const law = {
  us: lawUS,
  cc: lawCC,
};

const analyticsUS = ['subject_economics', 'subject_sports_analytics', 'subject_psychology'];
const analyticsCC = analyticsUS.map(camelCase);
const analytics = {
  us: analyticsUS,
  cc: analyticsCC,
};

const otherUS = ['subject_other'];
const otherCC = otherUS.map(camelCase);
const other = {
  us: otherUS,
  cc: otherCC,
};

const otherValue = {
  us: 'subject_interest_other',
  cc: 'subjectInterestOther',
};

const getColor = (selected) => {
  return selected ? 'white' : '#3366FF';
};

const isSelected = (keyNaming, category, subjects) => {
  return category[keyNaming].some((subCategory) => {
    return subjects[subCategory];
  });
};

const InterestButton = ({ selected, icon, name, keys, onClick }) => {
  const handleClick = (e) => {
    e.preventDefault();
    onClick({
      target: {
        name: keys,
        value: !selected,
        trackName: 'general_interests',
      },
    });
  };
  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <div className={`clickable btn btn-${selected ? 'primary' : 'default'}`} onClick={handleClick}>
      <Icon name={icon} size="80px" color={getColor(selected)} />
      <h6
        // eslint-disable-next-line react/forbid-dom-props -- autodisabled
        style={{
          marginTop: '10px',
        }}
      >
        {name}
      </h6>
    </div>
  );
};

InterestButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func,
};

const InterestSelector = ({ handleChange, subjects, keyNaming = 'us' }) => {
  return (
    <>
      <div>What is your primary area of interest? (Select up to 2)</div>
      <div className="interest-container">
        <InterestButton
          selected={isSelected(keyNaming, chemistry, subjects)}
          onClick={handleChange}
          keys={chemistry[keyNaming]}
          icon="chemistry"
          name="Biological Sciences, Chemistry, Neuroscience"
        />
        <InterestButton
          selected={isSelected(keyNaming, engineering, subjects)}
          onClick={handleChange}
          keys={engineering[keyNaming]}
          icon="engineering"
          name="Computer Science, Engineering, Math, Physics"
        />
        <InterestButton
          selected={isSelected(keyNaming, law, subjects)}
          onClick={handleChange}
          keys={law[keyNaming]}
          icon="law"
          name="Law, Government or Politics, History, Literature"
        />
        <InterestButton
          selected={isSelected(keyNaming, arts, subjects)}
          onClick={handleChange}
          keys={arts[keyNaming]}
          icon="arts"
          name="Fine Arts, Fashion, Architecture, Music, Visual Arts, Writing"
        />
        <InterestButton
          selected={isSelected(keyNaming, analytics, subjects)}
          onClick={handleChange}
          keys={analytics[keyNaming]}
          icon="finance"
          name="Economics, Statistics, Psychology"
        />
        <InterestButton
          selected={isSelected(keyNaming, other, subjects)}
          onClick={handleChange}
          keys={other[keyNaming]}
          icon="other"
          name="Other..."
        />
      </div>
      {isSelected(keyNaming, other, subjects) && (
        <FormInput
          type="text"
          name={otherValue[keyNaming]}
          placeholder=""
          label="Please specify:"
          value={subjects.subject_interest_other}
          onChange={handleChange}
          onError={[]}
          required
        />
      )}
    </>
  );
};

InterestSelector.propTypes = {
  handleChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  subjects: PropTypes.object,
  keyNaming: PropTypes.string,
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default InterestSelector;
