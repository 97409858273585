import { externalLink, getLandingUrl } from '@polygence/common';
import { Icon, Spacer, Text } from '@polygence/components';

import styles from 'src/payment/PaymentPageTerms.module.scss';

export const PaymentPageTerms = () => {
  return (
    <>
      <Spacer size={6} />
      <div className="mt-auto px-6 pb-6 d-flex flex-wrap gap-5 justify-content-center">
        <Text size="small" fontWeight="bold" className={styles['infoText']}>
          <Icon id="external-link" size="sm" />
          <a href={getLandingUrl('/terms-of-use')} {...externalLink}>
            Polygence Terms of Service
          </a>
        </Text>
        <Text size="small" fontWeight="bold" className={styles['infoText']}>
          <Icon id="lock" size="sm" /> Secure payment by Stripe
        </Text>
        <Text size="small" fontWeight="bold" className={styles['infoText']}>
          <Icon id="help-circle" size="sm" />
          <span className={styles['flex0']}>
            Have more questions? Reach out to{' '}
            <a href="mailto:students@polygence.org">students@polygence.org</a>
          </span>
        </Text>
      </div>
    </>
  );
};
