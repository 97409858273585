import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { Text } from '@polygence/components';

// TODO: different styles
import 'src/components/LexicalEditor/plugins/RichTextInputPlugin.sass';

export interface PlainTextInputPluginProps {
  placeholder?: string | undefined;
}

const Placeholder = ({ placeholder }: { placeholder: string }) => {
  return (
    <Text size="small" className="editor-placeholder">
      {placeholder}
    </Text>
  );
};

const PlainTextInputPlugin = ({ placeholder = '' }: PlainTextInputPluginProps) => {
  return (
    <div className="editor-container">
      <div className="editor-inner">
        <PlainTextPlugin
          contentEditable={<ContentEditable className="editor-input" />}
          placeholder={<Placeholder placeholder={placeholder} />}
        />
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default PlainTextInputPlugin;
