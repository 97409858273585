import { Button, Text } from '@polygence/components';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useState, useEffect } from 'react';

import LexicalEditor from 'src/components/LexicalEditor';
import PlainTextInputPlugin from 'src/components/LexicalEditor/plugins/PlainTextInputPlugin';
import SendMessagePlugin from 'src/components/LexicalEditor/plugins/SendMessagePlugin';
import ToolbarPlugin from 'src/components/LexicalEditor/plugins/toolbar/ToolbarPlugin';
import 'src/components/hermes/chat/ChatMessageEditor.sass';
import { usePolyGPTContext } from 'src/polygpt/PolyGPTContext';
import styles from 'src/polygpt/PolyGPTMessageEditor.module.scss';
import { useCanWriteInProject } from 'src/polygpt/useCanWriteInProject';
import { useIsActiveStagePending } from 'src/polygpt/useIsActiveStagePending';
import { useIsProjectLocked } from 'src/polygpt/useIsProjectLocked';

const PolyPilotEditorNotification = ({
  text,
  variant = 'warning',
}: {
  text: string;
  variant?: 'danger' | 'warning';
}) => {
  return (
    <motion.div
      className={classNames(styles['notification'], variant)}
      initial={{ translateY: '100%' }}
      animate={{ translateY: 0 }}
      exit={{ translateY: '100%' }}
      transition={{ duration: 0.3, type: 'ease' }}
    >
      <Text size="small" alignment="center" fontWeight="bold">
        {text}
      </Text>
    </motion.div>
  );
};

interface PolyGPTMessageEditorProps {
  onMessageSend: (message: string) => void;
  projectId: number;
  isMessageSendDisabled: boolean;
  isResendEnabled: boolean;
}

const NUMBER_OF_MAX_RETRIES = 3;
const PROJECT_LOCKED_NOTIFICATION = 'Purchase Mentor Review to continue your project.';
const ACTIVE_STAGE_PENDING_NOTIFICATION =
  'You cannot progress to the next stage until manual mentor review is complete.';
const CONNECTION_LOST = 'Connection lost. Please refresh the page.';

export const PolyGPTMessageEditor = ({
  onMessageSend,
  projectId,
  isMessageSendDisabled,
  isResendEnabled,
}: PolyGPTMessageEditorProps) => {
  const [userInput, setUserInput] = useState('');
  const { websocketConnection } = usePolyGPTContext();
  const isActiveStagePending = useIsActiveStagePending(projectId);
  const isProjectLocked = useIsProjectLocked(projectId);
  const canWriteInProject = useCanWriteInProject(projectId);
  const [isWebSocketConnected, setIsWebSocketConnected] = useState(true);
  const [showResendButton, setShowResendButton] = useState(false);

  useEffect(() => {
    if (websocketConnection) {
      websocketConnection.addEventListener('error', (_) => {
        if (websocketConnection.retryCount >= NUMBER_OF_MAX_RETRIES) {
          setIsWebSocketConnected(false);
          websocketConnection.close();
        }
      });
    }

    if (!isResendEnabled) {
      setShowResendButton(isResendEnabled);
    }
    const timeoutId = setTimeout(() => {
      setShowResendButton(isResendEnabled);
    }, 8000);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [websocketConnection, isResendEnabled]);

  const sendMessage = (userMessage: string) => {
    if (!websocketConnection) {
      return;
    }

    const message = userMessage.trim();

    websocketConnection.send(
      JSON.stringify({
        type: 'polygpt.user_message',
        project_id: projectId,
        user_message: message,
      }),
    );

    onMessageSend(message);
    setTimeout(() => setUserInput(''), 0);
  };

  const refreshConversation = () => {
    if (!websocketConnection) {
      return;
    }

    websocketConnection.send(
      JSON.stringify({
        type: 'polygpt.retry',
        project_id: projectId,
      }),
    );
  };

  return (
    <>
      {isWebSocketConnected && showResendButton && (
        <div className={styles['resendButton']}>
          <Button variant="secondary" onClick={refreshConversation}>
            Resend last message
          </Button>
        </div>
      )}
      <AnimatePresence>
        {!isWebSocketConnected && (
          <PolyPilotEditorNotification text={CONNECTION_LOST} variant="danger" />
        )}
        {isWebSocketConnected && isActiveStagePending && !isProjectLocked && (
          <PolyPilotEditorNotification text={ACTIVE_STAGE_PENDING_NOTIFICATION} variant="warning" />
        )}
        {isWebSocketConnected && isProjectLocked && (
          <PolyPilotEditorNotification text={PROJECT_LOCKED_NOTIFICATION} variant="danger" />
        )}
      </AnimatePresence>
      <div className={classNames('chat-message-editor__wrapper')}>
        {canWriteInProject && (
          <LexicalEditor onChange={setUserInput} defaultValue={userInput}>
            <ToolbarPlugin>
              {!isProjectLocked && <PlainTextInputPlugin placeholder="Write something..." />}
              <SendMessagePlugin
                disabled={
                  userInput.trim() === '' ||
                  isMessageSendDisabled ||
                  isProjectLocked ||
                  !isWebSocketConnected
                }
                onSend={sendMessage}
                sendOnEnter
              />
            </ToolbarPlugin>
          </LexicalEditor>
        )}
      </div>
    </>
  );
};
