import type { CamelCasedPropertiesDeep } from 'type-fest';

import { getRestClient } from '../commonSettings';
import type { StudentProfileId, UUID, UserId } from '../types/common';
import type { PaymentIntentInvoice, PaymentIntentProducts } from '../types/payment';

export const postConnectSubscription = (data: unknown) => {
  const internalFetch = getRestClient();

  return internalFetch.post('/payment/connect-subscription/', data);
};

export const getPaymentIntentInvoice = (uuid: UUID) => {
  const internalFetch = getRestClient();

  return internalFetch.get<PaymentIntentInvoice>(`/payment/intent/${uuid}/invoice/`);
};

export const proceedToPaymentIntentInvoice = (
  uuid: UUID,
  data?: { paymentIntentAmount: number },
) => {
  const internalFetch = getRestClient();

  return internalFetch.post<{
    invoiceUrl: string;
  }>(`/payment/intent/${uuid}/proceed/`, data);
};

export const updatePaymentIntentProducts = (uuid: UUID, products: PaymentIntentProducts) => {
  const internalFetch = getRestClient();

  return internalFetch.patch<PaymentIntentProducts>(`/payment/intent/${uuid}/products/`, products);
};

export const generateNewInvoiceUrl = (uuid: UUID) => {
  const internalFetch = getRestClient();

  return internalFetch.post(`/payment/intent/${uuid}/generate_invoice_url/`);
};

export const generateCheckoutSession = (uuid: string) => {
  const internalFetch = getRestClient();

  return internalFetch.post<CamelCasedPropertiesDeep<{ checkout_session_url: string }>>(
    `/payment/intent/${uuid}/generate-checkout-session`,
  );
};

export const getCustomerPortalUrl = (userId?: UserId) => {
  const internalFetch = getRestClient();

  return internalFetch.get<{ customerPortalUrl: string }>('/payment/stripe-customer-portal/', {
    params: userId ? { student_id: userId } : undefined,
  });
};

export const getOneTimeCustomerPortalUrl = (uuid: UUID) => {
  const internalFetch = getRestClient();

  return internalFetch.get<{ customerPortalUrl: string }>(
    `/payment/stripe-customer-portal/${uuid}/`,
  );
};

export const bulkPartnerPayment = (partnerCompanyTag: string, studentIds: StudentProfileId[]) => {
  const internalFetch = getRestClient();

  const data = {
    students: studentIds,
    partner_channel: partnerCompanyTag,
  };
  return internalFetch.post<{
    paymentIntentUrl: string;
  }>('/payment/bulk_partner_payment/', data);
};
