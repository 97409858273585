import { Button, Icon } from '@polygence/components';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import styles from 'src/payment/PaymentIntentV2/PaymentButtonMobile.module.scss';
const MotionButton = motion(Button);
const MotionIcon = motion(Icon);

export const PaymentButtonMobile = ({
  isProceedDisabled,
  handleProceed,
}: {
  isProceedDisabled: boolean;
  handleProceed: () => void;
}) => {
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    if (document.documentElement.clientHeight >= document.documentElement.scrollHeight) {
      setTimeout(() => {
        setHasScrolled(true);
      }, 500);

      return;
    }

    function onScroll() {
      const minScroll = Math.min(
        document.documentElement.scrollHeight - document.documentElement.clientHeight,
        100,
      );

      if (window.scrollY > minScroll) {
        setHasScrolled(true);
        document.removeEventListener('scroll', onScroll);
      }
    }

    document.addEventListener('scroll', onScroll);

    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <>
      <MotionButton
        transition={{ duration: 0.8, type: 'spring' }}
        layoutId="proceed"
        size="lg"
        variant="primary"
        className={styles['proceedWithPayment']}
        disabled={isProceedDisabled}
        onClick={handleProceed}
        endIcon={<MotionIcon id="chevron-right" layout="position" />}
      />
      {hasScrolled && (
        <MotionButton
          transition={{ duration: 0.8, type: 'spring' }}
          layoutId="proceed"
          size="lg"
          variant="primary"
          className={styles['proceedWithPayment']}
          disabled={isProceedDisabled}
          onClick={handleProceed}
          endIcon={<MotionIcon id="chevron-right" layout="position" />}
        >
          <motion.span
            layout="position"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.2, duration: 0.4 } }}
          >
            Proceed with payment
          </motion.span>
        </MotionButton>
      )}
    </>
  );
};
