import { Icon } from '@polygence/components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';

import styles from 'src/components/student/StudentApplicationSidebar/StudentApplicationSidebar.module.sass';

const ApplicationSideBar = ({ caption, avatar, reviewText, date }) => {
  const [open, setOpen] = useState(false);
  const wrapper = useRef();

  const handleToggle = () => {
    setOpen((prevState) => {
      if (prevState === false) {
        wrapper.current.animate(
          { maxHeight: ['158px', '800px'], overflow: ['hidden', 'visible'] },
          { easing: 'cubic-bezier(0.4, 0, 0.6, 1)', duration: 650 },
        );
      }
      return !prevState;
    });
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <div className={styles.wrapper} ref={wrapper}>
      <div className={styles.imageWrapper}>
        <img src={avatar} alt={caption} width={234} height={234} className={styles.avatar} />
      </div>
      <div>
        <figure className={classNames(styles.alumniFigure, { [styles.open]: open })}>
          <blockquote className={styles.alumniBlockquote}>
            <div className={styles.reviewText}>{reviewText}</div>
            <figcaption className={styles.alumniFigcaption}>
              <span>{caption}</span>
              <span>{date}</span>
            </figcaption>
          </blockquote>
        </figure>
        <button type="button" className={styles.showMoreButton} onClick={handleToggle}>
          <Icon id={open ? 'chevron-up' : 'chevron-down'} />{' '}
          {open ? 'Close full view' : 'Read full student review'}
        </button>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default ApplicationSideBar;

ApplicationSideBar.propTypes = {
  caption: PropTypes.string,
  avatar: PropTypes.string,
  reviewText: PropTypes.string,
  date: PropTypes.string,
};
