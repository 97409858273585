import hljs from 'highlight.js/lib/core';
import type { ElementRef, RefObject } from 'react';
import { useState, useLayoutEffect } from 'react';
import { usePrevious } from 'react-use';
import 'src/components/aux/markdown/codeHighlight.css';

const languages = {
  c: () => import('highlight.js/lib/languages/c'),
  cpp: () => import('highlight.js/lib/languages/cpp'),
  csharp: () => import('highlight.js/lib/languages/csharp'),
  css: () => import('highlight.js/lib/languages/css'),
  javascript: () => import('highlight.js/lib/languages/javascript'),
  json: () => import('highlight.js/lib/languages/json'),
  kotlin: () => import('highlight.js/lib/languages/kotlin'),
  latex: () => import('highlight.js/lib/languages/latex'),
  matlab: () => import('highlight.js/lib/languages/matlab'),
  lua: () => import('highlight.js/lib/languages/lua'),
  php: () => import('highlight.js/lib/languages/php'),
  ruby: () => import('highlight.js/lib/languages/ruby'),
  rust: () => import('highlight.js/lib/languages/rust'),
  scss: () => import('highlight.js/lib/languages/scss'),
  sql: () => import('highlight.js/lib/languages/sql'),
  java: () => import('highlight.js/lib/languages/java'),
  xml: () => import('highlight.js/lib/languages/xml'),
  markdown: () => import('highlight.js/lib/languages/markdown'),
  python: () => import('highlight.js/lib/languages/python'),
  typescript: () => import('highlight.js/lib/languages/typescript'),
} as const;

Object.entries(languages).forEach(([lang, importer]) => {
  importer()
    .then((module) => {
      hljs.registerLanguage(lang, module.default);
    })
    .catch(console.error);
});

export const useSyntaxHighlight = ({
  language,
  rawCodeString,
  codeElementRef,
}: {
  language: string | undefined;
  rawCodeString: string;
  codeElementRef: RefObject<ElementRef<'code'>>;
}) => {
  const previousCodeString = usePrevious(rawCodeString);
  const hasCodeChanged = rawCodeString !== previousCodeString;

  const [isHighlighted, setIsHighlighted] = useState(false);
  const [languageGuess, setLanguageGuess] = useState<string | undefined>(undefined);

  useLayoutEffect(() => {
    if (!codeElementRef.current) {
      return;
    }

    // if there's no language set, try to guess it
    if (!language && (!languageGuess || hasCodeChanged)) {
      const result = hljs.highlightAuto(codeElementRef.current.textContent || '');

      // only set the guess if the relevance is high enough, else it won't be syntax highlighted
      if (result.relevance >= 5) {
        setLanguageGuess(result.language);
      }
    }

    if (language || languageGuess) {
      hljs.highlightElement(codeElementRef.current);
      setIsHighlighted(true);
    }
  }, [language, languageGuess, hasCodeChanged, codeElementRef]);

  return [isHighlighted, languageGuess] as const;
};
