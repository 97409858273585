import { Icon } from '@polygence/components';
import { Link } from 'react-router-dom';

import styles from 'src/polygpt/polygpt.module.scss';
import {
  PolyPilotNavigationTab,
  PolyPilotNavigationTabs,
} from 'src/polygpt/usePolyPilotMobileNavigation';

const getIconForTab = (tab: PolyPilotNavigationTab) => {
  switch (tab) {
    case PolyPilotNavigationTabs.ProjectSelector:
      return 'list';
    case PolyPilotNavigationTabs.Chat:
      return 'message-square';
    case PolyPilotNavigationTabs.Details:
      return 'book-open';
    default:
      return 'help-circle';
  }
};

const PolyPilotMobileNavigationTab = ({
  tab,
  activeTab,
}: {
  tab: PolyPilotNavigationTab;
  activeTab: PolyPilotNavigationTab;
}) => {
  return (
    <Link to={`#${tab}`} className={styles['mobileNavLink']}>
      <Icon
        id={getIconForTab(tab)}
        color={tab === activeTab ? 'var(--primary)' : 'var(--grayscale-5)'}
      />
    </Link>
  );
};

export const PolyPilotMobileNavigation = ({
  tabs,
  activeTab,
}: {
  tabs: PolyPilotNavigationTab[];
  activeTab: PolyPilotNavigationTab;
}) => {
  return (
    <nav className={styles['mobileNav']}>
      {tabs.map((tab) => {
        return <PolyPilotMobileNavigationTab key={tab} tab={tab} activeTab={activeTab} />;
      })}
    </nav>
  );
};
