import type { SimpleUser } from '@polygence/common';
import type { OptionType } from '@polygence/components';
import { AsyncSelect } from '@polygence/components';
import { debounce } from 'lodash';
import type { ActionMeta, MultiValue, OnChangeValue } from 'react-select';

import { useLazyPolyPilotMentorsQuery } from 'src/reducers/adminReducers';

// eslint-disable-next-line @typescript-eslint/ban-types
type RequiredKeys<T> = { [K in keyof T]-?: {} extends Pick<T, K> ? never : K }[keyof T];

interface PolyPilotMentorSelectorSelectorProps<IsMulti extends boolean> {
  initialValue?: OnChangeValue<OptionType, IsMulti>;
  isMulti: IsMulti;
  label?: string;
  valueField?: RequiredKeys<SimpleUser>;
  isDisabled?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  onChange: (
    newValue: OnChangeValue<OptionType, IsMulti>,
    actionMeta: ActionMeta<OptionType>,
  ) => void;
}

export const PolyPilotMentorSelector = <IsMulti extends boolean>({
  initialValue,
  onChange,
  label = 'Mentors',
  valueField = 'id',
  isDisabled = false,
  isMulti,
  menuPlacement,
}: PolyPilotMentorSelectorSelectorProps<IsMulti>) => {
  const [listPolyPilotMentors] = useLazyPolyPilotMentorsQuery();

  const loadOptions = debounce(
    (inputValue: string, callback: (options: MultiValue<OptionType>) => void) => {
      listPolyPilotMentors({ search: inputValue })
        .unwrap()
        .then((results) => {
          return callback(
            results.map((polyPilotMentor) => {
              return {
                label: `${polyPilotMentor.firstName} ${polyPilotMentor.lastName} (${polyPilotMentor.email})`,
                value: polyPilotMentor[valueField],
              };
            }),
          );
        })
        .catch(console.error);
    },
    500,
  );

  return (
    <>
      <AsyncSelect
        label={label}
        cacheOptions
        isMulti={isMulti}
        loadOptions={loadOptions}
        placeholder="Search in mentor name or email"
        onChange={onChange}
        defaultValue={initialValue}
        defaultOptions
        isDisabled={isDisabled}
        menuPlacement={menuPlacement}
      />
    </>
  );
};
