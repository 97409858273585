import type { CounselorProfileId, StudentProfileId } from '@polygence/common/types/common';
import { AsyncSelect } from '@polygence/components';
import type { OptionType } from '@polygence/components';
import { debounce } from 'lodash-es';
import type { SingleValue, MultiValue } from 'react-select';

import { useLazyCounselorStudentsQuery } from 'src/reducers/marketplaceReducer';

export const CounselorStudentSelect = ({
  counselorProfileId,
  onChange,
}: {
  counselorProfileId: CounselorProfileId;
  onChange: (value: StudentProfileId) => void;
}) => {
  const [queryStudents] = useLazyCounselorStudentsQuery();

  const handleStudentSelect = (newValue: SingleValue<OptionType>) => {
    onChange(newValue?.value as number);
  };

  const loadOptions = debounce(
    (inputValue: string, callback: (options: MultiValue<OptionType>) => void) => {
      queryStudents({ counselorProfileId, q: inputValue })
        .unwrap()
        .then(({ results: students }) => {
          const options = students?.map(({ firstName, lastName, id }) => {
            return {
              label: `${firstName} ${lastName}`,
              value: id,
            };
          });
          callback(options || []);
        })
        .catch(console.error);
    },
    500,
  );

  return (
    <AsyncSelect
      loadOptions={loadOptions}
      defaultOptions
      isClearable
      placeholder="Enter the student's name here..."
      onChange={handleStudentSelect}
    />
  );
};
