import { useParams } from 'react-router';

import { PaymentIntentPageV2 } from 'src/payment/PaymentIntentV2/PaymentIntentPageV2';
import { PaymentIntentPageV3 } from 'src/payment/PaymentIntentV3/PaymentIntentPageV3';
import { useGetPaymentIntentQuery } from 'src/reducers/paymentReducer';

export const PaymentIntentPage = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { data: paymentIntent } = useGetPaymentIntentQuery(uuid);

  if (paymentIntent?.cartUuid) {
    return <PaymentIntentPageV3 />;
  }

  return <PaymentIntentPageV2 />;
};
