import { StaxConstructorOptions } from 'src/payment/CheckoutPageStax/staxTypes';

export const staxConstructorOptions: StaxConstructorOptions = {
  number: {
    id: 'card-number',
    placeholder: '1234 1234 1234 1234',
    style:
      'width:90%; border-radius: 6px; background-color: white; border-radius: 6px; padding: 8px 12px; font-size: 16px; line-height: 1.5;',
    format: 'prettyFormat',
  },
  cvv: {
    id: 'cvv',
    placeholder: '123',
    style:
      'width:90%; background-color: white; border-radius: 6px; padding: 8px 12px; font-size: 16px; line-height: 1.5;',
  },
};
