import { UserType } from '@polygence/common';
import * as roleUtils from '@polygence/common/utils/roles';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { getMyInfo } from 'src/components/getMyInfo';

// this wrapper makes sure that you don't access landing page and sign-ups if you are logged in
/* eslint-disable */
export function PublicRoute({ component: Component, logged_in, user_type, ...rest }) {
  var path_url;
  if (user_type === 'mentor') {
    path_url = '/home';
  } else {
    path_url = '/dashboard';
  }
  return (
    <Route
      render={(props) =>
        logged_in === true ? (
          <Redirect to={{ pathname: path_url, state: { from: props.location } }} />
        ) : (
          <Component {...props} {...rest} />
        )
      }
    />
  );
}

// this wrapper redirects to login if accessing page that needs authentication
export function PrivateRoute({ component: Component, ...rest }) {
  const loggedIn = useSelector((state) => state.user.loggedIn);
  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: '/user/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export function AdminRoute({ component: Component, ...rest }) {
  const loggedIn = useSelector((state) => state.user.loggedIn);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!loggedIn) {
          return (
            <Redirect
              to={{
                pathname: '/user/login',
                state: { from: props.location },
              }}
            />
          );
        }
        if (roleUtils.hasAdminDashboardAccess(getMyInfo()) || roleUtils.isIntern(getMyInfo())) {
          return <Component {...props} {...rest} />;
        }
        return <Redirect to={{ pathname: '/dashboard' }} />;
      }}
    />
  );
}

export function ReapplyPrivateRoute(props) {
  const isReapplyAllowed = useSelector(
    (state) => state.user.userType === UserType.STUDENT && state.user.otherInfo?.isReapplyAllowed,
  );
  if (isReapplyAllowed) {
    return <PrivateRoute {...props} />;
  } else {
    return (
      <Redirect
        to={{
          pathname: '/dashboard',
          state: { from: props.location },
        }}
      />
    );
  }
}

export function StudentRoute({
  component: Component,
  userType,
  loggedIn,
  channel,
  applicationJourneyEnabled,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        userType === UserType.STUDENT ? (
          <Component
            {...props}
            loggedIn={loggedIn}
            channel={channel}
            applicationJourneyEnabled={applicationJourneyEnabled}
          />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
