import type { Nullable, PermanentFileSerializer } from '@polygence/common';
import type { ProjectOutcomeType, Tag } from '@polygence/common/types/marketplace';
import type { OptionType } from '@polygence/components';

export type MentorProfileId = number;
export type StudentProfileId = number;
export type CounselorProfileId = number;
export type ParentProfileId = number;

export interface AdmissionAdvisor {
  action: AdmissionAdvisorAction;
  firstName: string;
  role: string;
  imageUrl: string;
  calendlyUrl: string;
}

export enum AdmissionAdvisorAction {
  GeneralEmail = 'general-email',
  CalendlyUrl = 'calendly-url',
}

export interface ChangingPortfolioItemProps {
  abstract: string;
  image: string | null;
  projectLinks: Array<ProjectLink>;
  tags: Tag[];
  outcome: string;
  title: string;
  mentorReview: string;
  studentReview: string;
}
export interface PortfolioItemProps extends ChangingPortfolioItemProps {
  editable: boolean;
  id: number;
  outcomeTypes: ProjectOutcomeType[];
  startedAt: string;
  caseStudyStatus: Nullable<CaseStudyStatus>;
}

export type CaseStudyStatus = 'draft' | 'published' | 'private' | 'update_request';

export interface EditablePortfolioItemProps extends ChangingPortfolioItemProps {
  id: number;
  onDataChange: (prevState: ChangingPortfolioItemProps) => void;
  setEditing: (prevState: boolean) => void;
}

export interface ProjectLink {
  id: number;
  platform: string;
  url: string;
  outcomeTypeDetails: ProjectOutcomeType;
  permOutcomeFile: PermanentFileSerializer[];
  outcomeType?: number;
  portfolioItem?: number;
}

export interface ProjectLinkModalValues {
  id?: number;
  outcomeType?: OptionType;
  outcomeTypeOther?: string;
  platform?: string;
  url?: string;
  permOutcomeFile?: PermanentFileSerializer[] | string;
}

export interface ProjectLinkModalProps {
  modalValues: ProjectLinkModalValues | null;
  options: OptionType[];
  onChange: (name: string, value: string | OptionType | string[]) => void;
  onClose: () => void;
  onSave: () => void;
}

export interface LoggerHistory {
  changes: Record<string, string>;
  other: Record<string, string>;
  recorded_at: string;
}
