import { Skeleton, Text } from '@polygence/components';
import { ComponentProps, useEffect, useRef, useState } from 'react';

export const formatPrice = (price: number | string) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(+price);

export const AmountOrPlaceholder = ({
  amount,
  size = 'medium',
  fontWeight = 'normal',
  className,
}: {
  amount: number | string | null | undefined;
  size?: ComponentProps<typeof Text>['size'];
  fontWeight?: ComponentProps<typeof Text>['fontWeight'];
  className?: string;
}) => {
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const previousAmount = useRef(amount);
  const placeholderHeight = size === 'large' ? 30 : 24;

  useEffect(() => {
    previousAmount.current = amount;
    const isAmountNull = amount == null;
    const timer = setTimeout(() => setShowPlaceholder(isAmountNull), 200);

    return () => clearTimeout(timer);
  }, [amount]);

  if (showPlaceholder) {
    return <Skeleton width="60px" height={`${placeholderHeight}px`} color="var(--grayscale-5)" />;
  }

  return (
    <Text size={size} fontWeight={fontWeight} alignment="center" className={className}>
      {`${formatPrice(amount ?? previousAmount.current ?? 0)}`}
    </Text>
  );
};
