import type { PaymentIntentInvoice, CartItem } from '@polygence/common/types/payment';
import { Checkbox, Icon, Spacer, Text, Tooltip } from '@polygence/components';
import classNames from 'classnames';
import type { ChangeEventHandler } from 'react';

import { AmountOrPlaceholder } from 'src/payment/PaymentIntentV2/AmountOrPlaceholder';
import styles from 'src/payment/PaymentIntentV2/PaymentIntentProduct.module.scss';

const FLAGSHIP_ID = 1;
const UNREMOVABLE_PRODUCT_IDS = [FLAGSHIP_ID];

export const GROUP_HEADERS = {
  pss: 'Premium Showcasing Support',
  '': '',
};

export const PaymentIntentProduct = ({
  product,
  isSelected,
  onChange,
  isRemovable,
  cartItem,
}: {
  product: PaymentIntentInvoice['pitchedProducts'][number];
  isSelected: boolean;
  onChange: ({ id, selected }: { id: number; selected: boolean }) => void;
  isRemovable?: boolean;
  cartItem?: CartItem;
}) => {
  const quantity = cartItem?.quantity ?? 1;
  const manualAmountInCents = cartItem?.manualAmountInCents;
  const isUnRemovable =
    isRemovable == null ? UNREMOVABLE_PRODUCT_IDS.includes(product.id) : !isRemovable;

  const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    onChange({ id: product.id, selected: target.checked });
  };

  const getPrice = () => {
    if (manualAmountInCents) {
      return manualAmountInCents / 100;
    }
    if (cartItem) {
      return cartItem.amountInCents / 100;
    }
    return product.basePriceInDollars;
  };
  const publicName = product.group
    ? `${GROUP_HEADERS[product.group]} - ${product.publicName}`
    : product.publicName;

  if (isUnRemovable && isSelected) {
    return (
      <div className="d-flex align-items-center">
        <Icon id="check" size="lg" className="mx-2" />
        <Spacer size={3} />
        <Text size="medium" alignment="left" className="d-inline" fontWeight="bold">
          {quantity && quantity > 1 ? `${quantity} x ${publicName}` : publicName}
        </Text>
        {product.publicDetails && (
          <Tooltip tip={product.publicDetails} placement="top">
            <Icon id="info" size="sm" className="mx-3 text-muted" />
          </Tooltip>
        )}

        <div className="ms-auto">
          <AmountOrPlaceholder amount={getPrice()} />
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames('d-flex align-items-center', {
        [styles['notSelected'] as string]: !isSelected,
      })}
    >
      <Checkbox
        label={publicName}
        checked={isSelected}
        name={product.name}
        onChange={handleChange}
      />
      {product.publicDetails && (
        <Tooltip tip={product.publicDetails} placement="top">
          <Icon id="info" size="sm" className="mx-3 text-muted" />
        </Tooltip>
      )}
      <div className="ms-auto">
        <AmountOrPlaceholder amount={getPrice()} />
      </div>
    </div>
  );
};
