import { axiosBaseQuery } from '@polygence/common/api/fetch';
import { createApi } from '@reduxjs/toolkit/query/react';

import type { ProductItem } from 'src/types/zeus';

export const productApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'product',
  endpoints: (build) => {
    return {
      products: build.query<ProductItem[], void>({
        query: () => {
          return '/student-application/products/';
        },
        transformResponse: (
          response: {
            id: number;
            name: string;
            basePriceInDollars: string;
            totalPriceInCents: number;
            canDiscountBeApplied: boolean;
            publicName: string;
            publicDetails: string;
            deprecated: boolean;
            isPitchable: boolean;
          }[],
        ) => {
          return response.map((response) => {
            return {
              ...response,
              basePriceInDollars: parseFloat(response.basePriceInDollars),
              totalPriceInCents: response.totalPriceInCents,
            };
          });
        },
      }),
    };
  },
});

export const { useProductsQuery } = productApi;
