import { DividerWithText } from '@polygence/components';
import { Fragment, useMemo } from 'react';

import { PolyGPTMessage } from 'src/polygpt/PolyGPTMessage';
import { PolyGPTScrollButton } from 'src/polygpt/PolyGPTScrollButton';
import { useGetPolyPilotProjectDetailsQuery } from 'src/reducers/polyGptReducers';
import type { Message } from 'src/types/polygence/polygpt';

export const PolyGPTMessageList = ({
  messages,
  isResponding,
  projectId,
}: {
  messages: Message[];
  isResponding: boolean;
  projectId?: number;
}) => {
  const { data: project } = useGetPolyPilotProjectDetailsQuery(projectId ?? 0, {
    skip: !projectId,
  });

  const firstMessagesInStages = useMemo(() => {
    if (!project?.projectStages) {
      return {};
    }

    return project?.projectStages?.reduce((acc: Record<number, string>, projectStage, index) => {
      if (projectStage.firstMessageId && index > 0) {
        // eslint-disable-next-line fp/no-mutation
        acc[projectStage.firstMessageId] = projectStage.stage.displayName;
      }

      return acc;
    }, {});
  }, [project?.projectStages]);

  return (
    <>
      <PolyGPTScrollButton isResponding={isResponding} />
      {messages.map((message, index) => (
        <Fragment key={`polygpt-message-${message.id ?? index}`}>
          <PolyGPTMessage
            message={message}
            loading={index === 0 && isResponding}
            projectId={projectId}
          />
          {/* This list is displayed in reverse order, so this will be before the message */}
          {!!firstMessagesInStages[message.id ?? 0] && (
            <DividerWithText text={firstMessagesInStages[message.id ?? 0]} />
          )}
        </Fragment>
      ))}
    </>
  );
};
