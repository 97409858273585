import type { Nullable } from '@polygence/common';
import type { WebSocket } from 'partysocket';
import { createContext, Dispatch, RefObject, SetStateAction, useContext } from 'react';

export const DEFAULT_HOME_URL = '/';

export interface PolyGPTContextType {
  websocketConnection: Nullable<WebSocket>;
  hideSystemMessages: boolean;
  setHideSystemMessages?: Dispatch<SetStateAction<boolean>>;
  messageContainerRef?: RefObject<HTMLDivElement>;
}

export const PolyGPTContext = createContext<PolyGPTContextType>({
  websocketConnection: null,
  hideSystemMessages: false,
  setHideSystemMessages: undefined,
  messageContainerRef: undefined,
});

export const usePolyGPTContext = () => {
  return useContext(PolyGPTContext);
};
