import classNames from 'classnames';
import type { ReactNode } from 'react';

import styles from 'src/payment/PaymentIntentV2/PaymentIntentPageV2.module.scss';

export const PaymentBox = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return <div className={classNames(styles['box'], className)}>{children}</div>;
};
