import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

export const DEFAULT_HOME_URL = '/';

export interface NavigationBarContextType {
  homeUrl: string;
  setHomeUrl?: (homeUrl: string) => void;
}

export const NavigationBarContext = createContext<NavigationBarContextType>({
  homeUrl: DEFAULT_HOME_URL,
});

export const NavigationBarContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [homeUrl, setHomeUrl] = useState(DEFAULT_HOME_URL);
  const contextValue = useMemo(() => {
    return { homeUrl, setHomeUrl };
  }, [homeUrl, setHomeUrl]);

  return (
    <NavigationBarContext.Provider value={contextValue}>{children}</NavigationBarContext.Provider>
  );
};

export const useCustomNavigationBarHomeUrl = (homeUrl: string) => {
  const { setHomeUrl } = useContext(NavigationBarContext);

  useEffect(() => {
    if (!setHomeUrl || typeof setHomeUrl !== 'function') {
      return;
    }

    setHomeUrl(homeUrl);

    return () => setHomeUrl(DEFAULT_HOME_URL);
  }, [homeUrl, setHomeUrl]);
};

// export hook as component for legacy class component usage :(
export const CustomNavigationBarHomeUrl = ({ homeUrl }: { homeUrl: string }) => {
  useCustomNavigationBarHomeUrl(homeUrl);

  return null;
};
