import * as marketplaceApi from '@polygence/common/api/marketplace';
import { commonHooks } from '@polygence/common/hooks';
import type { TimeSlot } from '@polygence/common/types/hermes';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

export const useAutoScheduleSetting = (isAutoAccepting: boolean, timeSlots?: TimeSlot[]) => {
  const currentUser = commonHooks.useCurrentUser();

  const [isAutoScheduleChecked, setIsAutoScheduleChecked] = useState(isAutoAccepting);
  const [isAutoScheduleEnabled, setIsAutoScheduleEnabled] = useState(false);
  const [isAutoScheduleLoading, setIsAutoScheduleLoading] = useState(false);

  const handleAutoScheduleChange = (checked: boolean) => {
    const autoAcceptingSessionProposalsAt = checked ? new Date().toISOString() : null;

    setIsAutoScheduleLoading(true);

    marketplaceApi
      .updateMentorProfile({ autoAcceptingSessionProposalsAt })
      .then(() => {
        const message = checked
          ? 'Session proposals will be accepted automatically.'
          : 'Session proposals should be accepted manually.';

        setIsAutoScheduleChecked(checked);
        toast.success(message);
      })
      .catch(() => toast.error('Something went wrong please try again.'))
      .finally(() => setIsAutoScheduleLoading(false));
  };

  useEffect(() => {
    if (timeSlots?.length) {
      const canEnableAutoSchedule = !!timeSlots.length && currentUser.calendarConnected;
      setIsAutoScheduleEnabled(canEnableAutoSchedule);

      if (!canEnableAutoSchedule && isAutoScheduleChecked) {
        setIsAutoScheduleChecked(false);
        handleAutoScheduleChange(false);
      }
    }
  }, [timeSlots?.length, currentUser.calendarConnected, isAutoScheduleChecked]);

  return {
    isAutoScheduleChecked,
    isAutoScheduleEnabled: isAutoScheduleEnabled && !isAutoScheduleLoading,
    handleAutoScheduleChange,
  };
};
