import * as backendApi from '@polygence/common/api/backend';
import useCurrentUser from '@polygence/common/hooks/selectors/useCurrentUser';
import type { StudentProfileId, StudentApplicationId } from '@polygence/common/types/common';
import { Text, Heading, Button, Spacer } from '@polygence/components';
import classnames from 'classnames';
import { MouseEventHandler, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from 'src/components/auth/UnverifiedUnauthorizedPage/UnverifiedApplicationSubmitPage.module.scss';
import Logo from 'src/components/static/images/unverified-application-submit.svg';
import { urls } from 'src/urls';
import { UnverifiedUserLocalStorageHandler } from 'src/utils/localStorageManager';

export const UnverifiedApplicationSubmitPage = () => {
  const currentUser = useCurrentUser();
  const [disabled, setDisabled] = useState(false);
  const location = useLocation<{
    profileId?: StudentProfileId;
    applicationId: StudentApplicationId;
  }>();
  const profileId = location?.state?.profileId;
  const applicationId = location?.state?.applicationId;

  useEffect(() => {
    if (profileId && applicationId) {
      UnverifiedUserLocalStorageHandler.unverifiedApplicationSubmit(profileId, applicationId);
    }
  }, [profileId, applicationId]);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setDisabled(true);

    backendApi
      .requestAccountVerification()
      .then(() => toast.success('Email has been sent!'))
      .catch(() => {
        toast.error('Something went wrong, please try again');
        setDisabled(false);
      });
  };

  return (
    <div className={classnames('d-flex justify-content-center', styles['container'])}>
      <div>
        <div className="d-flex justify-content-center">
          <img alt="" src={Logo} />
        </div>
        <Spacer size={9} />
        <Heading size="h3">You're almost there!</Heading>
        <Spacer size={7} />
        <Text size="large" fontWeight="bold" alignment="center">
          As a last step please verify your email address to submit your application.
        </Text>
        <Text size="medium" alignment="center">
          We’ve sent you a verification email to {currentUser.email}.
        </Text>
        <Text size="medium" alignment="center">
          Please click on the link in the email to confirm your email address.
        </Text>
        <Spacer size={1} />
        <Text size="small" alignment="center" className={styles['smallText']}>
          If you're experiencing any issues, make sure your email address is correct{' '}
          <Link to={urls.settingsPage()}>here</Link>.
        </Text>
        <Spacer size={9} />
        <div className="d-flex flex-wrap justify-content-center align-items center">
          <Text size="small" alignment="center" className="w-100">
            Haven't received it yet?
          </Text>
          <Button
            variant="link-primary"
            size="sm"
            onClick={handleClick}
            disabled={disabled}
            className={styles['verificationLink']}
          >
            Resend verification email
          </Button>
        </div>
      </div>
    </div>
  );
};
