import NiceModal, { useModal, bootstrapDialog } from '@ebay/nice-modal-react';
import { Modal, Alert, ModalBody, Text } from '@polygence/components';
import { useEffect } from 'react';

import { SpinningWheel } from 'src/components/SpinningWheel';

export const PriceChangeErrorModal = NiceModal.create(() => {
  const modal = useModal();

  useEffect(() => {
    setTimeout(() => {
      window.location.reload();
    }, 4000);
  }, []);

  return (
    <Modal {...bootstrapDialog(modal)}>
      <div className="d-flex flex-column gap-8">
        <ModalBody>
          <Alert variant="danger">
            <Text size="medium" fontWeight="bold">
              It seems that the price of one or more items have been changed. The page is going to
              be automatically refreshed to ensure that you see the most up-to-date prices.
            </Text>
          </Alert>
          <div>
            <SpinningWheel />
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
});
