import { FormRenderer } from '@data-driven-forms/react-form-renderer';
import type { Schema, ValidatorMapper } from '@data-driven-forms/react-form-renderer';
import { ThemeProvider } from '@material-ui/core/styles';
import type { Nullable, ProjectId } from '@polygence/common';
import * as surveyApi from '@polygence/common/api/survey';
import type { EditableForm } from '@polygence/common/types/survey';
import { Alert } from '@polygence/components';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { SpinningWheel } from 'src/components/SpinningWheel';
import componentMapper from 'src/components/data-driven-forms/component-mapper';
import createPolygenceTheme from 'src/components/data-driven-forms/theme';
import StudentFinalSurveyTemplate from 'src/components/student/student-final-survey/StudentFinalSurveyTemplate';
import { FormContextProvider } from 'src/providers/FormProvider';
import { useGetProductsPurchasedQuery } from 'src/reducers/paymentReducer';

const theme = createPolygenceTheme({
  overrides: {
    MuiInput: {
      formControl: {
        'label + &': {
          marginTop: '1.5rem',
        },
      },
    },
    MuiGrid: {
      item: {
        marginBottom: 'var(--space-5)',
      },
    },
  },
});

interface ShowcasingMatchingFormProps {
  projectId: Nullable<ProjectId>;
}

export const SELECTABLE_PSS_PRODUCTS = [
  'Premium Showcasing',
  'Premium Showcasing - Presenting',
  'Premium Showcasing - Multimedia',
];

export const ShowcasingMatchingForm = ({ projectId }: ShowcasingMatchingFormProps) => {
  const [formData, setFormData] = useState<EditableForm>();
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { data, isLoading } = useGetProductsPurchasedQuery();

  const purchasedPssProducts = data?.purchasedProducts.filter((product) =>
    SELECTABLE_PSS_PRODUCTS.includes(product),
  );

  const validators: ValidatorMapper = {
    pssTracks: () => (value: string[]) => {
      const count = purchasedPssProducts?.length;

      if (count !== value.length) {
        return `Please select ${count} Premium Showcasing tracks.`;
      }

      return undefined;
    },
  };

  useEffect(() => {
    surveyApi
      .getPremiumShowcasingMatchingForm()
      .then((res) => setFormData(res.data))
      .catch(() => toast.error('Error loading form.'));
  }, []);

  const onSubmit = (data: Record<string, unknown>) => {
    if (!projectId) {
      toast.error('Failed to send form response.');
      return;
    }

    if (loading) {
      return;
    }
    setLoading(true);
    surveyApi
      .postPremiumShowcasingMatchingSurvey({ data, projectId: projectId })
      .then(() => {
        setIsSubmitted(true);
      })
      .catch(() => toast.error('Failed to send form response.'))
      .finally(() => setLoading(false));
  };

  if (!formData || isLoading) {
    return <SpinningWheel />;
  }

  return isSubmitted ? (
    <Alert variant="success" className="text-center">
      Thank you for submitting.
    </Alert>
  ) : (
    <FormContextProvider name={`pss_${projectId ?? ''}`}>
      <ThemeProvider theme={theme}>
        <FormRenderer
          componentMapper={componentMapper}
          FormTemplate={StudentFinalSurveyTemplate}
          schema={formData.fields as Schema}
          onSubmit={onSubmit}
          validatorMapper={validators}
          initialValues={{ pss_products: purchasedPssProducts ?? [] }}
        />
      </ThemeProvider>
    </FormContextProvider>
  );
};
