import { Spacer, Text, Heading } from '@polygence/components';
import { Link } from 'react-router-dom';

import SuccessIcon from 'src/components/static/images/undraw_maker_launch_re.svg?react';
import { urls } from 'src/urls';

export const PaymentSuccessTokenWorkflowMessage = () => {
  return (
    <>
      <Heading size="h3" as="h1">
        We successfully received your payment.
      </Heading>
      <Spacer size={10} />
      <SuccessIcon />
      <Spacer size={9} />
      <Text size="large" alignment="center">
        {`Let's start inviting students `}
        <Link to={`${urls.getCounselorStudents()}#inviteSection`}>here</Link>!
      </Text>
    </>
  );
};
