import type { DateTime } from '@polygence/common';
import type { Cart } from '@polygence/common/types/payment';
import { Heading } from '@polygence/components';

import { PaymentBox } from 'src/payment/PaymentIntentV2/PaymentBox';
import { PaymentIntentDiscount } from 'src/payment/PaymentIntentV2/PaymentIntentDiscount';
import styles from 'src/payment/PaymentIntentV2/PaymentIntentPageV2.module.scss';
import { dayjs } from 'src/utils/dayjsCustom';

export const DiscountList = ({ discounts }: { discounts: Cart['discounts'] }) => {
  if (discounts.length === 0) {
    return null;
  }
  const getRemainingDaysOrHours = (expiresAt: DateTime) => {
    return dayjs(expiresAt).fromNow();
  };
  return (
    <PaymentBox className={styles['discounts']}>
      <Heading size="h5" as="h2" alignment="left" className="mb-6">
        Discounts
      </Heading>
      {discounts.map((discount) => {
        const displayDiscount = discount.expiresAt
          ? `${discount.displayName} (expires ${getRemainingDaysOrHours(discount.expiresAt)})`
          : discount.displayName;
        return (
          <PaymentIntentDiscount
            amount={discount.appliedAmountInCents / 100}
            name={displayDiscount}
            key={discount.displayName}
          />
        );
      })}
    </PaymentBox>
  );
};
