import { Button, Heading, Text } from '@polygence/components';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import styles from 'src/components/Tiles/Counselor/ProjectIdeaGeneratorTile.module.scss';
import tileStyles from 'src/components/Tiles/tilesDashboard.module.scss';
import { urls } from 'src/urls';

export const ProjectIdeaGeneratorTile = () => {
  return (
    <div className={classNames('tw-p-6', styles['projectIdeaGeneratorTile'], tileStyles['tile'])}>
      <Heading
        size="h4"
        className={classNames('tw-mb-3 tw-w-5/6 tw-p-0', styles['tileTitle'])}
        alignment="left"
      >
        Help your students explore project ideas with the help of the PolyGPT tool
      </Heading>
      <Text size="medium" className={classNames('tw-text-gray-10 tw-mb-3 tw-text-gray-100')}>
        Brainstorm and create a list of project ideas for your students!
      </Text>
      <Button
        variant="primary"
        // @ts-expect-error see BSH-5057
        as={Link}
        className={styles['ideaGeneratorTileButton']}
        to={urls.counselorProjectIdeaGenerator()}
      >
        <Text size="medium" fontWeight="bold">
          Use PolyGPT
        </Text>
      </Button>
      <img
        className={styles['illustration']}
        src="https://dpl6hyzg28thp.cloudfront.net/media/poly-gpt.jpeg"
        alt="Illustration"
      />
    </div>
  );
};
