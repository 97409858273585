import { commonHooks } from '@polygence/common';

import { useGetPolyPilotProjectDetailsQuery } from 'src/reducers/polyGptReducers';

export const useCanWriteInProject = (projectId: number) => {
  const currentUser = commonHooks.useCurrentUser();
  const { data } = useGetPolyPilotProjectDetailsQuery(projectId);

  if (!data) {
    return true;
  }

  return data.user.id === currentUser.id;
};
