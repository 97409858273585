/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';
import type { UUID, Nullable } from '@polygence/common';
import * as marketplaceApi from '@polygence/common/api/marketplace';
import { Button, Icon, Text, Modal, Heading } from '@polygence/components';
import { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';

import FormEmailInput from 'src/components/FormEmailInput';
import { FormInputWithValidation as FormInput } from 'src/components/FormInputWithValidation';
import logo from 'src/polygpt/PolyPilotLogo.png';
import styles from 'src/polygpt/PurchasePolyPilotModal.module.scss';
import { useCreatePaymentIntentForPolyPilotMutation } from 'src/reducers/paymentReducer';
import { PolyPilotProjectId } from 'src/types/polygence/polygpt';
import { navigateTo } from 'src/utils/navigateTo';

const ERROR_MESSAGE = "Couldn't create invoice. Please contact support!";

export const PurchasePolyPilotModal = NiceModal.create(
  ({
    projectId,
    paymentIntentId,
  }: {
    projectId: PolyPilotProjectId;
    paymentIntentId: Nullable<UUID> | undefined;
    // eslint-disable-next-line sonarjs/cognitive-complexity
  }) => {
    const [createPaymentIntentForPolyPilot, { isLoading }] =
      useCreatePaymentIntentForPolyPilotMutation();

    const [showParentInfoStep, setShowParentInfoStep] = useState(false);
    const [email, setEmail] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);

    const modal = useModal();

    const handleActionButton = () => {
      if (paymentIntentId) {
        navigateTo(`/payment/${paymentIntentId}/`);
        return;
      }

      createPaymentIntentForPolyPilot(projectId)
        .unwrap()
        .then(({ uuid: paymentIntentUuid }) => {
          if (paymentIntentUuid) {
            navigateTo(`/payment/${paymentIntentUuid}/`);
          }
        })
        .catch((error) => {
          if (
            typeof error.data === 'object' &&
            error.data !== null &&
            error.data.message === 'No parent email'
          ) {
            setShowParentInfoStep(true);
          } else {
            toast.error(ERROR_MESSAGE);
          }
        });
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (isLoading || isUpdating) {
        return;
      }

      setIsUpdating(true);
      marketplaceApi
        .updateStudentProfile({
          partial: true,
          parentEmail: email,
        })
        .then(() => {
          setIsUpdating(false);
          return handleActionButton();
        })
        .catch(() => {
          toast.error(ERROR_MESSAGE);
        })
        .finally(() => {
          setIsUpdating(false);
        });
    };

    return (
      <Modal {...bootstrapDialog(modal)} closeButton className={styles['modal']}>
        <div className="d-flex flex-column gap-5 p-6 py-8">
          {!showParentInfoStep ? (
            <>
              <Heading size="h3" as="h1">
                Get Full Access to PolyPilot!
              </Heading>
              <Text size="large" alignment="center" textWrap="balance">
                Your journey is just beginning! Upgrade today to keep the momentum going.
              </Text>
              <div className={styles['spacer']} />
              <Text size="medium" textWrap="balance">
                Continue your progress and get matched with an expert mentor who is uniquely
                qualified to support your chosen project.
              </Text>
              <div className="d-flex gap-2 justify-content-between align-items-center mt-4">
                <div className="d-flex flex-column gap-4">
                  <Text size="medium" fontWeight="semibold">
                    Full PolyPilot access includes:
                  </Text>
                  <ul className={styles['featureList']}>
                    <li>continued access to PolyPilot</li>
                    <li>feedback from a Polygence mentor</li>
                  </ul>
                </div>
                <img
                  src={logo}
                  width={150}
                  height={150}
                  alt="PolyPilot bird mascot"
                  className="d-none d-sm-block"
                />
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center gap-7">
                <Button
                  variant="primary"
                  className="w-100"
                  onClick={handleActionButton}
                  startIcon={<Icon id="unlock" />}
                  disabled={isLoading}
                >
                  Upgrade Now
                </Button>
                <img
                  src={logo}
                  width={150}
                  height={150}
                  alt="PolyPilot bird mascot"
                  className="d-block d-sm-none"
                />
              </div>
            </>
          ) : (
            <>
              <div className="d-flex flex-column gap-5">
                <Heading size="h3" as="h1">
                  Just one last step
                </Heading>
                <Text size="medium" alignment="center" textWrap="balance">
                  Please provide your parent or guardian's email address.
                </Text>
                <Text size="small" alignment="center" textWrap="balance">
                  We'll use this email address to coordinate billing and payment.
                </Text>
                <div className={styles['spacer']} />
                <form onSubmit={handleSubmit}>
                  <FormEmailInput
                    base={FormInput}
                    type="email"
                    name="email"
                    value={email}
                    className="mb-4"
                    onChange={(event: FormEvent<HTMLInputElement>) => {
                      setEmail((event.target as HTMLInputElement).value);
                    }}
                    placeholder="Parent Email Address"
                    onError={[]}
                    htmlRequired
                  />
                  <Button
                    type="submit"
                    variant="primary"
                    className="mt-8 w-100"
                    startIcon={<Icon id="unlock" />}
                    disabled={isLoading || isUpdating}
                  >
                    Upgrade Now
                  </Button>
                </form>
              </div>
            </>
          )}
        </div>
      </Modal>
    );
  },
);
