import { RootState } from '@polygence/common/store/store';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { HermesAIMessageThreadId, UserSnippetId } from '../../types/common';

export interface HermesAiState {
  selectedThreadId: HermesAIMessageThreadId | null;
  unsentSnippets: UserSnippetId[];
  isThreadSelectorOpen: boolean;
}

const initialState: HermesAiState = {
  selectedThreadId: null,
  unsentSnippets: [],
  isThreadSelectorOpen: false,
};

const hermesAiSlice = createSlice({
  name: 'hermesAi',
  initialState,
  reducers: {
    addUnsentSnippet: (state, { payload }: { payload: UserSnippetId }) => {
      state.unsentSnippets.push(payload);
    },
    removeUnsentSnippet: (state, { payload }: { payload: UserSnippetId }) => {
      state.unsentSnippets = state.unsentSnippets.filter((id) => id !== payload);
    },
    clearUnsentSnippets: (state) => {
      state.unsentSnippets = [];
    },
    toggleThreadSelector: (state) => {
      state.isThreadSelectorOpen = !state.isThreadSelectorOpen;
    },
    closeThreadSelector: (state) => {
      state.isThreadSelectorOpen = false;
    },
    setSelectedThreadId: (state, { payload }: { payload: HermesAIMessageThreadId | null }) => {
      state.selectedThreadId = payload;
      state.isThreadSelectorOpen = false;
    },
  },
});

const { actions, reducer } = hermesAiSlice;

const selectHermesAiState = (state: RootState) => state.hermesAi;
const selectUnsentSnippets = (state: HermesAiState) => state.unsentSnippets;
const unsentSnippetsSelector = createSelector(selectHermesAiState, selectUnsentSnippets);
const isThreadSelectorOpenSelector = createSelector(
  selectHermesAiState,
  (state) => state.isThreadSelectorOpen,
);
const selectedThreadIdSelector = createSelector(
  selectHermesAiState,
  (state) => state.selectedThreadId,
);

export const hermesAiSelectors = {
  selectedThreadId: selectedThreadIdSelector,
  unsentSnippets: unsentSnippetsSelector,
  isThreadSelectorOpen: isThreadSelectorOpenSelector,
};

export const hermesAiActions = actions;

export const hermesAiReducer = reducer;
