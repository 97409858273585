/* eslint-disable fp/no-mutation */
import useCurrentUser from '@polygence/common/hooks/selectors/useCurrentUser';
import { Button, Icon, Tooltip } from '@polygence/components';

import { usePolyGPTContext } from 'src/polygpt/PolyGPTContext';
import { useLazyExportProjectQuery } from 'src/reducers/polyGptReducers';
import { dayjs } from 'src/utils/dayjsCustom';

export const PolyGPTExportButton = ({ projectId }: { projectId: number | string }) => {
  const [trigger, { isLoading }] = useLazyExportProjectQuery();
  const { firstName, lastName } = useCurrentUser();
  const { hideSystemMessages } = usePolyGPTContext();

  const downloadTxtFile = (conversation: string) => {
    const currentDate = dayjs().format('YYYY-MM-DDTHH:mm:ss');

    const element = document.createElement('a');
    const file = new Blob([conversation], { type: 'text/plain' });

    element.href = URL.createObjectURL(file);
    element.download = `${currentDate}_${firstName}_${lastName}_polygpt_conversation_${projectId}.md`;
    document.body.appendChild(element);
    element.click();
  };

  return (
    <>
      <Tooltip tip="Export to text file">
        <Button
          variant="tertiary"
          disabled={isLoading}
          className="mb-4"
          onClick={() =>
            void trigger({ projectId, includeSystemMessages: !hideSystemMessages })
              .then(({ data }) => downloadTxtFile(data?.conversation ?? ''))
              .catch(console.error)
          }
          endIcon={<Icon id="download" />}
        />
      </Tooltip>
    </>
  );
};
