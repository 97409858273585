import { getLandingUrl } from '@polygence/common';
import { Button, Heading, Text } from '@polygence/components';
import classNames from 'classnames';

import { PaymentPageTerms } from 'src/payment/PaymentPageTerms';
import styles from 'src/students/Pods/PodFullPage.module.scss';

export const PaymentIntentCanceled = () => {
  return (
    <div className={classNames(styles['container'], 'd-flex row align-items-end')}>
      <div className="d-flex row justify-content-center align-items-center gap-6">
        <Heading as="h3" size="h3">
          This invoice is cancelled.
        </Heading>
        <Text size="medium" alignment="center" className={styles['grayscale-6']}>
          Don’t worry! You can still browse our available programs and find which one suits you
          best!
        </Text>
        <div className="d-flex justify-content-center w-25">
          <Button variant="primary" href={getLandingUrl()}>
            <Text size="medium" fontWeight="bold">
              Explore Programs
            </Text>
          </Button>
        </div>
      </div>
      <PaymentPageTerms />
    </div>
  );
};
