import { Action, combineReducers, configureStore } from '@reduxjs/toolkit';
import type {
  Middleware,
  ThunkDispatch,
  ReducersMapObject,
  PreloadedStateShapeFromReducersMapObject,
} from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { hermesAiApi } from '../api/hermes-ai';

import { additionalSessionReducer } from './reducers/additionalSessionReducer';
// eslint-disable-next-line import/no-cycle
import { attachmentsReducer } from './reducers/attachmentsReducer';
import { channelReducer } from './reducers/channelReducer';
import { hermesAiReducer } from './reducers/hermesAiReducer';
import { hermesReducer } from './reducers/hermesReducer';
import { pathfinderStudentApplicationReducer } from './reducers/pathfinderStudentApplicationReducer';
import { projectExtensionReducer } from './reducers/projectExtensionReducer';
import { selfProposeReducer } from './reducers/selfProposeReducer';
import { sharedResourcesReducer } from './reducers/sharedResourcesReducer';
import { showcasingAddonReducer } from './reducers/showcasingAddonReducer';
import { studentApplicationReducer } from './reducers/studentApplicationReducer';
import { userReducer, userActions } from './reducers/userReducer';

const commonReducers = {
  user: userReducer,
  hermes: hermesReducer,
  studentApplication: studentApplicationReducer,
  pathfinderStudentApplication: pathfinderStudentApplicationReducer,
  attachments: attachmentsReducer,
  additionalSession: additionalSessionReducer,
  projectExtension: projectExtensionReducer,
  showcasingAddon: showcasingAddonReducer,
  channel: channelReducer,
  sharedResources: sharedResourcesReducer,
  selfPropose: selfProposeReducer,
  hermesAi: hermesAiReducer,
  [hermesAiApi.reducerPath]: hermesAiApi.reducer,
};

const commonMiddlewares = [hermesAiApi.middleware];

export const getStoreWith = <T extends ReducersMapObject>(
  preloadedState: Partial<PreloadedStateShapeFromReducersMapObject<T & typeof commonReducers>> = {},
  reducers: T = {} as T,
  middlewares: Middleware[] = [],
) => {
  const combinedReducer = combineReducers({ ...commonReducers, ...reducers });

  return configureStore({
    reducer: (
      state: Parameters<typeof combinedReducer>[0],
      action: Parameters<typeof combinedReducer>[1],
    ) => {
      if (userActions.removeUser.match(action)) {
        return combinedReducer(undefined, action);
      }

      return combinedReducer(state, action);
    },
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware().concat(...(commonMiddlewares as Middleware[]), ...middlewares);
    },
    preloadedState,
  });
};

export type CommonStore = ReturnType<typeof getStoreWith<typeof commonReducers>>;
export type RootState = ReturnType<CommonStore['getState']>;
export const useCommonSelector = useSelector.withTypes<RootState>();
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
export const useCommonDispatch = useDispatch.withTypes<ThunkAppDispatch>();
