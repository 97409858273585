/* eslint-disable react/prop-types */

import { commonHooks, toOption } from '@polygence/common';
import * as marketplaceApi from '@polygence/common/api/marketplace';
import {
  Accordion,
  Alert,
  Button,
  Card,
  Checkbox as ComponentsCheckbox,
  Heading as ComponentsHeading,
  PhoneInput as ComponentsPhoneInput,
  RadioButton as ComponentsRadioButton,
  Text as ComponentsText,
  Illustration,
  InputField,
  Select,
  Spacer,
  Text,
  DividerWithText,
} from '@polygence/components';
import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { AsyncSelectWithOther } from 'src/components/AsyncSelectWithOther';
import { CountrySelect } from 'src/components/CountrySelect';
import FormEmailInput from 'src/components/FormEmailInput';
import { FormInputWithValidation as FormInput } from 'src/components/FormInputWithValidation';
import { Icon } from 'src/components/Icon';
import { InstitutionSelector } from 'src/components/InstitutionSelector';
import { MultiToggleSelect } from 'src/components/MultiToggleSelect';
import _PhoneNumberInput from 'src/components/PhoneNumberInput';
import SelectWithClarification from 'src/components/SelectWithClarification';
import SelectWithOther from 'src/components/SelectWithOther';
import _TextareaWithWordCount from 'src/components/TextareaWithWordCount';
import { UploadImage } from 'src/components/UploadImage';
import { AdmissionAdvisorModalInfo } from 'src/components/admin/components/AdmissionAdvisorModalInfo/AdmissionAdvisorModalInfo';
import { AdmissionAdvisorConcerns } from 'src/components/admin/components/application/AdmissionAdvisorConcerns';
import { AdmissionAdvisorStudentsInterests } from 'src/components/admin/components/application/AdmissionAdvisorStudentsInterests';
import { DisplayTags } from 'src/components/admin/components/aux/DisplayTags';
import { TagInput } from 'src/components/admin/components/reusable/TagInput';
import { EditableChannelWithCounselorDetails } from 'src/components/admin/components/studentRelated/EditableChannelWithCounselorDetails';
import hoursAndMinutes from 'src/components/aux/hoursAndMinutes';
import { ReactMarkdown } from 'src/components/aux/markdown/ReactMarkdown';
import { externalLinkPlugin } from 'src/components/aux/markdown/externalLinkPlugin';
import StudentReferralBanner from 'src/components/banners/student-referral-banner/StudentReferralBanner';
import { DatePickerWithLabel } from 'src/components/common/DatePickerWithLabel';
import DateRangeSelector from 'src/components/common/DateRangeSelector';
import { EditableAdmissionAdvisor } from 'src/components/common/EditableAdmissionAdvisor';
import { EditableChannel } from 'src/components/common/EditableChannel';
import { LabelWithTooltip } from 'src/components/common/LabelWithTooltip';
import MarkdownTextEditor from 'src/components/common/MarkdownTextEditor';
import { SingleScholarPageSelector } from 'src/components/common/Selectors/SingleScholarPageSelector';
import { TagSelector } from 'src/components/common/Selectors/TagSelector';
import TileCheckboxGroup from 'src/components/common/TileCheckboxGroup';
import TileRadioButtonGroup from 'src/components/common/TileRadioButtonGroup';
import { ProjectIdeaAssist } from 'src/components/common/form/GptWidget/ProjectIdeaAssist';
import { LaunchpadPitch } from 'src/components/common/form/LaunchpadPitch/LaunchpadPitch';
import { authFetch } from 'src/components/customFetch';
import InputWrapper from 'src/components/mentor/InputWrapper';
import _ProjectsSection from 'src/components/mentor/ProjectsSection';
import 'src/components/mentor/details.sass';
import { EditableCounselor } from 'src/components/overviews/student/editable/EditableCounselor';
import { CartProductSelectorAdmin } from 'src/components/product/CartProductSelectorAdmin';
import { ProductSelector, ProductsTotal } from 'src/components/product/ProductSelector';
import minusIcon from 'src/components/static/images/icons/minus.svg';
import plusIcon from 'src/components/static/images/icons/plus.svg';
import { PSSInfoBox } from 'src/components/student/ApplicationHubPage/PSSInfoBox';
import { ProjectIdeas } from 'src/components/student/ProjectIdeas';
import StudentApplicationSidebar from 'src/components/student/StudentApplicationSidebar';
import { CohortSelector } from 'src/components/student/UciGatiApplicationPage/CohortSelector';
import { PathfinderApplicationInterests } from 'src/components/student/pathfinders/PathfinderApplicationInterests';
import InterestSelector from 'src/components/student/profile/InterestSelector';
import { TopCollegesSelector } from 'src/components/usersettings/TopCollegesSelector';
import { FileUploadInput } from 'src/form-builder/elements/FileUploadInput';
import { ShowcasingOutcomeInput } from 'src/form-builder/elements/ShowcasingOutcomeInput';
import { StudentVideoInterview } from 'src/students/StudentVideoInterview';
import { getStaticAsset } from 'src/utils';
import evaluateFormElementsOperations from 'src/utils/evaluateFormElementsOperations';
import {
  checkForError,
  generateRange,
  isNullString,
  isNullOption,
} from 'src/utils/formHelpFunctions';

const dates = generateRange(1920, new Date().getFullYear()).reverse();
const datesPresent = ['Current'].concat(dates);

export const PhoneNumberInput = _PhoneNumberInput;

export const TextareaWithWordCount = _TextareaWithWordCount;

export const ProjectsSection = _ProjectsSection;

export const ReadOnlyTextInput = ({ label, name, value }) => {
  return (
    <>
      <div className="required">{label}</div>
      <input type="text" className="form-control" readOnly name={name} value={value} />
    </>
  );
};

const getListOfSchools = (search) =>
  marketplaceApi.getListOfSchools(search).then(({ data: { results } }) => results);

export const SelectInput = ({
  label,
  name,
  className = '',
  id,
  small_font: smallFont,
  options,
  isSearchable,
  onChange,
  defaultValue,
  displayError,
  required,
  value,
  patchDefault,
  isClearable = false,
  async = false,
  disabled = false,
}) => {
  const [internalOptions, setInternalOptions] = useState([]);
  const [internalValue, setInternalValue] = useState();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (async) {
      options.then((result) => {
        setInternalOptions(result);
      });
    } else {
      setInternalOptions(options);
    }
  }, [options]);

  useEffect(() => {
    if (async) {
      setInternalValue(
        internalOptions.find((e) => {
          return e.value === defaultValue;
        }),
      );
    } else {
      setInternalValue(defaultValue);
    }
  }, [defaultValue, internalOptions?.length]);
  /* eslint-enable react-hooks/exhaustive-deps */

  if (patchDefault) {
    onChange({ value: defaultValue.value }, { name });
  }

  return (
    <InputWrapper required={required} value={value}>
      <span className={classnames({ required })}>{label}</span>
      <div className="small text-muted">{smallFont}</div>
      <Select
        className={`mb-5 ${className}`}
        options={internalOptions}
        isSearchable={isSearchable}
        isClearable={isClearable}
        name={name}
        id={id}
        onChange={(currentValue, action) => {
          setInternalValue(currentValue);
          onChange(currentValue, action);
        }}
        value={internalValue}
        isDisabled={disabled}
      />
      {displayError && <div className="text-danger small">This field is required.</div>}
    </InputWrapper>
  );
};

export const SelectGoogleFile = ({
  label,
  name,
  className = '',
  id,
  small_font: smallFont,
  isSearchable,
  onChange,
  displayError,
  required,
  value,
  async = false,
  disabled = false,
}) => {
  const [internalValue, setInternalValue] = useState();
  const [sharedGoogleDriveFiles, setSharedGoogleDriveFiles] = useState([]);
  const project = commonHooks.useSelectedProject();

  useEffect(() => {
    authFetch(`/google/v2/files/?workspace_id=${project.workspaceId}`)
      .then(({ files }) =>
        files.map(({ id, name, webViewLink }) => ({
          label: name,
          value: id,
          link: webViewLink,
        })),
      )
      .then((files) => {
        setSharedGoogleDriveFiles(files);
        return files;
      })
      .catch(console.error);
  }, [project.workspaceId]);

  useEffect(() => {
    setInternalValue(
      sharedGoogleDriveFiles.find((e) => {
        return e.link === value;
      }),
    );
  }, [sharedGoogleDriveFiles, value]);

  return (
    <InputWrapper required={required} value={value}>
      <span className={classnames({ required })}>{label}</span>
      <div className="small text-muted">{smallFont}</div>
      <Select
        className={`mb-5 ${className}`}
        options={sharedGoogleDriveFiles}
        value={internalValue}
        isDisabled={disabled}
        isSearchable={isSearchable}
        id={id}
        onChange={(value) => {
          setInternalValue(value);
          onChange(value.link, name);
        }}
      />
      <input type="hidden" name={name} value={internalValue?.link} />
      {displayError && <div className="text-danger small">This field is required.</div>}
    </InputWrapper>
  );
};

export const TagSelect = ({
  label,
  name,
  category = 'subject_tag',
  onChange,
  value = [],
  className,
  displayError,
  required,
}) => {
  return (
    <InputWrapper required={required} value={value}>
      <span className={classnames({ required })}>{label}</span>
      <TagInput
        className={className}
        name={name}
        category={category}
        value={value}
        onChange={onChange}
      />
      {displayError && <div className="text-danger small">This field is required.</div>}
    </InputWrapper>
  );
};

export const SingleTagSelector = ({ label, onChange, value, required, displayError }) => {
  return (
    <div id="single-tag">
      <InputWrapper required={required} value={value}>
        <span className={classnames({ required })}>{label}</span>
        <TagSelector value={value} onChange={onChange} isInvalid={displayError} />
        {displayError && <div className="text-danger small">This field is required.</div>}
      </InputWrapper>
    </div>
  );
};
export const Checkbox = ({
  label,
  id,
  name,
  onChange,
  checked = false,
  className,
  defaultValue = false,
  disabled,
}) => {
  const [internalState, setInternalState] = useState(checked || defaultValue);
  const handleChange = useCallback(
    (event) => {
      setInternalState(!internalState);
      onChange(event);
    },
    [internalState, onChange],
  );

  useEffect(() => {
    if (checked !== internalState) {
      setInternalState(checked);
    }

    // we only want to update the internal state if the change is coming from outside
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <div className={className || 'form-group form-check ms-3 my-3'}>
      <label className="form-check-label" htmlFor={id}>
        <input
          type="checkbox"
          className="form-check-input"
          id={id}
          name={name}
          onChange={handleChange}
          checked={internalState}
          disabled={disabled}
        />
        {label}
      </label>
    </div>
  );
};

export const ExperienceInput = ({ number, profile, onChange: handleChange }) => {
  const experienceStart =
    isNullString(profile[`experience_${number}_start`]) || new Date().getFullYear();
  const experienceEnd = isNullString(profile[`experience_${number}_end`]) || 'Current';

  useEffect(() => {
    handleChange({
      target: { name: `experience_${number}_start`, value: experienceStart },
    });
    handleChange({
      target: { name: `experience_${number}_end`, value: experienceEnd },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormInput
        type="text"
        name={`experience_institution_${number}`}
        placeholder=""
        label="Institution or company"
        value={isNullString(profile[`experience_institution_${number}`])}
        onChange={handleChange}
        onError={['']}
        required={false}
      />
      <FormInput
        type="text"
        name={`experience_${number}`}
        placeholder=""
        label="Your position"
        value={isNullString(profile[`experience_${number}`])}
        onChange={handleChange}
        onError={['']}
        required={false}
      />
      <FormInput
        inputType="select"
        name={`experience_${number}_start`}
        placeholder=""
        label="Start"
        value={experienceStart}
        onChange={handleChange}
        onError={['']}
        required={false}
        options={dates}
      />
      <FormInput
        inputType="select"
        name={`experience_${number}_end`}
        placeholder=""
        label="End"
        value={experienceEnd}
        onChange={handleChange}
        onError={['']}
        required={false}
        options={datesPresent}
      />
    </>
  );
};

export const Toggler = ({ id, name, onChange, checked, label, checkedLabel = label }) => {
  return (
    <>
      <input
        type="checkbox"
        className="form-check-input hidden"
        id={id}
        name={name}
        onChange={onChange}
        checked={checked}
      />
      <label className="d-flex form-check-label my-5" htmlFor={id}>
        <div
          className="clickable box h-100 d-flex justify-content-center"
          // eslint-disable-next-line react/forbid-dom-props
          style={{ zIndex: '100' }}
        >
          <img height="25" alt={checked ? 'minus' : 'plus'} src={checked ? minusIcon : plusIcon} />
        </div>
        <div className="ms-5 box h-100 justify-content-center text-bold">
          {checked ? checkedLabel : `Add additional ${label}`}
        </div>
      </label>
    </>
  );
};

export const Divider = () => {
  return (
    <div className="my-5">
      <hr />
    </div>
  );
};

export const Heading = ({ text, className = 'text-info', component }) => {
  const Component = component ?? 'h5';
  return <Component className={className}>{text}</Component>;
};

export const CenteredIllustration = (props) => {
  return (
    <div className="d-flex justify-content-center">
      <Illustration {...props} />
    </div>
  );
};

export const HelperText = ({ text }) => {
  return (
    <p
      // eslint-disable-next-line react/forbid-dom-props
      style={{
        textAlign: 'center',
        color: 'var(--grayscale-8)',
        fontSize: 18,
        fontWeight: 300,
      }}
    >
      {text}
    </p>
  );
};

export const HeadingWithIcon = ({ text, iconName, required = false }) => {
  return (
    <ComponentsHeading size="h4" alignment="left" className="position-relative mb-3">
      {iconName && (
        <Icon
          name={iconName}
          size="40px"
          local
          style={{ position: 'absolute', left: '-56px', top: '-8px' }}
        />
      )}
      <span className={required ? 'required' : ''}>{text}</span>
    </ComponentsHeading>
  );
};

export const SubHeading = ({ text }) => {
  return (
    <ComponentsHeading size="h4" alignment="left" className="mb-3">
      {text}
    </ComponentsHeading>
  );
};

export const Paragraph = ({ text, className }) => {
  return (
    <ReactMarkdown className={className} rehypePlugins={externalLinkPlugin}>
      {text}
    </ReactMarkdown>
  );
};

export const ConnectStripe = () => {
  return (
    <div className="text-center mb-7">
      <a
        target="_blank"
        className="btn btn-info px-md-2 py-2"
        rel="noopener noreferrer"
        href="https://app.polygence.org/dashboard/payment-setup"
      >
        Provide account details
      </a>
    </div>
  );
};

export const SubjectSelector = ({ profile: { tags, id }, missingFields }) => {
  const subjectTags = tags.filter((tag) => {
    return tag.category === 'subject_tag';
  });
  let isInvalid = false;
  let error = 'This field is not valid';
  if (missingFields.includes('tags')) {
    // eslint-disable-next-line fp/no-mutation
    isInvalid = true;
  }
  // eslint-disable-next-line fp/no-mutation
  error = isInvalid && tags.length < 3 ? 'List at least 3 areas' : 'List at most 5 areas';

  return (
    <div className="mb-5 position-relative" id="mentor-tags">
      <span className="required">
        List of subjects that best describe your expertise (between 3-5).
      </span>

      <DisplayTags
        tags={subjectTags}
        category="subject_tag"
        label=""
        color="info"
        name="tags"
        editable="inline"
        changeUrl={`/api/mentors/${id}/tags/`}
        forceReload={() => {
          window.location.reload();
        }}
        className=""
        minTagCount={3}
        maxTagCount={5}
      />
      {isInvalid && error && <div className="row col text-danger small">{error}</div>}
    </div>
  );
};

const OTHER_OPTION = 'Other';
const DEGREE_OPTIONS = [
  'PhD',
  'MD',
  'MD/PhD',
  'JD',
  'DPhil',
  'MBA',
  'MSc',
  'MS',
  'MA',
  'MPhil',
  'BSc',
  'BS',
  'BA',
  OTHER_OPTION,
];

export const HighestAcademicDegree = ({ onChange: handleChange, profile, missingFields }) => {
  const [type, setType] = useState(null);
  const [typeOther, setTypeOther] = useState('');
  const [year, setYear] = useState('');

  const getType = () => {
    return type === OTHER_OPTION ? typeOther : type;
  };

  useEffect(() => {
    const { top_credential: topCredential } = profile;

    if (topCredential === null) {
      return;
    }

    const [newType, newYear] = topCredential.split(',').map((item) => {
      return String(item).trim();
    });

    if (DEGREE_OPTIONS.includes(newType)) {
      setType(newType);
    } else {
      setType(OTHER_OPTION);
      setTypeOther(newType);
    }

    if (newYear) {
      setYear(newYear);
    }
  }, [profile]);

  useEffect(() => {
    if (type === null) {
      return;
    }
    handleChange({
      target: { name: 'top_credential', value: `${getType()}, ${year}` },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, typeOther, year]);

  const error = missingFields.includes('top_credential');

  return (
    <div className="form-group">
      <div className="row">
        <label className="required" htmlFor="top_credential">
          Highest academic degree obtained or currently pursued:
        </label>
      </div>
      <Select
        className={classnames({
          'react-select-required is-invalid': error,
        })}
        options={DEGREE_OPTIONS.map((item) => {
          return {
            value: item,
            label: item,
          };
        })}
        name="top_credential"
        id="top_credential"
        placeholder="e.g. PhD"
        required
        onChange={({ value }) => {
          setType(value);
          setTypeOther('');
        }}
        value={type ? { value: type, label: type } : null}
      />
      {error && <div className="row col text-danger small">This field is required.</div>}
    </div>
  );
};

export const RadioButton = ({
  options,
  name,
  onChange,
  onBlur,
  profile,
  hidden,
  label,
  showLabel = true,
  small_font: smallFont,
  required = false,
  onError = [],
}) => {
  const isSelected = profile[name] !== undefined;
  const defaultValue = options.find(({ default: _default }) => {
    return _default;
  })?.value;
  const error = onError[0];

  return (
    <div className={classnames('form-group ms-3', { 'd-none': hidden })}>
      {showLabel && <span className={required ? 'required' : ''}>{label}</span>}
      {smallFont && <div className="text-muted small mb-5">{smallFont}</div>}
      {error && <div className="text-danger small">{error}</div>}
      {options.map(({ label: optionLabel, value, disabled }) => {
        const checked = isSelected ? profile[name] === value : defaultValue === value;

        return (
          <div className="form-check" key={`${name}-${value}`}>
            <input
              className="form-check-input"
              type="radio"
              name={name}
              id={`${name}-${value}`}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
              checked={checked}
              hidden={hidden}
              required={required}
            />
            <label className="form-check-label" htmlFor={`${name}-${value}`}>
              {optionLabel}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export const StarRating = ({
  label,
  onChange: handleChange,
  size = '3rem',
  required = false,
  value,
  starCount = 5,
  starColor = 'var(--warning)',
  emptyColor = 'var(--grayscale-4)',
  name,
}) => {
  const onInputChange = (event) => {
    handleChange({ target: { name, value: event.currentTarget.value } });
  };

  return (
    <InputWrapper required={required} value={value}>
      <label className={classnames({ required }, 'd-block')}>{label}</label>
      {Array(starCount)
        .fill(null)
        .map((v, i) => {
          return (
            <label
              // eslint-disable-next-line react/forbid-dom-props
              style={{
                cursor: 'pointer',
                fontSize: size,
                color: i + 1 <= value ? starColor : emptyColor,
              }}
              className="mx-3"
              htmlFor={`${name}_${i + 1}`}
              key={`${name}_${i + 1}`}
            >
              <input
                type="radio"
                name={name}
                id={`${name}_${i + 1}`}
                value={i + 1}
                className="d-none"
                onChange={onInputChange}
              />
              {/** */}★
            </label>
          );
        })}
    </InputWrapper>
  );
};

export const NumberSelector = ({
  name,
  label,
  startFrom = 1,
  numberCount = 10,
  defaultValue,
  onChange,
  className,
  value,
  disabled = false,
  required,
  ...props
}) => {
  const [selected, setSelected] = useState(value || defaultValue || '');
  const numbers = [
    ...Array.from({ length: numberCount }, (_, i) => {
      return i + startFrom;
    }),
  ];

  const selectValue = (value) => {
    setSelected(value);
    if (onChange) {
      onChange({ target: { name, value } });
    }
  };

  const classes = `number-selector ${className || ''}`;

  return (
    <InputWrapper value={selected} required={required} name={name} id={name}>
      <label className={classnames({ required }, 'd-block mb-0')} htmlFor={name}>
        {label}
      </label>
      <div className={classes} {...props}>
        {numbers.map((number) => {
          return (
            <Button
              key={number}
              size="sm"
              variant={selected === number ? 'primary' : 'secondary'}
              onClick={() => {
                return selectValue(number);
              }}
              disabled={disabled}
            >
              <strong>{number}</strong>
            </Button>
          );
        })}
      </div>
    </InputWrapper>
  );
};

const HoursAndMinutes = ({ label, small_font: smallFont, name, onChange, required, value }) => {
  const [timeValues, setTimeValues] = useState({});
  useEffect(() => {
    if (value == null) {
      return;
    }
    const [hours, minutes] = hoursAndMinutes(Number(value));
    setTimeValues({ hours, minutes });
  }, [value]);

  const handleFocus = (event) => {
    event.target.select();
  };

  const handleChange = (target) => {
    return (e) => {
      setTimeValues((val) => {
        return { ...val, [target]: Number(e.target.value) };
      });
    };
  };

  const handleBlur = () => {
    const hours = timeValues.hours ?? 0;
    const minutes = timeValues.minutes ?? 0;
    onChange({
      target: {
        name,
        value: Number(hours) * 60 + Number(minutes),
      },
    });
  };

  return (
    <InputWrapper required={required} value={value} name={name}>
      <span className={classnames({ required })}>{label}</span>
      <div className="small text-muted">{smallFont}</div>
      <div className="d-flex">
        <div className="input-group me-3">
          <input
            type="number"
            className="form-control"
            id={`${name}_hours`}
            value={timeValues.hours ?? ''}
            onChange={handleChange('hours')}
            onBlur={handleBlur}
            onFocus={handleFocus}
            min="0"
            step="1"
          />
          <span className="input-group-text">hours</span>
        </div>
        <div className="input-group">
          <input
            type="number"
            className="form-control"
            id={`${name}_minutes`}
            value={timeValues.minutes ?? ''}
            onChange={handleChange('minutes')}
            onBlur={handleBlur}
            onFocus={handleFocus}
            min="0"
            step="1"
          />
          <span className="input-group-text">minutes</span>
        </div>
      </div>
    </InputWrapper>
  );
};

const Note = ({ title, text }) => {
  return (
    <Card body>
      {title && (
        <ComponentsHeading size="h5" alignment="left" className="mb-3">
          {title}
        </ComponentsHeading>
      )}
      <ReactMarkdown>{text}</ReactMarkdown>
    </Card>
  );
};

const Details = ({ summary, detail }) => {
  return <Accordion summary={summary} detail={detail} />;
};

const ResponsiveDetails = ({ summary, detail }) => {
  return (
    <>
      <div className="d-none d-md-block">
        <Alert>{`${summary} ${detail}`}</Alert>
      </div>
      <div className="d-md-none">
        <Accordion summary={summary} detail={detail} />
      </div>
    </>
  );
};

export const components = {
  DividerWithText: {
    Component: DividerWithText,
  },
  UciCohortSelector: {
    Component: CohortSelector,
    getDerivedProps: ({ profile, props }) => {
      const value = profile[props.name];

      return {
        uuid: profile.uuid,
        uciCohort: profile.uciCohort,
        value,
        extras: profile.extras,
      };
    },
  },
  FileUploadInput: {
    Component: FileUploadInput,
    getDerivedProps: ({ onChange, profile: data, props: { onUpload, onDelete, required } }) => {
      const callbacks = {
        uploadFinancialAidDocuments: marketplaceApi.uploadFinancialAidDocuments,
        deleteAllFinancialAidDocuments: marketplaceApi.deleteAllFinancialAidDocuments,
        uploadFile: marketplaceApi.uploadFile,
        deleteFile: () => {
          return Promise.resolve();
        },
      };
      return {
        onChange,
        onUpload: callbacks[onUpload],
        onDelete: callbacks[onDelete],
        data,
        required,
      };
    },
  },
  TileCheckboxGroup: {
    Component: TileCheckboxGroup,
    getDerivedProps: ({ onChange, profile }) => {
      return {
        onChange,
        data: profile,
      };
    },
  },
  TileRadioButtonGroup: {
    Component: TileRadioButtonGroup,
    getDerivedProps: ({ onChange, profile, props }) => {
      const value = profile[props.name];

      return {
        onChange,
        value,
      };
    },
  },
  StudentApplicationSidebar: {
    Component: StudentApplicationSidebar,
    getDerivedProps: ({ props: { avatar } }) => {
      return {
        avatar: getStaticAsset(avatar),
      };
    },
  },
  HoursAndMinutes: {
    Component: HoursAndMinutes,
    // eslint-disable-next-line sonarjs/no-identical-functions
    getDerivedProps: ({ onChange, profile, props }) => {
      const value = profile[props.name];
      return {
        onChange,
        value,
      };
    },
  },
  Icon: {
    Component: Icon,
  },
  CenteredIllustration: {
    Component: CenteredIllustration,
  },
  LaunchpadPitchWithProjectIdea: {
    Component: LaunchpadPitch,
    getDerivedProps: ({ onChange, profile, props }) => {
      const value = profile[props.name];

      return {
        beforeHeader: <></>,
        header: (
          <ComponentsHeading
            alignment="left"
            size="h3"
            as="h3"
            className="text-white"
            fontWeight="normal"
          >
            Now that you have a project idea, do you want to refine it with our{' '}
            <strong>Launchpad program</strong>?
          </ComponentsHeading>
        ),
        description: (
          <Text size="medium" className="text-white" fontWeight="normal">
            In Launchpad you'll meet with <strong>three mentors</strong> prior to your Core Program
            to discuss you project idea and get help to refine it to be even better.
          </Text>
        ),
        question: (
          <>
            <Text size="medium" className="mt-7 mb-8 text-white" fontWeight="normal">
              Launchpad is an <strong>add-on program</strong> to be done before
              <br />
              your Core Program starts, for an <strong>additional cost</strong>.
            </Text>
            <Text size="medium" className="mb-8 text-white" fontWeight="normal">
              Would you like to learn more about the program later?
            </Text>
          </>
        ),
        value: value,
        onChange: (value) => {
          onChange({ target: { name: props.name, value: value === 'yes' } });
        },
      };
    },
  },
  LaunchpadPitchWithoutProjectIdea: {
    Component: LaunchpadPitch,
    getDerivedProps: ({ onChange, profile, props }) => {
      const value = profile[props.name];

      return {
        beforeHeader: <></>,
        header: (
          <ComponentsHeading
            alignment="left"
            size="h3"
            as="h3"
            className="text-white"
            fontWeight="normal"
          >
            Get a clear project idea, tailored to you with our add on{' '}
            <strong>Launchpad program</strong>
          </ComponentsHeading>
        ),
        description: (
          <Text size="medium" className="text-white" fontWeight="normal">
            You’ll meet with <strong>three mentors</strong> prior to your Core Program to gain
            inspiration and workshop a project idea together. By the end, you’ll have a clear
            project idea to embark on in your Core Program.
          </Text>
        ),
        question: (
          <>
            <Text size="medium" className="mt-7 mb-8 text-white" fontWeight="normal">
              Launchpad is an <strong>add-on program</strong> to be done before
              <br />
              your Core Program starts, for an <strong>additional cost</strong>.
            </Text>
            <Text size="medium" className="mb-8 text-white" fontWeight="normal">
              Would you like to learn more about the program later?
            </Text>
          </>
        ),
        value: value,
        onChange: (value) => {
          onChange({ target: { name: props.name, value: value === 'yes' } });
        },
      };
    },
  },
  Heading: {
    Component: Heading,
  },
  HeadingWithIcon: {
    Component: HeadingWithIcon,
  },
  SubHeading: {
    Component: SubHeading,
  },
  ComponentsHeading: {
    Component: ComponentsHeading,
  },
  ComponentsText: {
    Component: ComponentsText,
  },
  LabelWithTooltip: {
    Component: LabelWithTooltip,
  },
  Paragraph: {
    Component: Paragraph,
  },
  ReadOnlyTextInput: {
    Component: ReadOnlyTextInput,
    getDerivedProps: ({ profile, props }) => {
      return { value: profile[props.name] };
    },
  },
  FormInput: {
    Component: FormInput,
    getDerivedProps: ({ profile, props, missingFields, onChange, onBlur }) => {
      return {
        value: isNullString(profile[props.name]),
        onChange,
        onBlur,
        onError: [checkForError(props.name, missingFields)],
      };
    },
  },
  PhoneNumberInput: {
    Component: PhoneNumberInput,
    getDerivedProps: ({ profile, props, missingFields, onChange, onBlur }) => {
      return {
        value: isNullString(profile[props.name]),
        handleChange: onChange,
        onBlur,
        onError: [checkForError(props.name, missingFields)],
      };
    },
  },
  ComponentsPhoneInput: {
    Component: ComponentsPhoneInput,
    getDerivedProps: ({ profile, props, onChange, onBlur, validationResult }) => {
      return {
        value: profile[props.name],
        onChange,
        onBlur,
        isInvalid: !validationResult[props.name]?.valid,
        errorMessage: validationResult[props.name]?.message,
        shouldValidate: false,
      };
    },
  },
  TextareaWithWordCount: {
    Component: TextareaWithWordCount,
    // eslint-disable-next-line sonarjs/no-identical-functions
    getDerivedProps: ({ profile, props, missingFields, onChange, onBlur }) => {
      return {
        value: isNullString(profile[props.name]),
        onChange,
        onBlur,
        onError: [checkForError(props.name, missingFields)],
      };
    },
  },
  SelectInput: {
    Component: SelectInput,
    getDerivedProps: ({ profile, props, missingFields, onChange, onBlur }) => {
      const key = props.defaultValueKey ? props.defaultValueKey : props.name;
      let defaultValue =
        props.options.length &&
        (props.options.filter(({ value }) => {
          return value === profile[key];
        })[0] ||
          props.options.filter(({ default: _default }) => {
            return _default;
          }));
      let patchDefault = false;

      if (defaultValue?.length === 0 && props.defaultValue) {
        // eslint-disable-next-line fp/no-mutation
        defaultValue = props.defaultValue;
        // eslint-disable-next-line fp/no-mutation
        patchDefault = !!props.patchDefault;
      }

      if (props.async) {
        // eslint-disable-next-line fp/no-mutation
        defaultValue = profile[key];
      }

      return {
        defaultValue,
        patchDefault,
        onChange: ({ value }, { name }) => {
          onChange({ target: { name, value } });
          if (typeof onBlur === 'function') {
            onBlur({ target: { name, value } });
          }
        },
        className: checkForError(props.name, missingFields)
          ? // eslint-disable-next-line sonarjs/no-duplicate-string
            'react-select-required is-invalid'
          : '',
        value: profile[key],
        displayError: checkForError(props.name, missingFields),
      };
    },
  },
  SelectGoogleFile: {
    Component: SelectGoogleFile,
    getDerivedProps: ({ profile, props, missingFields, onChange, onBlur }) => {
      return {
        // eslint-disable-next-line sonarjs/no-identical-functions
        onChange: (value, name) => {
          onChange({ target: { name, value } });
          if (typeof onBlur === 'function') {
            onBlur({ target: { name, value } });
          }
        },
        className: checkForError(props.name, missingFields)
          ? // eslint-disable-next-line sonarjs/no-duplicate-string
            'react-select-required is-invalid'
          : '',
        value: profile[props.name],
        displayError: checkForError(props.name, missingFields),
      };
    },
  },
  TagSelect: {
    Component: TagSelect,
    getDerivedProps: ({ profile, props, missingFields, onChange, onBlur }) => {
      return {
        onChange: (target) => {
          onChange(target);
          if (typeof onBlur === 'function') {
            onBlur(target);
          }
        },
        className: checkForError(props.name, missingFields)
          ? classnames(props.className, 'react-select-required is-invalid')
          : props.className,
        value: profile[props.name],
        displayError: checkForError(props.name, missingFields),
      };
    },
  },
  CountrySelect: {
    Component: CountrySelect,
    getDerivedProps: ({ profile, props, missingFields, onChange, onBlur }) => {
      return {
        // eslint-disable-next-line sonarjs/no-identical-functions
        onChange: (target) => {
          onChange(target);
          if (typeof onBlur === 'function') {
            onBlur(target);
          }
        },
        value: profile[props.name],
        showError: checkForError(props.name, missingFields),
        errors: [checkForError(props.name, missingFields)],
      };
    },
  },
  Checkbox: {
    Component: Checkbox,
    getDerivedProps: ({ profile, props, onChange, onBlur }) => {
      return {
        checked: props.reversed ? !profile[props.name] : profile[props.name],
        onChange: ({ target: { name, checked } }) => {
          onChange({
            target: { name, value: props.reversed ? !checked : checked },
          });
          if (typeof onBlur === 'function') {
            onBlur({
              target: { name, value: props.reversed ? !checked : checked },
            });
          }
        },
      };
    },
  },
  DatePickerWithLabel: {
    Component: DatePickerWithLabel,
    getDerivedProps: ({ profile, props, onChange, onBlur, missingFields }) => {
      return {
        onChange: (e) => {
          onChange(e);
          if (typeof onBlur === 'function') {
            onBlur(e);
          }
        },
        value: profile[props.name],
        name: props.name,
        localeTime: props.localeTime,
        dateFormat: props.dateFormat,
        onError: [checkForError(props.name, missingFields)],
      };
    },
  },
  ConnectStripe: {
    Component: ConnectStripe,
  },
  ExperienceInput: {
    Component: ExperienceInput,
    getDerivedProps: ({ profile, onChange }) => {
      return { profile, onChange };
    },
  },
  SubjectSelector: {
    Component: SubjectSelector,
    getDerivedProps: ({ profile, onChange, missingFields }) => {
      return {
        profile,
        onChange: ({ target: { name, checked } }) => {
          return onChange({ target: { name, value: checked } });
        },
        missingFields,
      };
    },
  },
  UploadImage: {
    Component: UploadImage,
    getDerivedProps: ({ missingFields }) => {
      return {
        missingFields,
      };
    },
  },
  ProjectsSection: {
    Component: ProjectsSection,
    getDerivedProps: ({ profile, missingFields }) => {
      return {
        profile,
        missingFields,
      };
    },
  },
  HighestAcademicDegree: {
    Component: HighestAcademicDegree,
    getDerivedProps: ({ profile, onChange, missingFields }) => {
      return {
        profile,
        onChange,
        missingFields,
      };
    },
  },
  SelectWithOther: {
    Component: SelectWithOther,
    getDerivedProps: ({ profile, props, missingFields, onChange, onBlur }) => {
      return {
        value: isNullString(profile[props.name]),
        otherValue: isNullString(profile[props.otherName]),
        onError: {
          [props.name]: checkForError(props.name, missingFields),
          [props.otherName]: checkForError(props.otherName, missingFields),
        },
        // eslint-disable-next-line sonarjs/no-identical-functions
        onChange: (e) => {
          onChange(e);
          if (typeof onBlur === 'function') {
            onBlur(e);
          }
        },
      };
    },
  },
  SchoolSelector: {
    Component: AsyncSelectWithOther,
    getDerivedProps: ({ profile, props, onChange, onBlur }) => {
      return {
        defaultValue: isNullOption(profile[props.name], profile[props.nameLabel]),
        otherValue: isNullString(profile[props.otherName]),
        onChange,
        onBlur,
        name: props.name,
        otherName: props.otherName,
        mapOptions: ({ id, name, city }) => ({ value: id, label: `${name} (${city})` }),
        loadSource: getListOfSchools,
        nullifyLogic: (handleChange) => {
          if (props.name === 'middleSchool') {
            handleChange({ target: { name: 'highSchool', value: null } });
            handleChange({ target: { name: 'highSchoolOther', value: null } });
            if (profile[props.name] && profile[props.name] !== 'other') {
              handleChange({ target: { name: 'middleSchoolOther', value: null } });
            }
          } else if (props.name === 'highSchool') {
            handleChange({ target: { name: 'middleSchool', value: null } });
            handleChange({ target: { name: 'middleSchoolOther', value: null } });
            if (profile[props.name] && profile[props.name] !== 'other') {
              handleChange({ target: { name: 'highSchoolOther', value: null } });
            }
          }
        },
      };
    },
  },
  SelectWithClarification: {
    Component: SelectWithClarification,
    getDerivedProps: ({ profile, props, missingFields, onChange, onBlur }) => {
      return {
        value: isNullString(profile[props.name]),
        clarificationValue: isNullString(profile[props.clarificationName]),
        onError: {
          [props.name]: checkForError(props.name, missingFields),
          [props.clarificationName]: checkForError(props.clarificationName, missingFields),
        },
        // eslint-disable-next-line sonarjs/no-identical-functions
        onChange: (e) => {
          onChange(e);
          if (typeof onBlur === 'function') {
            onBlur(e);
          }
        },
      };
    },
  },
  Divider: {
    Component: Divider,
  },
  Toggler: {
    Component: Toggler,
    getDerivedProps: ({ profile, props, onChange }) => {
      return {
        checked: profile[props.name],
        onChange: ({ target: { name, checked } }) => {
          return onChange({ target: { name, value: checked } });
        },
      };
    },
  },
  RadioButton: {
    Component: RadioButton,
    getDerivedProps: ({ onChange, profile, props, missingFields, onBlur }) => {
      return {
        onChange,
        onBlur,
        profile,
        hidden: props.hidden,
        onError: [checkForError(props.name, missingFields)],
      };
    },
  },
  ComponentsCheckbox: {
    Component: ComponentsCheckbox,
    getDerivedProps: ({ profile, onChange, props }) => {
      const checked = Boolean(profile[props.name]);

      return {
        checked,
        onChange,
        ...props,
      };
    },
  },
  ComponentsRadioButton: {
    Component: ComponentsRadioButton,
    getDerivedProps: ({ profile, onChange, props }) => {
      const { checkedIfInArray, ...rest } = props;

      const isChecked = checkedIfInArray
        ? checkedIfInArray.includes(profile[props.name])
        : profile[props.name] === props.value;

      return {
        checked: isChecked,
        onChange,
        ...rest,
      };
    },
  },
  StarRating: {
    Component: StarRating,
    getDerivedProps: ({ props, profile, onChange }) => {
      return {
        onChange,
        value: isNullString(profile[props.name]),
      };
    },
  },
  NumberSelector: {
    Component: NumberSelector,
    getDerivedProps: ({ profile, onChange, props }) => {
      return {
        profile,
        onChange,
        props,
        value: isNullString(profile[props.name]),
      };
    },
  },
  MarkdownEditor: {
    Component: MarkdownTextEditor,
    getDerivedProps: ({ profile, missingFields, props, onChange }) => {
      return {
        defaultValue: isNullString(profile[props.name]),
        name: props.name,
        onChange: (value) => {
          onChange({
            target: {
              value,
              name: props.name,
            },
          });
        },
        displayError: checkForError(props.name, missingFields),
      };
    },
  },
  InterestSelector: {
    Component: InterestSelector,
    getDerivedProps: ({ profile, onChange, onBlur }) => {
      return {
        subjects: profile,
        handleChange: ({ target: { name, value } }) => {
          if (typeof name.forEach === 'function') {
            name.forEach((subject) => {
              onChange({ target: { name: subject, value } });
            });
          } else {
            onChange({ target: { name, value } });
          }
          if (typeof onBlur === 'function') {
            onBlur({ target: { name, value } });
          }
        },
      };
    },
  },
  EditableChannelWithCounselorDetails: {
    Component: EditableChannelWithCounselorDetails,
    getDerivedProps: ({ profile, props }) => {
      return {
        userId: profile.userId,
        channel: {
          value: profile.channel,
          label: profile.channelLabel,
        },
        counselorDetails: profile.counselorDetails,
        label: props.label,
      };
    },
  },
  EditableChannel: {
    Component: EditableChannel,
    getDerivedProps: ({ profile, props }) => {
      return {
        channel: {
          value: profile.channel,
          label: profile.channelLabel,
        },
        userId: profile.userId,
        disabled: props.disabled,
        label: props.label,
      };
    },
  },
  EditableCounselor: {
    Component: EditableCounselor,
    getDerivedProps: ({ profile, onChange }) => {
      return {
        studentChannel: profile.channel,
        studentId: profile.id,
        counselor: profile.counselorDetails,
        onChange,
      };
    },
  },
  EditableAdmissionAdvisor: {
    Component: EditableAdmissionAdvisor,
    getDerivedProps: ({ profile }) => {
      return {
        studentId: profile.id,
        admissionAdvisor: profile.admissionAdvisor,
      };
    },
  },
  TopCollegesSelector: {
    Component: TopCollegesSelector,
    getDerivedProps: ({ profile }) => {
      return { profileId: profile.id };
    },
  },
  ProjectIdeaAssist: {
    Component: ProjectIdeaAssist,
  },
  DateRangeSelector: {
    Component: DateRangeSelector,
    getDerivedProps: ({ profile, props, onChange, validationResult }) => {
      return {
        timeline: {
          [props.startAt]: profile[props.startAt],
          [props.endAt]: profile[props.endAt],
        },
        handleChange: onChange,
        isInvalid:
          validationResult[props.name] == null ? false : !validationResult[props.name]?.valid,
        errorMessage: validationResult[props.name]?.message,
      };
    },
  },
  Note: {
    Component: Note,
  },
  InputField: {
    Component: InputField,
    getDerivedProps: ({ profile, props, onChange, onBlur, validationResult }) => {
      return {
        value: isNullString(profile[props.name]),
        onChange,
        onBlur,
        isInvalid:
          validationResult[props.name] == null ? false : !validationResult[props.name]?.valid,
        errorMessage: validationResult[props.name]?.message,
      };
    },
  },
  EmailInputField: {
    Component: FormEmailInput,
    getDerivedProps: ({ profile, props, onChange, onBlur, validationResult }) => {
      return {
        base: InputField,
        value: isNullString(profile[props.name]),
        onChange,
        onBlur,
        isInvalid:
          validationResult[props.name] == null ? false : !validationResult[props.name]?.valid,
        errorMessage: validationResult[props.name]?.message,
      };
    },
  },
  Details: {
    Component: Details,
  },
  ResponsiveDetails: {
    Component: ResponsiveDetails,
  },
  Alert: {
    Component: Alert,
  },
  Spacer: {
    Component: Spacer,
  },
  StudentVideoInterview: {
    getDerivedProps: ({ onChange, props: { videoAskBaseSrc, name }, profile }) => {
      const handleSubmit = () => {
        const value = new Date().toISOString();
        const event = { target: { name, value } };

        onChange(event);
        marketplaceApi.updateStudentProfileForCoreApp({
          ...profile,
          [name]: value,
        });
      };
      return {
        onSubmit: handleSubmit,
        defaultSubmitted: profile[name] !== null,
        options: {
          videoAskBaseSrc,
        },
        profileId: profile.id,
        applicationId: profile.studentApplicationId,
        applicationRevampEnabled: profile.applicationRevampEnabled,
      };
    },
    Component: StudentVideoInterview,
  },
  AdmissionAdvisorConcerns: {
    Component: AdmissionAdvisorConcerns,
    getDerivedProps: ({ profile, onChange }) => {
      return {
        onChange,
        aaConcerns: profile.admissionAdvisorConcerns,
        aaConcernAltProgram: profile.admissionAdvisorConcernAlternativeProgram,
        aaConcernOther: profile.admissionAdvisorConcernOther,
      };
    },
  },
  AdmissionAdvisorStudentsInterests: {
    Component: AdmissionAdvisorStudentsInterests,
    getDerivedProps: ({ profile, onChange }) => {
      return {
        onChange,
        aaStudentsInterests: profile.admissionAdvisorStudentsInterests,
        aaStudentsInterestOther: profile.admissionAdvisorStudentsInterestOther,
      };
    },
  },
  ProjectIdeas: {
    Component: ProjectIdeas,
    getDerivedProps: ({ profile }) => {
      return {
        generalInterest: profile.generalInterest,
      };
    },
  },
  PathfinderInterests: {
    Component: PathfinderApplicationInterests,
    getDerivedProps: ({ profile, onChange }) => {
      return {
        onChange,
        interests: Array.from(
          { ...profile.interests, length: profile.subjectsLimit },
          (value) => value ?? 0,
        ),
        applicationId: profile.applicationId,
      };
    },
  },
  ProductSelector: {
    Component: ProductSelector,
    getDerivedProps: ({ profile, onChange }) => {
      return {
        onChange,
        selectedProductIds: profile.selectedProducts,
        channel: profile.channel,
        partnershipArrangement: profile.partnershipArrangement,
        paymentIntentUuid: profile.paymentIntentUuid,
        displayPitchable: true,
      };
    },
  },
  ProductsTotal: {
    Component: ProductsTotal,
    getDerivedProps: ({ profile }) => {
      return {
        selectedProductIds: profile.selectedProducts,
        partnershipArrangement: profile.partnershipArrangement,
        channel: profile.channel,
      };
    },
  },
  CartProductSelectorAdmin: {
    Component: CartProductSelectorAdmin,
    getDerivedProps: ({ profile, onChange }) => {
      return { onChange, cartUuid: profile.cartUuid };
    },
  },
  StudentReferralBanner: {
    Component: StudentReferralBanner,
    getDerivedProps: ({ profile }) => {
      return {
        bannerText: profile.bannerText,
        firstProfileCompletionAt: profile.firstProfileCompletionAt,
      };
    },
  },
  SingleScholarPageSelector: {
    Component: SingleScholarPageSelector,
    getDerivedProps: ({ profile, props, onChange, validationResult }) => {
      const defaultValue = profile.wordOfMouthReferralExistingScholar
        ? profile.wordOfMouthReferralExistingScholar
        : toOption(profile.wordOfMouthReferral);
      return {
        defaultValue,
        label: props.label,
        onChange,
        isInvalid:
          validationResult[props.name] == null ? false : !validationResult[props.name]?.valid,
        errorMessage: validationResult[props.name]?.message,
      };
    },
  },
  ShowcasingOutcomeInput: {
    Component: ShowcasingOutcomeInput,
    getDerivedProps: ({ onChange, profile: data, props }) => {
      return { onChange, defaultValue: data[props.name] };
    },
  },
  SingleTagSelector: {
    Component: SingleTagSelector,
    getDerivedProps: ({ onChange, profile, required, missingFields, props }) => {
      return {
        value: profile?.primary_tag,
        onChange: ({ value }) => {
          onChange({ target: { name: props.name, value } });
        },
        displayError: checkForError(props.name, missingFields),
      };
    },
  },
  MultiToggleSelect: {
    Component: MultiToggleSelect,
    getDerivedProps: ({ onChange, profile, props }) => {
      return {
        value: props.options.filter((option) => profile[option.value]),
        onChange: (values) => {
          values.map(({ name, value }) => onChange({ target: { name, value } }));
        },
      };
    },
  },
  PSSInfoBox: {
    Component: PSSInfoBox,
    getDerivedProps: ({ onChange }) => {
      return { onClick: () => onChange({ target: { name: 'interestedInPss', value: true } }) };
    },
  },
  InstitutionSelector: {
    Component: InstitutionSelector,
    getDerivedProps: ({ profile, props, onChange }) => {
      return {
        value: isNullOption(profile[props.name], profile[props.name]),
        isInvalid: props.required && !profile[props.name],
        onChange,
      };
    },
  },
  AdmissionAdvisorModalInfo: {
    Component: AdmissionAdvisorModalInfo,
  },
};

export const GroupField = ({ fields, profile, ...groupFieldProps }) => {
  return (
    <>
      {fields.map(({ component, props, display }, idx) => {
        const {
          Component,
          getDerivedProps = () => {
            return null;
          },
        } = components[component];
        return (
          evaluateFormElementsOperations(display, profile) && (
            <Component
              {...props}
              {...getDerivedProps({ props, profile, ...groupFieldProps })}
              key={`${component}-${props?.name || idx}`}
            />
          )
        );
      })}
    </>
  );
};

// eslint-disable-next-line fp/no-mutation
components.GroupField = {
  Component: GroupField,
  getDerivedProps: ({ props, ...rest }) => {
    return rest;
  },
};
