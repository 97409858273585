import { createApi } from '@reduxjs/toolkit/query/react';

import { HermesAIMessageThreadId, UserSnippetId, WorkspaceId } from '../types/common';
import { HermesAIMessageThread, HermesAIMessageThreadMessages, UserSnippet } from '../types/hermes';
import { handleWebsocketMessage, getConnection } from '../websocket/hermes-ai';

import { axiosBaseQuery } from './fetch';

export const hermesAiApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'hermesAIApi',
  tagTypes: ['UserSnippets'],
  endpoints: (build) => ({
    createAiMessageThread: build.mutation<HermesAIMessageThread, WorkspaceId>({
      query: (workspaceId) => {
        return {
          url: '/hermes/ai-message-threads/',
          method: 'POST',
          data: {
            workspace: workspaceId,
          },
        };
      },
    }),
    listMessageThreads: build.query<HermesAIMessageThread[], WorkspaceId>({
      query: (workspaceId) => {
        return {
          url: `/hermes/ai-message-threads/`,
          method: 'GET',
          params: { workspace_id: workspaceId },
        };
      },
    }),
    getMessages: build.query<HermesAIMessageThreadMessages, HermesAIMessageThreadId>({
      query: (id) => {
        return {
          url: `/hermes/ai-message-threads/${id}/`,
          method: 'GET',
        };
      },
      onCacheEntryAdded: async (
        hermesAiMessageThreadId,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) => {
        // create a websocket connection when the cache subscription starts
        const ws = getConnection();

        const listener = handleWebsocketMessage(hermesAiMessageThreadId, updateCachedData);

        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;
          ws.subscribe(listener);
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        ws.unsubscribe(listener);
      },
    }),
    createUserSnippet: build.mutation<UserSnippet, string>({
      query: (content) => {
        return {
          url: `/hermes/ai-message-threads/user-snippets/`,
          method: 'POST',
          data: {
            content,
          },
        };
      },
    }),
    getUserSnippets: build.query<UserSnippet[], HermesAIMessageThreadId>({
      query: (id) => {
        return {
          url: `/hermes/ai-message-threads/${id}/user-snippets/`,
          method: 'GET',
        };
      },
      providesTags: (_, __, id) => [{ type: 'UserSnippets', id: id }],
    }),
    editUserSnippet: build.mutation<UserSnippet, { id: UserSnippetId; content: string }>({
      query: ({ id, content }) => {
        return {
          url: `/hermes/ai-message-threads/user-snippets/${id}/`,
          method: 'PATCH',
          data: { content },
        };
      },
    }),
    deleteUserSnippet: build.mutation<void, UserSnippetId>({
      query: (id) => {
        return {
          url: `/hermes/ai-message-threads/user-snippets/${id}/`,
          method: 'DELETE',
        };
      },
    }),
    getUnsentUserSnippet: build.query<UserSnippet, UserSnippetId>({
      query: (id) => {
        return {
          url: `/hermes/ai-message-threads/user-snippets/${id}/`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useCreateAiMessageThreadMutation,
  useGetMessagesQuery,
  usePrefetch,
  useCreateUserSnippetMutation,
  useGetUserSnippetsQuery,
  useDeleteUserSnippetMutation,
  useEditUserSnippetMutation,
  useGetUnsentUserSnippetQuery,
  useListMessageThreadsQuery,
} = hermesAiApi;
