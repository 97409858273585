/* eslint-disable react/jsx-max-depth */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { featureDecisions, UUID } from '@polygence/common';
import { ProductType } from '@polygence/common/types/hermes';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';

import { CreateNewProject } from 'src/polygpt/CreateNewProject';
import { PolyGPTContext } from 'src/polygpt/PolyGPTContext';
import { PolyPilotProjectWrapper } from 'src/polygpt/PolyPilotProject';
import styles from 'src/polygpt/polygpt.module.scss';
import { usePolyGptSocketConnection } from 'src/polygpt/usePolyGptSocket';
import { polyGptApi } from 'src/reducers/polyGptReducers';
import { useAppDispatch } from 'src/store';
import { isCartCookieProductValid } from 'src/utils/cart';

export const PolyGptPage = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [isPolyGptEnabled, setIsPolyGptEnabled] = useState(false);
  const [hideSystemMessages, setHideSystemMessages] = useState(false);

  const socketConnection = usePolyGptSocketConnection({ closeOnUnmount: false });

  const messageContainerRef = useRef<HTMLDivElement>(null);
  const contextValue = useMemo(
    () => ({
      websocketConnection: socketConnection,
      hideSystemMessages,
      setHideSystemMessages,
      messageContainerRef,
    }),
    [socketConnection, hideSystemMessages, setHideSystemMessages, messageContainerRef],
  );

  const selectProject = useCallback(
    (projectId: number | UUID) => {
      history.push(`${match.url}/${String(projectId)}`);
    },
    [history, match.url],
  );

  useEffect(() => {
    if (!socketConnection) {
      return;
    }

    function handleMessage(event: MessageEvent) {
      const data = JSON.parse(event.data);

      if (data.type === 'polygpt.project.created') {
        selectProject(data.message);
        void dispatch(
          polyGptApi.endpoints.listPolyPilotProjects.initiate(undefined, { forceRefetch: true }),
        );
      }
    }
    socketConnection.addEventListener('message', handleMessage);

    return () => socketConnection.removeEventListener('message', handleMessage);
  }, [socketConnection, selectProject, dispatch]);

  useEffect(() => {
    featureDecisions
      .getInstance()
      .then((featureDecisionsInstance) => {
        setIsPolyGptEnabled(
          featureDecisionsInstance?.polyGptEnabled() ||
            isCartCookieProductValid(ProductType.POLYPILOT),
        );
      })
      .catch((e) => console.error(e));
  }, []);

  if (!isPolyGptEnabled) {
    return null;
  }

  return (
    <PolyGPTContext.Provider value={contextValue}>
      <div className={styles['polygptContainer']}>
        <div className={classNames('d-flex flex-column flex-md-row h-100', styles['maxWidth'])}>
          <Switch>
            <Route path={`${match.url}/:projectId`}>
              <PolyPilotProjectWrapper selectProject={selectProject} />
            </Route>
            <Route path={`${match.url}/`}>
              <CreateNewProject />
            </Route>
          </Switch>
        </div>
      </div>
    </PolyGPTContext.Provider>
  );
};
