import { externalLink } from '@polygence/common';
import type { AdmissionAdvisorModal } from '@polygence/common';
import * as marketplaceApi from '@polygence/common/api/marketplace';
import useCurrentUser from '@polygence/common/hooks/selectors/useCurrentUser';
import { useEffect, useState } from 'react';

import TeamMemberTile from 'src/components/Tiles/TeamMemberTile';
import { STUDENT_TEAM_EMAIL } from 'src/constants';
import { AdmissionAdvisorAction } from 'src/types/marketplace';
import { addInviteeInformationToCalendlyUrl } from 'src/utils/addInviteeInformationToCalendlyUrl';

interface AdmissionAdvisorTileProps {
  email: string | null;
}

export const AdmissionAdvisorTile = ({ email }: AdmissionAdvisorTileProps) => {
  const [advisor, setAdvisor] = useState<AdmissionAdvisorModal>();
  const { firstName, lastName, email: currentUserEmail } = useCurrentUser();

  useEffect(() => {
    marketplaceApi
      .getAdmissionAdvisorModal(email)
      .then(({ data }) => {
        setAdvisor(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [email]);

  return (
    <TeamMemberTile
      firstName={advisor?.firstName}
      imageUrl={advisor?.imageUrl}
      role={advisor?.role}
    >
      <>
        {advisor?.action === AdmissionAdvisorAction.GeneralEmail && (
          <a href={`mailto:${STUDENT_TEAM_EMAIL}`} {...externalLink}>
            Reach out to {advisor?.firstName}
          </a>
        )}
        {advisor?.action === AdmissionAdvisorAction.CalendlyUrl && (
          <a
            href={addInviteeInformationToCalendlyUrl(advisor?.calendlyUrl, {
              name: `${firstName} ${lastName}`,
              email: currentUserEmail,
            })}
            {...externalLink}
          >
            Set up a call with {advisor?.firstName}
          </a>
        )}
      </>
    </TeamMemberTile>
  );
};
