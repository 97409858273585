/* eslint-disable sonarjs/no-duplicate-string */
import { ApplicationSectionIds } from 'src/constants/application-sections';
import type { ApplicationComponentSection } from 'src/students/student-application';
import { schoolGraduationYearProps } from 'src/students/student-application';
import {
  contentSpacer,
  headingProps,
  headingSpacer,
  illustrationSize,
} from 'src/students/student-application/common-section-components';
import { counselorDetailsFollowup } from 'src/students/student-application/counselor-details-followup';
import {
  PARENT_SECTION_A,
  PARENT_SECTION_B,
} from 'src/students/student-application/parent-sections';
import { referralQuestions } from 'src/students/student-application/referral-questions';

export const studentProfileSections = Object.freeze<ApplicationComponentSection[]>([
  {
    id: ApplicationSectionIds.PRONOUNS_SECTION_ID,
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            [{ component: 'AdmissionAdvisorModalInfo' }],
            [
              {
                component: 'CenteredIllustration',
                props: {
                  name: 'SpaceAstronautOxygenTank',
                  size: illustrationSize,
                },
              },
              headingSpacer,
              {
                component: 'ComponentsHeading',
                props: {
                  children: 'Choose your pronouns.',
                  ...headingProps,
                },
              },
              contentSpacer,
              {
                component: 'ComponentsRadioButton',
                validation: {
                  required: true,
                },
                tag: 'profile',
                props: {
                  name: 'pronoun',
                  label: 'He/ him',
                  value: 'he_him',
                },
              },
              {
                component: 'ComponentsRadioButton',
                tag: 'profile',
                props: {
                  name: 'pronoun',
                  label: 'She/ her',
                  value: 'she_her',
                },
              },
              {
                component: 'ComponentsRadioButton',
                tag: 'profile',
                props: {
                  name: 'pronoun',
                  label: 'They/ them',
                  value: 'they_them',
                },
              },
              {
                component: 'ComponentsRadioButton',
                tag: 'profile',
                props: {
                  name: 'pronoun',
                  label: 'Custom',
                  value: 'other',
                },
              },
              {
                component: 'InputField',
                display: {
                  $is: {
                    field: 'pronoun',
                    value: 'other',
                  },
                },
                tag: 'profile',
                props: {
                  label: 'Please specify',
                  name: 'pronounOther',
                },
                validation: {
                  required: true,
                },
              },
            ],
          ],
        },
      },
    ],
  },
  {
    id: ApplicationSectionIds.REFERRAL_SECTION_ID,
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            [{ component: 'AdmissionAdvisorModalInfo' }],
            [
              {
                component: 'CenteredIllustration',
                props: {
                  name: 'SpaceRabbitSpaceship',
                  size: illustrationSize,
                },
              },
              headingSpacer,
              {
                component: 'ComponentsHeading',
                props: {
                  children: 'How did you hear about Polygence?',
                  ...headingProps,
                },
              },
              contentSpacer,
              ...referralQuestions,
            ],
          ],
        },
      },
    ],
  },
  {
    id: ApplicationSectionIds.SCHOOL_SECTION_ID,
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            [{ component: 'AdmissionAdvisorModalInfo' }],
            [
              {
                component: 'CenteredIllustration',
                props: {
                  name: 'SpaceAstronautStars',
                  size: illustrationSize,
                },
              },
              headingSpacer,
              {
                component: 'ComponentsHeading',
                props: {
                  children: 'Are you currently a student?',
                  ...headingProps,
                },
              },
              contentSpacer,
              {
                component: 'ComponentsRadioButton',
                tag: 'profile',
                props: {
                  name: 'schoolGradeOther',
                  label: "Yes, I'm a middle school student.",
                  value: 'middle_school_student',
                },
                validation: {
                  required: true,
                },
              },
              {
                component: 'SchoolSelector',
                tag: 'profile',
                props: {
                  label: 'Your school',
                  name: 'middleSchool',
                  nameLabel: 'middleSchoolLabel',
                  otherName: 'middleSchoolOther',
                  placeholder: 'Write here',
                  defaultOptions: [],
                },
                validation: {
                  selectWithOther: true,
                },
                display: {
                  $is: {
                    field: 'schoolGradeOther',
                    value: 'middle_school_student',
                  },
                },
              },
              {
                component: 'DatePickerWithLabel',
                tag: 'profile',
                props: {
                  ...schoolGraduationYearProps,
                  label: 'Expected high school grad year',
                  customInput: true,
                },
                validation: {
                  required: true,
                },
                display: {
                  $is: {
                    field: 'schoolGradeOther',
                    value: 'middle_school_student',
                  },
                },
              },
              {
                component: 'ComponentsRadioButton',
                tag: 'profile',
                props: {
                  name: 'schoolGradeOther',
                  label: "Yes, I'm a high school student.",
                  value: 'high_school_student',
                },
              },
              {
                component: 'SchoolSelector',
                tag: 'profile',
                props: {
                  label: 'Your school',
                  name: 'highSchool',
                  nameLabel: 'highSchoolLabel',
                  otherName: 'highSchoolOther',
                  placeholder: 'Write here',
                  defaultOptions: [],
                },
                display: {
                  $is: {
                    field: 'schoolGradeOther',
                    value: 'high_school_student',
                  },
                },
                validation: {
                  selectWithOther: true,
                },
              },
              {
                component: 'DatePickerWithLabel',
                tag: 'profile',
                props: {
                  ...schoolGraduationYearProps,
                  label: 'Expected graduation year',
                  customInput: true,
                },
                display: {
                  $is: {
                    field: 'schoolGradeOther',
                    value: 'high_school_student',
                  },
                },
                validation: {
                  required: true,
                },
              },
              {
                component: 'ComponentsRadioButton',
                tag: 'profile',
                props: {
                  name: 'schoolGradeOther',
                  label: "Yes, I'm a college student.",
                  value: 'college_student',
                },
              },
              {
                component: 'DatePickerWithLabel',
                tag: 'profile',
                props: {
                  ...schoolGraduationYearProps,
                  label: 'Expected graduation year',
                  customInput: true,
                },
                display: {
                  $is: {
                    field: 'schoolGradeOther',
                    value: 'college_student',
                  },
                },
                validation: {
                  required: true,
                },
              },
              {
                component: 'ComponentsRadioButton',
                tag: 'profile',
                props: {
                  name: 'schoolGradeOther',
                  label: "No, I'm not currently in school.",
                  value: 'not_in_school',
                },
              },
              {
                component: 'DatePickerWithLabel',
                tag: 'profile',
                props: {
                  ...schoolGraduationYearProps,
                  label: 'Expected graduation year',
                  customInput: true,
                },
                display: {
                  $is: {
                    field: 'schoolGradeOther',
                    value: 'not_in_school',
                  },
                },
                validation: {
                  required: true,
                },
              },
            ],
          ],
        },
      },
    ],
  },
  {
    id: ApplicationSectionIds.COUNSELOR_SECTION_ID,
    elements: [
      {
        component: 'ThreeColumnLayout',
        props: {
          children: [
            [{ component: 'AdmissionAdvisorModalInfo' }],
            [
              {
                component: 'CenteredIllustration',
                props: {
                  name: 'SpaceProject',
                  size: illustrationSize,
                },
              },
              headingSpacer,
              {
                component: 'ComponentsHeading',
                props: {
                  children: 'Do you have a guidance counselor?',
                  ...headingProps,
                },
              },
              contentSpacer,
              {
                component: 'ComponentsRadioButton',
                tag: 'profile',
                props: {
                  name: 'counselorType',
                  label: 'Yes, I work with a private counselor.',
                  value: 'external',
                },
                validation: {
                  required: true,
                },
              },
              {
                component: 'ComponentsText',
                display: {
                  $is: {
                    field: 'counselorType',
                    value: 'external',
                  },
                },
                props: {
                  children:
                    'It is super important to provide the correct counselor information to ensure your counselor discount is applied. We recommend copy and pasting from your last communications with them.',
                  fontWeight: 'light',
                  size: 'small',
                },
              },
              {
                component: 'Box',
                display: {
                  $is: {
                    field: 'counselorType',
                    value: 'external',
                  },
                },
                props: {
                  children: counselorDetailsFollowup,
                },
              },
              {
                component: 'ComponentsRadioButton',
                tag: 'profile',
                props: {
                  name: 'counselorType',
                  label: 'Yes, I work with my school counselor but not with a private counselor.',
                  value: 'school',
                },
              },
              {
                component: 'Box',
                display: {
                  $is: {
                    field: 'counselorType',
                    value: 'school',
                  },
                },
                props: {
                  children: counselorDetailsFollowup,
                },
              },
              {
                component: 'ComponentsRadioButton',
                tag: 'profile',
                props: {
                  name: 'counselorType',
                  label: "No, I'm not working with any guidance counselor.",
                  value: 'none',
                },
              },
            ],
          ],
        },
      },
    ],
  },
  {
    ...PARENT_SECTION_A,
    display: {
      $is: {
        field: 'applicationRevampEnabled',
        value: false,
      },
    },
  },
  {
    ...PARENT_SECTION_B,
    display: {
      $is: {
        field: 'applicationRevampEnabled',
        value: true,
      },
    },
  },
]);
