import type { Product } from '@polygence/common/types/studentApplication';
import { Button, Icon, Text, Tooltip } from '@polygence/components';
import { FontWeight } from '@polygence/components/dist/types';
import type { MouseEventHandler } from 'react';

import { AmountOrPlaceholder } from 'src/payment/PaymentIntentV2/AmountOrPlaceholder';
import styles from 'src/payment/PaymentIntentV3/AddableProduct.module.scss';

export const AddableProduct = ({
  product,
  onClick,
  disabled,
  fontWeight,
}: {
  product: Product;
  onClick: ({ id }: { id: number }) => void;
  disabled: boolean;
  fontWeight?: FontWeight;
}) => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
    onClick({ id: product.id });
  };

  return (
    <div className="d-flex align-items-start gap-5">
      <div>
        <Text
          size="medium"
          as="span"
          alignment="left"
          className="text-reset"
          fontWeight={fontWeight}
        >
          {product.publicName}
        </Text>
        {product.publicDetails && (
          <Tooltip tip={product.publicDetails} placement="top">
            <Icon id="info" size="sm" className="text-muted ms-2 mt-n2" />
          </Tooltip>
        )}
      </div>
      <div className="d-flex align-items-center gap-5 ms-auto">
        {Number(product.basePriceInDollars) !== product.totalPriceInCents / 100 && (
          <AmountOrPlaceholder
            size="small"
            amount={+product.totalPriceInCents / 100}
            className={styles['originalPrice']}
          />
        )}
        <AmountOrPlaceholder className="text-reset" amount={+product.basePriceInDollars} />
        <Button size="sm" variant="secondary" onClick={handleClick} disabled={disabled}>
          Add
        </Button>
      </div>
    </div>
  );
};
