/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable fp/no-mutation */
import type { UUID } from '@polygence/common';
import classNames from 'classnames';
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { validate as isValidUUID } from 'uuid';

import { PageLoad } from 'src/components/PageLoad';
import { SupportWidget } from 'src/components/hermes/SupportWidget/SupportWidget';
import { usePolyGPTContext } from 'src/polygpt/PolyGPTContext';
import { PolyGPTMessageEditor } from 'src/polygpt/PolyGPTMessageEditor';
import { PolyGPTMessageList } from 'src/polygpt/PolyGPTMessageList';
import { PolyPilotMobileNavigation } from 'src/polygpt/PolyPilotMobileNavigation';
import { PolyPilotProjectList } from 'src/polygpt/PolyPilotProjectList';
import { PolyPilotSidebarHeader } from 'src/polygpt/PolyPilotSidebarHeader';
import { PolyPilotStageTracker } from 'src/polygpt/PolyPilotStageTracker';
import { PolyGPTDebugPanel } from 'src/polygpt/debug-features/PolyGPTDebugPanel';
import { useIsPolyGptAdmin } from 'src/polygpt/debug-features/useIsPolyGptAdmin';
import { useIsPolyPilotMentor } from 'src/polygpt/debug-features/useIsPolyPilotMentor';
import { useIsPolyPilotTester } from 'src/polygpt/debug-features/useIsPolyPilotTester';
import styles from 'src/polygpt/polygpt.module.scss';
import { useIsPaidProject } from 'src/polygpt/useIsPaidProject';
import { usePolyPilotMobileNavigation } from 'src/polygpt/usePolyPilotMobileNavigation';
import {
  useGetPolyPilotProjectMessagesQuery,
  useLazyGetProjectByUuidQuery,
  useListPolyPilotProjectsQuery,
} from 'src/reducers/polyGptReducers';
import { polyGptApi } from 'src/reducers/polyGptReducers';
import { useAppDispatch } from 'src/store';
import type { Message } from 'src/types/polygence/polygpt';

export const PolyPilotProject = ({ projectId }: { projectId: number }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isPolyGptAdmin = useIsPolyGptAdmin();
  const isPolyPilotMentor = useIsPolyPilotMentor();
  const isPolyPilotTester = useIsPolyPilotTester();
  const [isResponding, setIsResponding] = useState(false);

  const { data: apiMessages, isLoading } = useGetPolyPilotProjectMessagesQuery(projectId, {
    refetchOnMountOrArgChange: true,
  });
  const { websocketConnection, hideSystemMessages, messageContainerRef } = usePolyGPTContext();
  const [newMessages, setNewMessages] = useState<Message[]>([]);
  const messages = useMemo(() => {
    const previousMessages = apiMessages ?? [];
    const allMessages = [...newMessages, ...previousMessages];
    const shouldHideSystemMessages =
      !(isPolyGptAdmin || isPolyPilotMentor || isPolyPilotTester) || hideSystemMessages;

    return shouldHideSystemMessages
      ? allMessages.filter((message) => message.type !== 'system')
      : allMessages;
  }, [
    apiMessages,
    newMessages,
    isPolyGptAdmin,
    isPolyPilotMentor,
    isPolyPilotTester,
    hideSystemMessages,
  ]);
  const hasAssistantMessage = messages.some((message) => message.type === 'assistant');

  const updateLatestMessageWith = (updateFn: (prevLast: Message) => Message) => {
    setNewMessages((messages) => {
      const latestMessage = messages[0] ?? {
        type: 'assistant',
        message: '',
      };

      return [updateFn(latestMessage), ...messages.slice(1, messages.length)];
    });
  };

  const addMessage = useCallback((message: Message) => {
    setNewMessages((messages) => [message, ...messages]);
  }, []);

  const streamMessage = useCallback((message: string) => {
    updateLatestMessageWith((latestMessage) => ({
      ...latestMessage,
      message: message,
    }));
  }, []);

  const scrollToBottom = () => {
    if (!messageContainerRef?.current) {
      return;
    }

    messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (!websocketConnection) {
      return;
    }
    let unblock: (() => void) | null = null;

    function handleSocketMessage(event: MessageEvent) {
      const data = JSON.parse(event.data);

      if (data.project_id !== projectId) {
        return;
      }

      if (data.type === 'polygpt.message.add') {
        const { id, type, name, message, tool_calls } = data.message;
        addMessage({ type, message, id, name, toolCalls: tool_calls });
      }

      if (data.type === 'polygpt.message.start') {
        addMessage({ type: 'assistant', message: data.message });
        setIsResponding(true);
        unblock = history.block('Answer is still in progress, are you sure you want to leave?');
      }

      if (data.type === 'polygpt.message.chunk') {
        streamMessage(data.message);
      }

      if (data.type === 'polygpt.message.finish') {
        const id = data.message.id;
        updateLatestMessageWith((latestMessage) => ({ ...latestMessage, id }));
        setIsResponding(false);

        if (unblock) {
          unblock();
          unblock = null;
        }
      }

      if (data.type === 'polygpt.system.message') {
        addMessage({ type: 'system', message: data.message });
      }

      if (data.type === 'polygpt.error') {
        toast.error(data.message.error);
      }

      if (data.type === 'polygpt.title.refreshed') {
        void dispatch(
          polyGptApi.endpoints.listPolyPilotProjects.initiate(undefined, { forceRefetch: true }),
        );
      }
    }

    websocketConnection.addEventListener('message', handleSocketMessage);

    return () => {
      websocketConnection.removeEventListener('message', handleSocketMessage);
      if (unblock) {
        unblock();
      }
    };
  }, [websocketConnection, addMessage, streamMessage, history, projectId, dispatch]);

  const onMessageSend = (message: string) => {
    scrollToBottom();
    addMessage({ type: 'user', message });
  };

  if (isLoading) {
    return null;
  }

  const isResendEnabled = () => {
    return (
      !isResponding &&
      messages &&
      messages.length > 0 &&
      messages[0]?.type === 'user' &&
      messages[0]?.name === 'student'
    );
  };

  return (
    <div className={styles['polygptConversationContainer']}>
      <div className={styles['messageContainer']} ref={messageContainerRef}>
        <PolyGPTMessageList messages={messages} isResponding={isResponding} projectId={projectId} />
      </div>
      <div className={styles['editor']}>
        <PolyGPTMessageEditor
          projectId={projectId}
          onMessageSend={onMessageSend}
          isMessageSendDisabled={isResponding || !hasAssistantMessage}
          isResendEnabled={isResendEnabled()}
        />
      </div>
    </div>
  );
};

export const PolyPilotProjectWrapper = ({
  selectProject,
}: {
  selectProject: (id: number | UUID) => void;
}) => {
  const { projectId } = useParams<{ projectId: string | undefined }>();
  const [projectIdNumber, setProjectIdNumber] = useState<number | null>(null);

  const projectIdString = projectId ? projectId : undefined;

  const { data: projects = [], isLoading } = useListPolyPilotProjectsQuery();
  const [getProjectByUuid] = useLazyGetProjectByUuidQuery();
  const isAdmin = useIsPolyGptAdmin();
  const isMentor = useIsPolyPilotMentor();
  const isTester = useIsPolyPilotTester();
  const isPaidProject = useIsPaidProject(projectIdNumber);

  const showProjectSelector = isAdmin || isMentor || isTester || projects.length > 1;
  const { registerSwipeControls, activeTab, tabs } = usePolyPilotMobileNavigation({
    showProjectSelector,
  });
  const activeTabIndex = Math.max(tabs.indexOf(activeTab), 0);

  useEffect(() => {
    if (projectIdString) {
      if (isValidUUID(projectIdString)) {
        getProjectByUuid(projectIdString)
          .then((result) => {
            setProjectIdNumber(result.data?.id || null);
          })
          .catch((err) => console.error(err));
      } else {
        setProjectIdNumber(parseInt(projectIdString));
      }
    }
  }, [getProjectByUuid, projectIdString]);

  if (!projectIdNumber) {
    return null;
  }

  if (isLoading) {
    return <PageLoad />;
  }

  return (
    <>
      <div
        className={classNames(styles['polyPilotProject'], {
          [styles['withProjectSelector'] as string]: showProjectSelector,
        })}
        // eslint-disable-next-line react/forbid-dom-props
        style={{ '--tabIndex': activeTabIndex } as CSSProperties}
        {...registerSwipeControls}
      >
        {showProjectSelector && (
          <div className="px-3">
            <PolyPilotProjectList selectProject={selectProject} />
          </div>
        )}
        <div className="h-100 px-3">
          <PolyPilotProject projectId={projectIdNumber} key={projectIdNumber} />
        </div>
        <div className="d-flex flex-column px-3 pt-5">
          <PolyPilotSidebarHeader projectId={projectIdNumber} />
          <PolyPilotStageTracker projectId={projectIdNumber} key={`${projectId}__tracker`} />
          {isPaidProject && (
            <SupportWidget
              formName="polypilot-support"
              pilotProjectId={projectIdNumber}
              className={classNames({
                [styles['polyPilotSupportWidget'] as string]: !showProjectSelector,
              })}
            />
          )}
          <PolyGPTDebugPanel />
        </div>
      </div>
      <PolyPilotMobileNavigation activeTab={activeTab} tabs={tabs} />
    </>
  );
};
