/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import NiceModal from '@ebay/nice-modal-react';
import {
  Badge,
  Button,
  Icon,
  OptionType,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SingleValue,
  Text,
  ToggleSwitch,
  Tooltip,
  Checkbox,
} from '@polygence/components';
import { CSSProperties, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { toast } from 'react-toastify';
import { validate as isValidUUID } from 'uuid';

import { usePolyGPTContext } from 'src/polygpt/PolyGPTContext';
import { PolyGPTExportButton } from 'src/polygpt/PolyGPTExportButton';
import { MentorMessageModal } from 'src/polygpt/debug-features/MentorMessageModal';
import styles from 'src/polygpt/debug-features/PolyGPTDebugPanel.module.scss';
import { PolyPilotMentorSelector } from 'src/polygpt/debug-features/PolyPilotMentorSelector';
import { ResearchPaperSearch } from 'src/polygpt/debug-features/ResearchPaperSearch';
import { useIsPolyGptAdmin } from 'src/polygpt/debug-features/useIsPolyGptAdmin';
import { useIsPolyPilotMentor } from 'src/polygpt/debug-features/useIsPolyPilotMentor';
import { useIsPolyPilotTester } from 'src/polygpt/debug-features/useIsPolyPilotTester';
import { useLazyGetProjectByUuidQuery } from 'src/reducers/polyGptReducers';
import {
  useListStageVersionsQuery,
  useUpdatePolypilotProjectMutation,
} from 'src/reducers/polyPilotAdminReducers';

export const PolyGPTDebugPanel = () => {
  const match = useRouteMatch<{ projectId: string }>('/polypilot/:projectId');
  const projectId = match?.params?.projectId;
  const isPolyGptAdmin = useIsPolyGptAdmin();
  const isPolyPilotMentor = useIsPolyPilotMentor();
  const isPolyPilotTester = useIsPolyPilotTester();
  const [metadata, setMetadata] = useState({
    project_class: undefined,
  });

  const { data: stageVersions = [] } = useListStageVersionsQuery();

  const [projectIdNumber, setProjectIdNumber] = useState<number | null>(null);

  const [getProjectByUuid] = useLazyGetProjectByUuidQuery();

  useEffect(() => {
    if (projectId) {
      if (isValidUUID(projectId)) {
        getProjectByUuid(projectId)
          .then((result) => {
            setProjectIdNumber(result.data?.id || null);
          })
          .catch((err) => console.error(err));
      } else {
        setProjectIdNumber(parseInt(projectId));
      }
    }
  }, [getProjectByUuid, projectId]);

  const [updatePolypilotProject] = useUpdatePolypilotProjectMutation();

  const versionOptions = stageVersions.map((stageVersion) => ({
    value: stageVersion.id,
    label: stageVersion.name,
  }));

  const [selectedVersionId, setSelectedVersionId] = useState<number | null>(null);
  const [isUnlocked, setIsUnlocked] = useState<boolean>(false);

  const selectedVersionOption = versionOptions.find((option) => option.value === selectedVersionId);

  const handleSelectedVersionChange = (newValue: SingleValue<OptionType>) => {
    setSelectedVersionId(newValue ? +newValue.value : null);
  };

  const openMentorMessageModal = () => {
    NiceModal.show(`mentor-message-${projectIdNumber}`).catch(console.error);
  };

  const { websocketConnection, hideSystemMessages, setHideSystemMessages } = usePolyGPTContext();

  const { project_class: projectClass } = metadata;

  const handleCreateNewProject = () => {
    if (!websocketConnection) {
      return;
    }

    websocketConnection.send(
      JSON.stringify({
        type: 'polygpt.create_project',
        stage_version_name: selectedVersionOption?.label,
        is_unlocked: isUnlocked,
      }),
    );
  };

  const handleChangeMentor = (value: SingleValue<OptionType>) => {
    if (!projectIdNumber) {
      return;
    }

    updatePolypilotProject([projectIdNumber, { mentor: value?.value as number }])
      .unwrap()
      .then(() => {
        toast.success('Mentor successfully assigned.');
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  useEffect(() => {
    if (!websocketConnection) {
      return;
    }

    function handleSocketMessage(event: MessageEvent) {
      const data = JSON.parse(event.data);

      if (data.type === 'polygpt.metadata') {
        setMetadata(data.message);
      }
    }

    websocketConnection.addEventListener('message', handleSocketMessage);

    return () => {
      websocketConnection.removeEventListener('message', handleSocketMessage);
    };
  }, [websocketConnection]);

  if (!(isPolyGptAdmin || isPolyPilotMentor || isPolyPilotTester) || !projectId) {
    return null;
  }

  return (
    <Popover>
      <PopoverTrigger>
        <Button size="sm" className={styles['debugPanelTrigger']}>
          DEBUG
        </Button>
      </PopoverTrigger>
      <PopoverContent className="d-flex flex-column p-4 gap-3">
        {(isPolyPilotMentor || isPolyPilotTester) && (
          <>
            <div className="d-flex align-items-center justify-content-between gap-3">
              <Button variant="tertiary" size="sm" onClick={() => openMentorMessageModal()}>
                Send
              </Button>
              <Text size="small" fontWeight="bold" className="text-nowrap">
                Send message to student
              </Text>
              {projectIdNumber && (
                <MentorMessageModal
                  id={`mentor-message-${projectIdNumber}`}
                  projectId={projectIdNumber}
                />
              )}
            </div>
          </>
        )}
        {isPolyPilotTester && (
          <>
            <label
              htmlFor="hide_system"
              className="d-flex align-items-center justify-content-between gap-3"
            >
              <ToggleSwitch
                name="hide_system"
                aria-label="hide system messages"
                checked={hideSystemMessages}
                onCheckedChange={() => {
                  setHideSystemMessages?.((prevState) => !prevState);
                }}
              />
              <Text size="small" fontWeight="bold" className="text-nowrap">
                Hide System Messages
              </Text>
            </label>
            <PolyPilotMentorSelector
              menuPlacement="auto"
              isMulti={false}
              onChange={handleChangeMentor}
              label="Assign a mentor"
            />
          </>
        )}
        {isPolyGptAdmin && (
          <>
            <div className="d-flex gap-3 align-items-center">
              <Badge variant="info">Debug</Badge>
              <Text size="small" fontWeight="light">
                Only refreshes after a new message
              </Text>
            </div>
            <div className="d-flex justify-content-center">
              <ResearchPaperSearch />
            </div>
            <div className="d-flex align-items-center gap-3 mt-4">
              <label
                htmlFor="hide_system"
                className="d-flex align-items-center justify-content-between gap-3"
              >
                <ToggleSwitch
                  name="hide_system"
                  aria-label="hide system messages"
                  checked={hideSystemMessages}
                  onCheckedChange={() => {
                    setHideSystemMessages?.((prevState) => !prevState);
                  }}
                />
                <Text size="small" fontWeight="bold" className="text-nowrap">
                  Hide System Messages
                </Text>
              </label>
              <Tooltip tip="Edit system prompts">
                <Button
                  variant="link"
                  size="sm"
                  endIcon={<Icon id="edit" />}
                  href="/administrator/polypilot-stage_editor"
                />
              </Tooltip>
            </div>

            <div className="d-flex gap-3 mt-3">
              <div className="flex-grow-1 p-2">
                <Text size="small">Project ID: {projectId}</Text>
                <Text size="small">Project class: {projectClass}</Text>
              </div>
              <div className="p-2">
                <PolyGPTExportButton projectId={projectId} />
              </div>
            </div>

            <div>
              <Text size="small" fontWeight="normal">
                Create a new project from the selected version:
              </Text>
              <div className="d-flex flex-row mt-3">
                <Select
                  value={selectedVersionOption}
                  options={versionOptions}
                  onChange={handleSelectedVersionChange}
                  size="small"
                  className="mb-n5"
                  menuPlacement="auto"
                />
                <Checkbox
                  label="Unlocked"
                  checked={isUnlocked}
                  name="isUnlocked"
                  className="mx-4"
                  onChange={() => {
                    setIsUnlocked(!isUnlocked);
                  }}
                />
                <Tooltip tip="Start a new project" placement="top">
                  <Button
                    startIcon={<Icon id="plus" color="white" />}
                    onClick={handleCreateNewProject}
                    variant="tertiary"
                    size="sm"
                    className="p-3 align-self-center ms-auto"
                    style={{ '--btn-transform-hover': 'none' } as CSSProperties}
                    disabled={!selectedVersionId}
                  />
                </Tooltip>
              </div>
            </div>

            <div>
              <Text size="small" fontWeight="normal">
                Send message to student
              </Text>
              <Button variant="tertiary" size="sm" onClick={() => openMentorMessageModal()}>
                Send message
              </Button>
              {projectIdNumber && (
                <MentorMessageModal
                  id={`mentor-message-${projectIdNumber}`}
                  projectId={projectIdNumber}
                />
              )}
            </div>
          </>
        )}
      </PopoverContent>
    </Popover>
  );
};
