import { useGetPolyPilotProjectDetailsQuery } from 'src/reducers/polyGptReducers';

export const useIsPaidProject = (projectId: number | null) => {
  const { isProjectUnlocked } = useGetPolyPilotProjectDetailsQuery(projectId ?? 0, {
    selectFromResult: ({ data }) => {
      return {
        isProjectUnlocked: Boolean(data?.unlockedAt),
      };
    },
    skip: !projectId,
  });

  return isProjectUnlocked;
};
