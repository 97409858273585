import * as paymentApi from '@polygence/common/api/payment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import ErrorPage from 'src/components/ErrorPage';
import { PageLoad } from 'src/components/PageLoad';
import { RedirectNotification } from 'src/components/RedirectNotification';

const OneTimeAccessPaymentMethodPage = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const [url, setUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    paymentApi
      .getOneTimeCustomerPortalUrl(uuid)
      .then(({ data }) => {
        setUrl(data.customerPortalUrl);
      })
      .catch(({ response }: { response: { data: { error: string } } }) => {
        setError(response?.data?.error || 'Something went wrong');
      });
  }, [uuid]);
  if (error) {
    return <ErrorPage error={error} />;
  }
  if (url) {
    return <RedirectNotification url={url} />;
  }
  return <PageLoad />;
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default OneTimeAccessPaymentMethodPage;
