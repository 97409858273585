import type {
  ComponentDisplay,
  ApplicationComponentDescription,
} from 'src/students/student-application';

export const narrowFieldSpacer = Object.freeze<ApplicationComponentDescription>({
  component: 'Spacer',
  props: { size: 3 },
});

export const textFieldSpacer = Object.freeze<ApplicationComponentDescription>({
  component: 'Spacer',
  props: { size: 6 },
});

export const headingSpacer = Object.freeze<ApplicationComponentDescription>({
  component: 'Spacer',
  props: {
    size: 8,
  },
});

export const contentSpacer = Object.freeze<ApplicationComponentDescription>({
  component: 'Spacer',
  props: {
    size: {
      base: 8,
      lg: 10,
    },
  },
});

export const displayForConnectedCounselor: ComponentDisplay = {
  $not: {
    field: 'connectedCounselorFirstName',
    value: null,
  },
};

export const displayIfNoCounselor: ComponentDisplay = {
  $is: {
    field: 'connectedCounselorFirstName',
    value: null,
  },
};

export const headingProps = Object.freeze({
  size: {
    base: 'h4',
    lg: 'h3',
  },
  as: 'h1',
  bold: true,
});

export const illustrationSize = 'var(--student-app-illustration-size)';

export const influencerTextProps = Object.freeze({
  children: 'We are always looking for great influencers to follow/support!',
  fontWeight: 'light',
  size: 'small',
  className: 'text-muted ms-4 mt-2',
});
