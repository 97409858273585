import { getRestClient } from '../commonSettings';
import type { StudentApplicationId, StudentProfileId } from '../types/common';
import type {
  StudentApplication,
  StudentOverviewStudentApplication,
} from '../types/studentApplication';

export const startNewApplication = (
  profileId: StudentProfileId,
  copyPreviousApplication = false,
) => {
  const internalFetch = getRestClient();
  return internalFetch.post<StudentApplication>(
    `/student-application/students/${profileId}/applications/`,
    { copyPreviousApplication },
  );
};

export const getApplication = (
  profileId: StudentProfileId,
  applicationId: StudentApplicationId,
) => {
  const internalFetch = getRestClient();
  return internalFetch.get<StudentApplication>(
    `/student-application/students/${profileId}/applications/${applicationId}/`,
  );
};

export const updateApplication = (
  profileId: StudentProfileId,
  applicationId: StudentApplicationId,
  payload: Partial<StudentApplication>,
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<StudentApplication>(
    `/student-application/students/${profileId}/applications/${applicationId}/`,
    payload,
  );
};

export const updateApplicationPartnerPays = (
  profileId: StudentProfileId,
  applicationId: StudentApplicationId,
  payload: Partial<StudentApplication>,
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<StudentApplication>(
    `/student-application/students/${profileId}/applications/${applicationId}/resell/`,
    payload,
  );
};

export const getLatestApplication = (profileId: StudentProfileId) => {
  const internalFetch = getRestClient();
  return internalFetch.get<StudentApplication>(
    `/student-application/students/${profileId}/applications/latest/`,
  );
};

export const getApplications = (profileId: StudentProfileId) => {
  const internalFetch = getRestClient();
  return internalFetch.get<StudentApplication[]>(
    `/student-application/students/${profileId}/applications/`,
  );
};

export const getStudentOverviewApplications = (profileId: StudentProfileId) => {
  const internalFetch = getRestClient();
  return internalFetch.get<StudentOverviewStudentApplication[]>(
    `/student-application/students/${profileId}/overview/applications/`,
  );
};
