import { useGetPolyPilotProjectDetailsQuery } from 'src/reducers/polyGptReducers';

export const useIsActiveStagePending = (projectId: number) => {
  const { isActiveStagePending } = useGetPolyPilotProjectDetailsQuery(projectId, {
    selectFromResult: ({ data }) => {
      const activeStage = data?.projectStages?.find((stage) => stage.isActive);

      return {
        isActiveStagePending: (activeStage?.completedAt && !activeStage?.approvedAt) || false,
      };
    },
  });

  return isActiveStagePending;
};
