import { useEffect, useState } from 'react';

export const usePersistentState = <StateType>(defaultValue: StateType, key: string) => {
  const [value, setValue] = useState<StateType>(() => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== 'undefined' && stickyValue !== null
      ? (JSON.parse(stickyValue) as StateType)
      : defaultValue;
  });

  useEffect(() => {
    if (value == null) {
      window.localStorage.removeItem(key);
      return;
    }

    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue] as const;
};
