import type { CamelCasedPropertiesDeep } from 'type-fest';

import type { WORKSPACE_DISPLAY_CATEGORIES } from '../utils/hermes/workspaceDisplayCategories';

import type { Participant, User, UserType } from './backend';
import type {
  AdditionalSessionId,
  AssignmentBlueprintId,
  AssignmentId,
  AttachmentId,
  ExtensionRequestId,
  HermesAIMessageThreadId,
  LoungeId,
  MeetingId,
  MentorNoShowId,
  MessageId,
  ProjectId,
  ProjectPlanId,
  RoomId,
  ScheduledActionId,
  SessionId,
  SharedResourceId,
  SilentRoomUserReportId,
  SummaryId,
  TimeSlotId,
  UserId,
  UUID,
  WorkspaceId,
} from './common';
import type {
  AssignmentSerializer,
  FeedbackRequestPublicSerializer,
  FeedbackReviewerSerializer,
  HermesAIMessageThreadMessagesSerializer,
  HermesAIMessageThreadSerializer,
  MessageSerializer,
  ProjectPauseRequestSerializer,
  SessionSerializer,
  TimeSlotCreateSerializer,
  UserSnippetSerializer,
} from './data/hermes';
import type { PermanentFileSerializer } from './data/marketplace';
import type { Nullable } from './utils';
import type { Interest } from './zeus';

export interface Workspace {
  id: WorkspaceId;
  disabled: boolean;
  name: string;
  operationUser: Nullable<UserId>;
  permissions: string[];
  zoomDisabled: boolean;
  projectId?: ProjectId;
  roomId: RoomId;
  loungeId?: LoungeId;
  meetingIds: MeetingId[];
  roomName: string;
}

export enum ProjectStatus {
  ACTIVE = 'active',
  COMPLETE = 'complete',
  TERMINATED = 'terminated',
}

export const ProjectType = {
  FLAGSHIP: 'flagship',
  PATHFINDER_DEPRECATED: 'pathfinder_deprecated',
  PATHFINDER_DIAGNOSTIC: 'pathfinder_diagnostic',
  PATHFINDER_LAUNCHPAD: 'pathfinder_launchpad',
  PATHFINDER_STANDALONE: 'pathfinder_standalone',
  PREMIUM_SHOWCASING_PUBLISHING: 'showcasing_publishing',
  PREMIUM_SHOWCASING_PRESENTING: 'showcasing_presenting',
  PREMIUM_SHOWCASING_COMPETING: 'showcasing_competing',
  PREMIUM_SHOWCASING_MULTIMEDIA: 'showcasing_multimedia',
  PODS: 'pods',
  ADVANCED_SCHOLARS_PROGRAM: 'advanced_scholars_program',
} as const;

export type ProjectTypes = (typeof ProjectType)[keyof typeof ProjectType];

export const ProjectFeeType = {
  NOT_SET: 'not_set',
  NO_FEE_FULL_RATE: 'no_fee_full_rate',
  NO_FEE_HONORARIUM: 'no_fee_honorarium',
  NO_FEE_PRO_BONO: 'no_fee_pro_bono',
  FULL_FEE_FULL_RATE: 'full_fee_full_rate',
  REDUCED_FEE_FULL_RATE: 'reduced_fee_full_rate',
  FULL_FEE_WITH_DONATION: 'full_fee_with_donation',
  REDUCED_FEE_HONORARIUM: 'reduced_fee_honorarium',
} as const;

export type ProjectFeeTypes = (typeof ProjectFeeType)[keyof typeof ProjectFeeType];

export enum ExtensionRequestStatus {
  REQUESTED = 'requested',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
}

export interface ExtensionRequest {
  extendedDeadline: string;
  extendedDeadlineActions: string;
  extendedDeadlineBenefit: string;
  id: ExtensionRequestId;
  project: ProjectId;
  requestedByUserType: UserType;
  status: ExtensionRequestStatus;
}

export type ExtensionRequestCreatePayload = Pick<
  ExtensionRequest,
  'extendedDeadline' | 'extendedDeadlineActions' | 'extendedDeadlineBenefit' | 'project'
>;

export enum AdditionalSessionStatus {
  REQUESTED = 'requested',
  PENDING = 'payment_pending',
  DECLINED = 'declined',
  COMPLETED = 'completed',
}

export interface AdditionalSession {
  additionalSessionsNumber: number;
  id: AdditionalSessionId;
  originalSessionsNumber: number;
  project: ProjectId;
  proposedPlan: string;
  reasonForSessions: string;
  requestedBy: UserId;
  respondedBy: UserId;
  status: AdditionalSessionStatus;
  uuid: UUID;
}

export interface Project {
  additionalSessions: AdditionalSession[];
  writingFeedbackDocumentUrl: Nullable<string>;
  description?: string;
  details: Record<string, unknown>;
  detailsEnabled?: boolean;
  extras: Record<string, unknown>;
  questionnaire: Record<string, unknown>;
  enrollmentDeadline: Nullable<string>;
  expectedEndAt: Nullable<string>;
  expectedStartAt: string;
  extensionRequests: ExtensionRequest[];
  finalStudentSurveyCompleted: boolean;
  finalStudentSurveyEnabled: boolean;
  finalStudentSurveyUrl?: Nullable<Record<number, string>>;
  id: ProjectId;
  milestonesEnabled: boolean;
  assignmentEnabled: boolean;
  halfSessionsEnabled: boolean;
  pathfinderType: ProjectTypes;
  permissions: string[];
  premiumShowcasingAddonPurchaseEnabled: boolean;
  premiumShowcasingSupportPurchasedAt: Nullable<string>;
  additionalWritingFeedbackPurchaseEnabled: boolean;
  additionalWritingFeedbackPurchasedAt: Nullable<string>;
  price: string;
  projectFinal: Nullable<string>;
  projectFinalDraft: Nullable<string>;
  projectFinalWebsite: Nullable<string>;
  projectHalfDraft: Nullable<string>;
  projectOutline: Nullable<string>;
  recordingOptOut: boolean;
  recentMessages: Message[];
  reasonForProject: Nullable<string>;
  researchDescription: Nullable<string>;
  researchQuestion: Nullable<string>;
  reviewsRemaining: number;
  sessions?: Session[];
  sessionsCompleted: number;
  sessionsPaidFor: number;
  sessionsRemaining: string;
  sessionsSchedulable: number;
  showFeedbackDot: boolean;
  startedAt: Nullable<string>;
  status: ProjectStatus;
  studentsCount: number;
  surveysRequiredFor1StSessionForZoomJoin: boolean;
  surveysRequiredFor2NdSessionForZoomJoin: boolean;
  finalStudentSurveyRequiredForZoomJoin: boolean;
  title?: string;
  type?: ProjectTypes;
  workspaceId: WorkspaceId;
  uuid: UUID;
  showcasingMatchingSurveyCompleted: boolean;
  isShowcasingMatchingFormEnabled: boolean;
  mentorFirstName: string;
  pauseEnabled: boolean;
  pausedAt: string;
  pausedUntil: string;
  projectPauseRequests: ProjectPauseRequest[];
}

export const enum MessageType {
  HAS_ATTACHMENT = 'has_attachment',
  SYSTEM = 'system',
  SIMPLE = 'simple',
  PROPOSED_SESSION = 'proposed_session',
  GOOGLE_DRIVE_FILE = 'google_drive_file',
  MARKDOWN = 'markdown',
  EXTENSION_REQUEST = 'extension_request',
  ADDITIONAL_SESSION = 'additional_session',
  USER_ACTION = 'user_action',
  STUDENT_FIRST_SURVEY_REMINDER = 'student_first_survey_reminder',
  STUDENT_FINAL_SURVEY_REMINDER = 'student_final_survey_reminder',
  DECLINED_PROPOSED_SESSION = 'declined_proposed_session',
  MENTOR_RESCHEDULED_PROPOSED_SESSION = 'mentor_rescheduled_proposed_session',
  GIF = 'gif',
  SHOWCASING_MATCHING_SURVEY_REMINDER = 'showcasing_matching_survey_reminder',
  PROJECT_PAUSE_REQUEST = 'project_pause_request',
  HERMES_AI_THREAD = 'hermes_ai_thread',
}

export interface Message {
  attachmentIds?: AttachmentId[];
  content: string;
  createdAt: string;
  emailNotificationSentAt: Nullable<string>;
  executedAfterCreate: boolean;
  extra: Record<string, unknown>;
  id: MessageId;
  localMessageId?: Nullable<string>;
  messageType: MessageType;
  room: RoomId;
  seenBy: UserId[];
  sentBy: User | undefined;
  silentReport: Nullable<SilentRoomUserReportId>;
  status?: 'sending' | 'sent' | 'failed';
  updatedAt: string;
  aiMessageThread?: Nullable<HermesAIMessageThreadId>;
  aiMessageThreadTitle?: Nullable<string>;
}

export type MessageOfExtensionRequest = Message & {
  extra: {
    originalProjectDeadline: string;
    requestedProjectDeadline: string;
    extendedDeadlineBenefit: string;
    extendedDeadlineActions: string;
    extensionRequestId: ExtensionRequestId;
  };
};

export enum RoomTypes {
  LOUNGE = 'lounge',
  BASIC = 'basic',
}

export enum WorkspaceTypes {
  LOUNGE = 'lounge',
  BASIC = 'basic',
  PROJECT = 'project',
}

export interface Room {
  id: RoomId;
  unseenMessagesCount: number;
  participants: Participant[];
  messages: Message[];
  messagesCount: number;
  notificationEnabled: boolean;
  workspaceId: WorkspaceId;
  unsentMessages: Message[];
  roomType: RoomTypes | ProjectTypes;
}

export interface ZoomRecording {
  chatTranscript: string;
  videoHostedUrl: string;
  videoId: string;
}

export interface MentorNoShow {
  createdAt: string;
  id: MentorNoShowId;
  comment: string;
  scheduledAt: string;
}

export const enum MeetingStatus {
  SCHEDULED = 'scheduled',
  CANCELLED = 'cancelled',
  RESCHEDULED = 'rescheduled',
  PROPOSING = 'proposing',
  LATE_CANCELLED = 'late_cancelled',
}

export const enum SessionCountAs {
  FULL_SESSION = 'full_session',
  NO_SHOW = 'no_show',
  HALF_SESSION = 'half_session',
  NOT_SESSION = 'not_session',
  LATE_CANCELLED = 'late_cancelled',
}

export interface Meeting {
  createdAt: string;
  id: MeetingId;
  mentorNoShows: MentorNoShow[];
  oneDayReminderAction: Nullable<ScheduledActionId>;
  oneHourReminderAction: Nullable<ScheduledActionId>;
  recordingOn: boolean;
  scheduledAt: string;
  sharingOn: boolean;
  status: MeetingStatus;
  updatedAt: string;
  workspace: WorkspaceId;
  workspaceId: WorkspaceId;
  zoom: Record<string, unknown>;
  zoomAvailableAction: Nullable<ScheduledActionId>;
  zoomRecordings: ZoomRecording[];
  zoomVideoHostedFile: Nullable<string>;
}

export interface AssignmentBlueprint {
  assignmentNames: string[];
  displayBeforeSession: boolean;
  dueSessionNumber: number;
  id: AssignmentBlueprintId;
  instruction: string;
  name: string;
  order: number;
}

export interface ScheduleSession {
  number: number;
  sessionId: SessionId | undefined;
  meetingId: MeetingId | undefined;
  proposing: boolean;
  responding: boolean;
  isSelectedTimeValid: boolean;
}

export interface File {
  id: number;
  content: string;
}

export interface FileUploadFile {
  loading: boolean;
  name: string;
  id: number;
  content: string;
}

export interface AssignmentSolution {
  files?: File[];
  links?: string[];
  submittedAt?: Nullable<string>;
}

export interface NewAssignment {
  session: SessionId;
  title: string;
  description: string;
}

export interface Feedback {
  user: UserId;
  content: Record<string, unknown>;
}

export interface Session {
  id: SessionId;
  feedbacks: Feedback[];
  countAs: SessionCountAs;
  sessionValue: number;
  sessionNumber: number;
  extras: Record<string, unknown>;
  proposing: boolean;
  proposedBy: Nullable<UserId>;
  assignments: AssignmentId[];
  assignmentSolution: Nullable<AssignmentSolution>;
  number: number;
  meetingId: MeetingId;
  meeting?: Partial<Meeting>;
  scheduledAt: string;
  summary?: Summary;
  completedAt: string;
  cancellable: boolean;
}

export interface Summary {
  id: SummaryId;
  content: string;
  preparationLength: number;
  assignment: string;
  extras: Record<string, unknown>;
  session: SessionId;
}

export interface Lounge {
  workspaceId: WorkspaceId;
  id: LoungeId;
  image: string;
  name: string;
}

export interface NewWorkspace {
  email: string;
  loungeArgs: {
    image?: string;
    name?: string;
  };
  projectArgs: {
    projectType: ProjectTypes;
  };
  type: 'basic' | 'project' | 'lounge';
  users: Array<{ email: string }>;
  aspArgs: Partial<Interest>;
}

export interface SilentReport {
  lastMessageFromOther: MessageId;
  message: MessageId;
  reportedAnswer: string;
  reportedBy: UserId;
  workspace: WorkspaceId;
}

export interface SessionsForProject {
  sessions: SessionSerializer[];
  projectId: ProjectId;
}

export interface ScheduledSession {
  assignmentSolution: null;
  assignments: Assignment[];
  countAs: SessionCountAs;
  extras: Record<string, unknown>;
  feedbacks: Feedback[];
  id: SessionId;
  meeting: Meeting;
  proposedBy: Nullable<UserId>;
  proposing: boolean;
  sessionNumber: number;
  sessionValue: number;
  summary: null;
}

interface SharedResourceBase {
  id: SharedResourceId;
  displayName: string;
  description: string;
  url: string;
  messageId: MessageId;
  createdAt: string;
  createdBy: UserId;
  createdByFirstName: string;
  archived: boolean;
  archivedBy?: UserId;
  archivedAt?: boolean;
  favourite: boolean;
  favouriteBy?: UserId;
  favouriteAt?: string;
}
type SharedResourceWithoutMetaData = {
  type: 'file' | 'link';
} & SharedResourceBase;

type SharedResourceWithGoogleMetaData = {
  type: 'google';
  googleIconUrl: string;
  googleId: string;
} & SharedResourceBase;

export type SharedResource = SharedResourceWithoutMetaData | SharedResourceWithGoogleMetaData;

export type SharedResourceType = SharedResource['type'];

export interface TimeSlot extends TimeSlotCreateSerializer {
  id: TimeSlotId;
}

export interface SessionSummary {
  projectId: ProjectId;
  sessionId: SessionId;
  countAs: Session['countAs'];
  preparationLength: number;
  content: string;
  assignment: string;
}

export interface SessionSummaryPayload {
  session: SessionId;
  preparationLength: number;
  content: string;
  assignment: string;
  extras: {
    countAs: Session['countAs'];
    [k: string]: unknown;
  };
}

export interface AdditionalSessionData {
  [k: string]: FormDataEntryValue | number;
  project: ProjectId;
  requestedBy: UserId;
}

export interface StudentFeedbackResponse {
  user: UserId;
  content: Record<string, unknown>;
  fileStyleGuide: PermanentFileSerializer[];
  project: ProjectId;
}

export type Assignment = CamelCasedPropertiesDeep<AssignmentSerializer>;

export type FeedbackRequestPublic = CamelCasedPropertiesDeep<FeedbackRequestPublicSerializer>;

export type WorkspaceType = RoomTypes | ProjectTypes;

export interface WorkspaceDisplay {
  id: WorkspaceId;
  participants: Participant[];
  type: WorkspaceType;
  unseenMessageCount: number;
  lastMessageAt: Nullable<string>;
  status: Nullable<ProjectStatus>;
  lounge: Nullable<Lounge>;
}

export type WorkspaceDisplayCategory = keyof typeof WORKSPACE_DISPLAY_CATEGORIES;
export type ProjectCategory = Exclude<WorkspaceDisplayCategory, 'Other' | 'Completed'>;

export interface WorkspacesPerCategory {
  category: WorkspaceDisplayCategory;
  workspaces: WorkspaceDisplay[];
}

export const ProductType = {
  CORE: 'core',
  PODS: 'pods',
  PATHFINDERS: 'pathfinders',
  POLYPILOT: 'polypilot',
} as const;

export interface SessionPlan {
  session_number: number;
  during_session: string;
  before_next_session: string;
}

export interface ProjectPlanSchema {
  title: string;
  description: string;
  potential_outcome: string;
  potential_pitfalls: string;
  essential_skills_to_learn: string;
  roadmap: SessionPlan[];
}

export interface ProjectPlanSerializer {
  id: ProjectPlanId;
  student_name: string;
  content: string;
  content_json: ProjectPlanSchema;
  approved_at: Nullable<string>;
  latest_session_number: Nullable<number>;
  rating: Nullable<number>;
}

export type ProjectPlan = CamelCasedPropertiesDeep<ProjectPlanSerializer>;

export type ProductTypes = (typeof ProductType)[keyof typeof ProductType];

export type FeedbackReviewer = CamelCasedPropertiesDeep<FeedbackReviewerSerializer>;

export type MessageData = CamelCasedPropertiesDeep<MessageSerializer>;

export type ProjectPauseRequest = CamelCasedPropertiesDeep<ProjectPauseRequestSerializer>;

export type HermesAIMessageThread = CamelCasedPropertiesDeep<HermesAIMessageThreadSerializer>;
export type HermesAIMessageThreadMessages =
  CamelCasedPropertiesDeep<HermesAIMessageThreadMessagesSerializer>;

export type UserSnippet = CamelCasedPropertiesDeep<UserSnippetSerializer>;

export interface CancelSessionRequest {
  projectId: ProjectId;
  sessionId: SessionId;
  reason?: string;
  proposalDeclineReason?: string;
  declined?: boolean;
  isLate?: boolean;
}
