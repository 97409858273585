/* eslint-disable fp/no-mutation */
import { Button } from '@polygence/components';
import { useRef } from 'react';
import { useIntersection } from 'react-use';

import { usePolyGPTContext } from 'src/polygpt/PolyGPTContext';
import styles from 'src/polygpt/polygpt.module.scss';

type ButtonStatus = 'hidden' | 'generating' | 'answer_ready' | 'scroll_down';

const ButtonLabel: Omit<Record<ButtonStatus, string>, 'hidden'> = {
  generating: 'Generating answer...',
  answer_ready: 'Answer Ready!',
  scroll_down: 'See Latest Messages',
};

interface PolyGPTScrollButtonProps {
  isResponding: boolean;
}

export const PolyGPTScrollButton = ({ isResponding }: PolyGPTScrollButtonProps) => {
  const { messageContainerRef } = usePolyGPTContext();
  const scrollThresholdMarkerRef = useRef(null);
  const prevStatus = useRef<ButtonStatus>('hidden');
  const scrollMarkerIntersection = useIntersection(scrollThresholdMarkerRef, {
    root: messageContainerRef?.current,
    rootMargin:
      isResponding || ['generating', 'answer_ready'].includes(prevStatus.current)
        ? '150px'
        : '600px',
    threshold: 1,
  });

  const isMarkerInViewport = scrollMarkerIntersection
    ? scrollMarkerIntersection.intersectionRatio === 1
    : true;

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const getStatus = () => {
    let newValue = prevStatus.current;

    if (scrollMarkerIntersection) {
      switch (prevStatus.current) {
        case 'hidden':
          if (!isMarkerInViewport) {
            newValue = isResponding ? 'generating' : 'scroll_down';
          }

          break;

        case 'scroll_down':
          if (isMarkerInViewport) {
            newValue = 'hidden';
            break;
          }

          if (isResponding) {
            newValue = 'generating';
            break;
          }

          break;

        case 'generating':
          if (isMarkerInViewport) {
            newValue = 'hidden';
            break;
          }

          if (!isResponding) {
            newValue = 'answer_ready';
            break;
          }

          break;

        case 'answer_ready':
          if (isMarkerInViewport) {
            newValue = 'hidden';
          }

          break;
      }
    }

    prevStatus.current = newValue;
    return newValue;
  };

  const status = getStatus();

  const scrollToBottom = () => {
    messageContainerRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <div className={styles['scrollThresholdMarker']} ref={scrollThresholdMarkerRef} />
      {status !== 'hidden' && (
        <Button size="sm" className={styles['scrollButton']} onClick={scrollToBottom}>
          {ButtonLabel[status]}
        </Button>
      )}
    </>
  );
};
