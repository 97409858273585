/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';
import * as marketplaceApi from '@polygence/common/api/marketplace';
import { Button, Heading, Modal, Text, UploadedFile } from '@polygence/components';
import type { WebSocket } from 'partysocket';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { FileUploadInput } from 'src/form-builder/elements/FileUploadInput';
import {
  useCreateValidationDeliverableOutcomeMutation,
  useUpdateValidationDeliverableOutcomeMutation,
} from 'src/reducers/polyGptReducers';
import type { ProjectStage } from 'src/types/polygence/polygpt';

export const UploadProjectFileModal = NiceModal.create(
  ({
    projectStage,
    projectId,
    websocketConnection,
  }: {
    projectStage: ProjectStage;
    projectId: number;
    websocketConnection: WebSocket | null;
  }) => {
    const modal = useModal();
    const [createValidationDeliverableOutcome] = useCreateValidationDeliverableOutcomeMutation();
    const [updateValidationDeliverableOutcome] = useUpdateValidationDeliverableOutcomeMutation();
    const [hasChanged, setHasChanged] = useState(false);

    const [file, setFile] = useState<UploadedFile[]>(
      projectStage.stage.validationDeliverableOutcomeFile
        ? [
            {
              url: projectStage.stage.validationDeliverableOutcomeFile,
              filename: projectStage.stage.validationDeliverableOutcomeFile,
            },
          ]
        : [],
    );

    const hasFiles = file && file.length > 0;

    const handleClose = () => {
      if (
        hasFiles &&
        hasChanged &&
        // eslint-disable-next-line no-alert
        !window.confirm(
          'Your uploaded document is not submitted yet, and it will get lost. Are you sure?',
        )
      ) {
        return;
      }

      modal.hide().catch(console.error);
    };

    const handleOutcomeFileChange = ({
      // @ts-expect-error name not used
      target: { name, value },
    }: {
      target: { name: string; value: UploadedFile[] };
    }) => {
      setHasChanged(true);
      setFile(value);
    };

    const saveOutcomeFile = () => {
      if (!file) {
        return;
      }

      if (!projectStage.stage.validationDeliverableOutcomeFile) {
        createValidationDeliverableOutcome({
          projectId,
          projectStageId: projectStage.id,
          validationDeliverableId: projectStage.stage.validationDeliverable,
          outcomeFile: file,
        })
          .unwrap()
          .then(() => {
            websocketConnection?.send(
              JSON.stringify({
                type: 'polygpt.file_upload_success',
                project_id: projectId,
              }),
            );
          })
          .then(() => {
            toast.success('File upload succeeded.');
          })
          .catch(() => {
            toast.error('File upload failed.');
          })
          .finally(() => {
            setFile([]);
            modal.hide().catch(console.error);
          });
      } else {
        const newFile = file.pop();
        updateValidationDeliverableOutcome([
          projectId,
          projectStage.id,
          { outcomeFile: newFile ? [newFile] : [] },
        ])
          .unwrap()
          .then(() => {
            toast.success('File upload succeeded.');
          })
          .catch(() => {
            toast.error('File upload failed.');
          })
          .finally(() => {
            setFile([]);
            modal.hide().catch(console.error);
          });
      }
    };

    return (
      <Modal {...bootstrapDialog(modal)} body closeButton onHide={handleClose}>
        <div className="d-flex flex-column gap-4">
          <Heading size="h5" as="h1">
            Upload {projectStage.stage.displayFileName}
          </Heading>
          <section className="px-3">
            <Text size="medium" fontWeight="light" alignment="center" textWrap="balance">
              After submitting, your mentor will review it before you can move to the next stage.
            </Text>
          </section>
          <section>
            <FileUploadInput
              multiple={false}
              accept={['.pdf', '.docx']}
              name="outcomeFile"
              // @ts-expect-error https://polygence.atlassian.net/browse/BSH-7657
              onUpload={marketplaceApi.uploadFile}
              onDelete={() => {
                handleOutcomeFileChange({
                  target: { name: 'outcomeFile', value: [] },
                });
              }}
              onChange={handleOutcomeFileChange}
              data={{
                // @ts-expect-error https://polygence.atlassian.net/browse/BSH-7657
                outcomeFile: file,
              }}
            />
          </section>
          <section className="d-flex justify-content-end gap-3">
            <Button variant="link" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={saveOutcomeFile} disabled={!hasFiles || !hasChanged}>
              Submit
            </Button>
          </section>
        </div>
      </Modal>
    );
  },
);
