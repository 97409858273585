import { OwnMentorProfile } from '@polygence/common';
import * as marketplaceApi from '@polygence/common/api/marketplace';
import { Heading, Text, ToggleSwitch } from '@polygence/components';
import { useState } from 'react';

import styles from 'src/components/Availability.module.scss';
import { DateFormat } from 'src/components/aux/dateWrapper';
import { DatePickerWithLabel } from 'src/components/common/DatePickerWithLabel';

const CONCURRENT_STUDENT_OPTIONS = [1, 2, 3, 4, 5];

export const Availability = ({ mentor: initalMentor }: { mentor: Partial<OwnMentorProfile> }) => {
  const [mentor, setMentor] = useState<Partial<OwnMentorProfile>>(initalMentor);

  const handleOnChange = (name: string, value: boolean | number | string | null) => {
    const updatedMentor = { ...mentor, [name]: value };
    marketplaceApi
      .updateMentorProfile({ [name]: value })
      .then(() => {
        setMentor(updatedMentor);
      })
      .catch((error) => {
        console.error('Something went wrong during the mentor updating', error);
      });
  };

  return (
    <>
      <div>
        <Heading size="h5" alignment="left" className="tw-mb-1">
          Availability
        </Heading>
        <Text size={{ base: 'small', md: 'medium' }}>
          Are you currently available to mentor <br />
          <b>Core Program</b> students and if so, how many?
        </Text>
      </div>
      <div className={styles['grid']}>
        <Text size="small" className="tw-p-0">
          Available to mentor students
        </Text>
        <ToggleSwitch
          aria-label="availability"
          checked={mentor?.available}
          onCheckedChange={(isChecked) => handleOnChange('available', isChecked)}
        />
        {mentor?.available && (
          <>
            <Text size="small" className="tw-p-0">
              Number of Core students
            </Text>
            <select
              name="capacity"
              title="capacity"
              onChange={(e) => handleOnChange('concurrentStudents', Number(e.target.value))}
              className="form-control tw-p-1 tw-text-center"
              value={mentor?.concurrentStudents ?? 1}
            >
              {CONCURRENT_STUDENT_OPTIONS.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </>
        )}
        {!mentor?.available && (
          <>
            <Text size="small" className="tw-p-0">
              When will you have more bandwidth?
            </Text>
            <DatePickerWithLabel
              id="unavailableUntil"
              name="unavailableUntil"
              className="tw-max-w-42 tw-m-0 tw-w-44 tw-p-1"
              inputClassName="form-control tw-p-1 tw-text-center"
              value={mentor?.unavailableUntil}
              onChange={(e) => handleOnChange('unavailableUntil', e.target.value)}
              dateFormat={DateFormat.FORMAT_14}
            />
          </>
        )}
      </div>
    </>
  );
};
