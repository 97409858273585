import { externalLink } from '@polygence/common/utils/externalLink';
import { Icon, Text } from '@polygence/components';
import classNames from 'classnames';
import { useState, useEffect, useRef } from 'react';
import type { ChangeEventHandler } from 'react';
import { toast } from 'react-toastify';

import useExternalScript from 'src/hooks/useExternalScript';
import styles from 'src/payment/CheckoutPageStax/CheckoutPageStax.module.scss';
import { staxConstructorOptions } from 'src/payment/CheckoutPageStax/staxConstructorOptions';
import 'src/payment/CheckoutPageStax/staxTypes';
import { StaxConstructor, StaxError, StaxInstance } from 'src/payment/CheckoutPageStax/staxTypes';

declare const window: {
  StaxJs: StaxConstructor;
} & Window;

const initialState = {
  firstname: '',
  lastname: '',
  month: '',
  year: '',
};

export const CheckoutPageStax = () => {
  const isScriptLoaded = useExternalScript('https://staxjs.staxpayments.com/stax.js', 'stax-js');
  const [payButtonDisabled, setPayButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const stax = useRef<StaxInstance>();
  const [data, setData] = useState(initialState);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setErrorMessage('');
    setSuccessMessage('');
    setData((prevData) => ({
      ...prevData,
      [event.target.name]: event.target.value,
    }));
  };

  const handlePayment = () => {
    if (stax.current) {
      setIsLoading(true);
      stax.current
        .tokenize({ ...data, customer_id: 'b71a0366-c289-4715-855d-f5d82f81d71b' })
        .then((result) => {
          setSuccessMessage(result.id);
          setData(initialState);
        })
        .catch((error: StaxError) => {
          if (typeof error === 'object') {
            setErrorMessage(
              error.message ||
                Object.keys(error)
                  .map((k: string) => k)
                  .join(', '),
            );
          } else {
            setErrorMessage(JSON.stringify(error));
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    if (isScriptLoaded) {
      stax.current = new window.StaxJs('Polygence-3779c6fee45b', staxConstructorOptions);
      stax.current.showCardForm().catch(() => {
        toast.error('An error occurred while loading the payment form.');
      });

      stax.current.on('card_form_complete', () => {
        setPayButtonDisabled(false);
      });

      stax.current.on('card_form_uncomplete', () => {
        setPayButtonDisabled(true);
      });
    }
  }, [isScriptLoaded]);

  return (
    <div className="p-5 pt-sm-12">
      <div
        className={classNames(
          'd-flex flex-column gap-7 mx-auto p-7 bg-grayscale-3 border rounded',
          styles['wrapper'],
        )}
      >
        <h3>Checkout</h3>
        <div className="d-flex flex-column gap-3">
          <Text size="medium" fontWeight="bold" className="mb-2">
            Cardholder information
          </Text>
          <div className="d-flex flex-column flex-sm-row gap-4 mb-2">
            <div className={styles['formControl']}>
              <label htmlFor="firstname">First name</label>
              <input
                type="text"
                id="firstname"
                name="firstname"
                value={data.firstname}
                onChange={handleChange}
              />
            </div>
            <div className={styles['formControl']}>
              <label htmlFor="lastname">Last name</label>
              <input
                type="text"
                id="lastname"
                name="lastname"
                value={data.lastname}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column gap-3">
          <Text size="medium" fontWeight="bold" className="mb-2">
            Card information
          </Text>
          <div className="d-flex flex-column flex-sm-row gap-4">
            <div className={styles['formControl']}>
              <label htmlFor="card-number">Card number</label>
              <div id="card-number" />
            </div>
            <div className={styles['formControl']}>
              <label htmlFor="card-number">CVV</label>
              <div id="cvv" />
            </div>
          </div>
          <div className={classNames('d-flex w-100 gap-4', styles['expirationDate'])}>
            <div className={styles['formControl']}>
              <label htmlFor="month">Expiration / Month</label>
              <input
                type="text"
                id="month"
                name="month"
                placeholder="12"
                minLength={2}
                maxLength={2}
                inputMode="numeric"
                value={data.month}
                onChange={handleChange}
              />
            </div>
            <div className={styles['formControl']}>
              <label htmlFor="year">Year</label>
              <input
                type="text"
                id="year"
                name="year"
                placeholder="2024"
                minLength={4}
                maxLength={4}
                inputMode="numeric"
                value={data.year}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column gap-5">
          <button
            type="button"
            disabled={
              payButtonDisabled || isLoading || Object.entries(data).some(([, value]) => !value)
            }
            className={styles['payButton']}
            onClick={handlePayment}
          >
            {isLoading && (
              <img
                src="https://dpl6hyzg28thp.cloudfront.net/icons/loader-white.svg"
                alt="stax logo"
                width={24}
              />
            )}
            {isLoading ? 'Paying...' : 'Pay'}
          </button>
          <a
            href="https://staxpayments.com/terms-conditions/"
            className={styles['proof']}
            {...externalLink}
          >
            <Icon id="lock" size="xs" />
            <p>Powered by</p>
            <img
              src="https://dpl6hyzg28thp.cloudfront.net/icons/stax-logo-gray.svg"
              alt="stax logo"
              width={36}
            />
          </a>
        </div>
      </div>
      {successMessage && (
        <div className="d-flex justify-content-center mt-5 text-primary">{successMessage}</div>
      )}
      {errorMessage && (
        <div className="d-flex justify-content-center mt-5 text-danger">{errorMessage}</div>
      )}
    </div>
  );
};
