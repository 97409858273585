import type { StudentRelatedPod } from '@polygence/common/types/data/pods';
import { Badge, Button, Card, Heading, Icon, Text } from '@polygence/components';
import { Link } from 'react-router-dom';

import DateWrapper, { DateFormat } from 'src/components/aux/dateWrapper';

export const PendingPodInfoCard = ({ pod, paymentIntent }: StudentRelatedPod) => {
  const {
    startDate,
    remainingSeats,
    isPodFull,
    course: { title },
  } = pod;
  const formattedStartDate = new DateWrapper(startDate).format(DateFormat.FORMAT_12);

  const getLink = () => {
    if (paymentIntent) {
      return `/payment/${paymentIntent.uuid}`;
    }
    return '#';
  };

  const button = !isPodFull ? (
    <Link to={getLink()}>
      <Button variant="tertiary" endIcon={<Icon id="chevron-right" />} disabled={!paymentIntent}>
        Pay now
      </Button>
    </Link>
  ) : (
    <Button variant="tertiary" disabled>
      This pod is full.
    </Button>
  );

  return (
    <Card className="my-5 p-6">
      <div className="d-flex gap-5 justify-content-between">
        <div className="d-flex flex-column gap-5">
          <Heading size="h5" alignment="left">
            {title}
          </Heading>
          <div className="d-flex flex-row align-items-center gap-5">
            <Text size="small" className="text-muted">
              Starts {formattedStartDate}
            </Text>
            <Badge variant="secondary" size="small" pill>
              {`${remainingSeats} seats left!`}
            </Badge>
          </div>
        </div>
        <div>{button}</div>
      </div>
    </Card>
  );
};
