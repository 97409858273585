/* eslint-disable react/jsx-max-depth */
import NiceModal from '@ebay/nice-modal-react';
import { ProductGroup } from '@polygence/common';
import * as paymentApi from '@polygence/common/api/payment';
import {
  Cart,
  PaymentIntentInvoice,
  PaymentIntentStatuses,
  PaymentIntentTypes,
} from '@polygence/common/types/payment';
import type { Product } from '@polygence/common/types/studentApplication';
import { Alert, Button, Heading, Icon, Spacer, Text } from '@polygence/components';
import { captureException } from '@sentry/react';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import { Icon as SimpleIcon } from 'src/components/Icon';
import { Loader } from 'src/components/Loader';
import { PageLoad } from 'src/components/PageLoad';
import { DashedBox } from 'src/components/common/DashedBox';
import { useModal } from 'src/components/useModal';
import { AmountOrPlaceholder } from 'src/payment/PaymentIntentV2/AmountOrPlaceholder';
import { PaymentBox } from 'src/payment/PaymentIntentV2/PaymentBox';
import { PaymentButtonMobile } from 'src/payment/PaymentIntentV2/PaymentButtonMobile';
import { PaymentIntentCanceled } from 'src/payment/PaymentIntentV2/PaymentIntentCanceled';
import styles from 'src/payment/PaymentIntentV2/PaymentIntentPageV2.module.scss';
import {
  PaymentIntentProduct,
  GROUP_HEADERS,
} from 'src/payment/PaymentIntentV2/PaymentIntentProduct';
import { PaymentTypeSelector } from 'src/payment/PaymentIntentV2/PaymentTypeSelector';
import { AddableProduct } from 'src/payment/PaymentIntentV3/AddableProduct';
import { DiscountList } from 'src/payment/PaymentIntentV3/DiscountList';
import { ExpiringDiscounts } from 'src/payment/PaymentIntentV3/ExpiringDiscounts';
import { PriceChangeErrorModal } from 'src/payment/PaymentIntentV3/PriceChangeErrorModal';
import { PaymentPageTerms } from 'src/payment/PaymentPageTerms';
import { trackProceed, trackSelectedProductChange } from 'src/payment/tracking';
import {
  useAddProductToCartMutation,
  useGetPaymentIntentQuery,
  useLazyGetCartQuery,
  useRemoveProductFromCartMutation,
  useUpdatePaymentIntentMutation,
} from 'src/reducers/paymentReducer';
import { navigateToWithTimeout } from 'src/utils/navigateTo';

const GENERIC_ERROR_MESSAGE = 'Something went wrong.';

const mapIdToProductName = (id: number, products: Product[]) => {
  const product = products.find((product) => product.id === id);
  return product ? product.name : '';
};

const trackProducts = (
  selected: boolean,
  previouslySelectedProducts: number[],
  id: number,
  paymentIntent: PaymentIntentInvoice,
) => {
  const selectedProducts = selected
    ? [...previouslySelectedProducts, id]
    : previouslySelectedProducts.filter((product) => product !== id);

  trackSelectedProductChange({
    uuid: paymentIntent.uuid,
    selectedProducts: selectedProducts.map((id) =>
      mapIdToProductName(id, paymentIntent.pitchedProducts),
    ),
    previousSelectedProducts: previouslySelectedProducts.map((id) =>
      mapIdToProductName(id, paymentIntent.pitchedProducts),
    ),
  });
};

interface GroupedProducts {
  group: ProductGroup;
  products: Product[];
}

const BundleableProducts = ({
  bundleableProducts,
  onAddBundleProduct,
  disabled,
}: {
  bundleableProducts: Cart['bundleableProducts'];
  onAddBundleProduct: ({ id }: { id: number }) => void;
  disabled: boolean;
}) => {
  if (bundleableProducts.length <= 0) {
    return null;
  }
  const groups = bundleableProducts.reduce<Array<Product | GroupedProducts>>(
    (previousValue, current) => {
      if (!current.group) {
        return [...previousValue, current];
      } else {
        const index = previousValue.findIndex((item) => item.group === current.group);
        if (index > -1) {
          const foundGroup = previousValue[index];
          if (foundGroup && 'products' in foundGroup) {
            const products = [...foundGroup.products, current];
            return [
              ...previousValue.slice(0, index),
              {
                group: current.group,
                products,
              },
              ...previousValue.slice(index + 1, previousValue.length),
            ];
          }
          return previousValue;
        } else {
          return [...previousValue, { group: current.group, products: [current] }];
        }
      }
    },
    [],
  );
  return (
    <DashedBox className="p-7">
      <Heading size="h5" as="h2" alignment="left" className="mb-6 text-reset">
        Complete your Polygence Journey{' '}
        <SimpleIcon name="rocket" style={{ verticalAlign: 'bottom' }} />
      </Heading>
      <div className="d-flex flex-column gap-5">
        {groups.map((product) => {
          if ('products' in product) {
            return (
              <div key={product.group}>
                <h6 className="tw-mb-3">{GROUP_HEADERS[product.group]}</h6>
                <div className="tw-flex tw-flex-col tw-gap-1 tw-border-0 tw-border-l-2 tw-border-solid tw-border-primary-800 tw-pl-2">
                  {product.products.map((p) => (
                    <AddableProduct
                      product={p}
                      key={p.id}
                      onClick={onAddBundleProduct}
                      disabled={disabled}
                      fontWeight="normal"
                    />
                  ))}
                </div>
              </div>
            );
          } else {
            return (
              <AddableProduct
                product={product}
                key={product.id}
                onClick={onAddBundleProduct}
                disabled={disabled}
                fontWeight="bold"
              />
            );
          }
        })}
      </div>
    </DashedBox>
  );
};

const PaymentIntentAlreadyPaid = ({
  paymentIntent,
  subscription,
}: {
  paymentIntent: PaymentIntentInvoice;
  subscription?: boolean;
}) => {
  return (
    <div className={classNames(styles['pageContainer'], 'pt-11')}>
      <Heading size="h3" alignment="center" fontWeight="bold">
        {subscription
          ? 'This invoice is an ongoing subscription.'
          : 'This invoice is already paid.'}
      </Heading>
      <Text size="medium" alignment="center" className="tw-my-4">
        {paymentIntent.description}
      </Text>
      <PaymentPageTerms />
    </div>
  );
};

export const PaymentIntentPageV3 = () => {
  const { uuid } = useParams<{ uuid?: string }>();
  const {
    data: paymentIntent,
    isLoading: isQueryInProgress,
    isFetching: isFetchingInProgress,
    isError,
  } = useGetPaymentIntentQuery(uuid ?? '');
  const [updatePaymentIntent, { isLoading: isMutationInProgress }] =
    useUpdatePaymentIntentMutation();
  const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);
  const [isProceedDisabledDebounced, setIsProceedDisabledDebounced] = useState(false);
  const [fetchCart, { data: cart }] = useLazyGetCartQuery();
  const [addProductToCart, addProductToCartResult] = useAddProductToCartMutation();
  const [removeProductFromCart, removeProductFromCartResult] = useRemoveProductFromCartMutation();

  const isProceedDisabled =
    isQueryInProgress ||
    isFetchingInProgress ||
    isMutationInProgress ||
    isLoadingInvoice ||
    isNavigating ||
    addProductToCartResult.isLoading ||
    removeProductFromCartResult.isLoading;

  const cartUuid = paymentIntent?.cartUuid;
  useEffect(() => {
    if (cartUuid) {
      fetchCart(cartUuid).catch(console.error);
    }
  }, [fetchCart, cartUuid]);

  const [RedirectModal, openRedirectModal, closeRedirectModal] = useModal();

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        setIsProceedDisabledDebounced(isProceedDisabled);
      },
      isProceedDisabled ? 200 : 50,
    );

    return () => clearTimeout(timeout);
  }, [isProceedDisabled]);

  useEffect(() => {
    if (paymentIntent?.redirectUrl) {
      openRedirectModal();
      navigateToUrl(paymentIntent.redirectUrl);
    } else if (paymentIntent?.firstPaymentReceivedAt && paymentIntent.invoiceUrl) {
      openRedirectModal();
      navigateToUrl(paymentIntent.invoiceUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentIntent]);

  const handleProductSelected = ({ id, selected }: { id: number; selected: boolean }) => {
    const previouslySelectedProducts = paymentIntent?.selectedProducts ?? [];
    if (
      !paymentIntent ||
      (!selected && previouslySelectedProducts.length === 0) ||
      !cart ||
      isProceedDisabled
    ) {
      return;
    }

    trackProducts(selected, previouslySelectedProducts, id, paymentIntent);

    if (selected) {
      addProductToCart({ uuid: cart.uuid, productId: id })
        .unwrap()
        .catch(() => {
          toast.error(GENERIC_ERROR_MESSAGE);
        });
    } else {
      removeProductFromCart({ uuid: cart.uuid, productId: id })
        .unwrap()
        .catch(() => {
          toast.error(GENERIC_ERROR_MESSAGE);
        });
    }
  };

  const handleAddBundleProduct = ({ id }: { id: number }) => {
    if (!cart || isProceedDisabled) {
      return;
    }
    addProductToCart({ uuid: cart.uuid, productId: id })
      .unwrap()
      .catch(() => {
        toast.error(GENERIC_ERROR_MESSAGE);
      });
  };

  const handleTypeChange = (type: PaymentIntentTypes) => {
    if (uuid) {
      void updatePaymentIntent([uuid, { type }]);
    }
  };

  const navigateToUrl = (url: string) => {
    setIsNavigating(true);
    navigateToWithTimeout(url, 1000);
  };

  const handleProceed = () => {
    if (!paymentIntent || isProceedDisabled || !uuid) {
      return;
    }

    trackProceed({
      uuid: paymentIntent.uuid,
      selectedProducts: paymentIntent.selectedProducts.map((id) =>
        mapIdToProductName(id, paymentIntent.pitchedProducts),
      ),
      pitchedProducts: paymentIntent.pitchedProducts.map((product) => product.name),
      paymentMethod: paymentIntent.type,
    });

    openRedirectModal();
    setIsLoadingInvoice(true);
    void paymentApi
      .proceedToPaymentIntentInvoice(uuid, { paymentIntentAmount: paymentIntent.amount })
      .then(({ data }) => {
        setIsLoadingInvoice(false);
        navigateToUrl(data.invoiceUrl);
      })
      .catch((e: AxiosError) => {
        closeRedirectModal();
        if ((e?.response?.data as { detail?: string })?.detail === 'Cart price has changed') {
          NiceModal.show(PriceChangeErrorModal).catch(console.error);
        } else {
          captureException(e);
          toast.error('Something went wrong.');
        }
      })
      .finally(() => {
        setIsLoadingInvoice(false);
      });
  };

  if (isError || !paymentIntent?.cartUuid) {
    return (
      <div className="container pt-11">
        <Alert variant="danger">Something went wrong.</Alert>
      </div>
    );
  }

  if (!paymentIntent || !cart) {
    return <PageLoad />;
  }

  if (
    paymentIntent.status === PaymentIntentStatuses.PENDING &&
    paymentIntent.firstPaymentReceivedAt &&
    paymentIntent.type === PaymentIntentTypes.SUBSCRIPTION
  ) {
    return <PaymentIntentAlreadyPaid paymentIntent={paymentIntent} subscription />;
  }

  if (paymentIntent.firstPaymentReceivedAt) {
    return <PaymentIntentAlreadyPaid paymentIntent={paymentIntent} />;
  }

  if (paymentIntent.status === PaymentIntentStatuses.CANCELED) {
    return <PaymentIntentCanceled />;
  }

  const bundleableProducts = cart.bundleableProducts;
  const cartItems = cart.cartItems;
  const discounts = cart.discounts;
  const expiringDiscounts = discounts.filter((discount) => discount.expiresAt);

  return (
    <>
      <div className={classNames(styles['pageContainer'], 'pt-11')}>
        <Heading className="mb-4" as="h1" size="h3" alignment="center" fontWeight="bold">
          Please verify your payment amount below
        </Heading>
        <div className="mx-auto w-75">
          <Text size="large" alignment="center" fontWeight="light" className="px-6 px-xl-11">
            {paymentIntent.description}
          </Text>
        </div>
        <Spacer size={10} />
        <div className="container">
          <div
            className={classNames(styles['container'], {
              [styles['hasNoProducts'] as string]: cart.addedProducts.length === 0,
            })}
          >
            <div className={styles['details']}>
              {cart.addedProducts.length > 0 && (
                <PaymentBox className={styles['products']}>
                  <Heading size="h5" as="h2" alignment="left" className="mb-6">
                    Your package includes
                  </Heading>
                  <div className="d-flex flex-column gap-5">
                    {cart.addedProducts.map((product) => {
                      const cartItem = cartItems.find((item) => item.product.id === product.id);
                      return (
                        <PaymentIntentProduct
                          product={product}
                          key={product.id}
                          isSelected={!!cartItem}
                          isRemovable={cartItem?.isRemovable ?? true}
                          onChange={handleProductSelected}
                          cartItem={cartItem}
                        />
                      );
                    })}
                  </div>
                </PaymentBox>
              )}
              <BundleableProducts
                bundleableProducts={bundleableProducts}
                onAddBundleProduct={handleAddBundleProduct}
                disabled={addProductToCartResult.isLoading}
              />
              <DiscountList discounts={discounts} />

              <PaymentBox className={styles['total']}>
                {paymentIntent.type === PaymentIntentTypes.SUBSCRIPTION &&
                  paymentIntent.riskPremium && (
                    <div className="d-flex align-items-center mb-6">
                      <Text size="medium" fontWeight="bold" alignment="left" className="d-inline">
                        Monthly installment interest ({paymentIntent.riskPremium}%)
                      </Text>
                      <div className="ms-auto">
                        <AmountOrPlaceholder
                          amount={paymentIntent.riskPremiumAmount}
                          size="medium"
                        />
                      </div>
                    </div>
                  )}
                <div className="d-flex align-items-center">
                  <Heading size="h5" as="h2" alignment="left" className="d-inline">
                    Total
                  </Heading>
                  <div className="ms-auto">
                    <AmountOrPlaceholder amount={paymentIntent.amount} size="medium" />
                  </div>
                </div>
              </PaymentBox>
            </div>
            <div className={styles['type']}>
              <ExpiringDiscounts expiringDiscounts={expiringDiscounts} />
              <PaymentTypeSelector paymentIntent={paymentIntent} onChange={handleTypeChange}>
                <div className="d-none d-md-flex justify-content-center">
                  <Button
                    variant="primary"
                    disabled={isProceedDisabledDebounced}
                    onClick={handleProceed}
                    endIcon={<Icon id="chevron-right" />}
                  >
                    Proceed with payment
                  </Button>
                </div>
              </PaymentTypeSelector>
            </div>
          </div>
        </div>
        <PaymentPageTerms />
        <div className="d-block d-md-none">
          <Spacer size={12} />
          <Spacer size={4} />
          <PaymentButtonMobile
            handleProceed={handleProceed}
            isProceedDisabled={isProceedDisabledDebounced}
          />
        </div>
      </div>
      <RedirectModal backdrop="static" centered>
        <div className="d-flex flex-column gap-3 p-8 justify-content-center align-items-center">
          <Loader />
          {isLoadingInvoice && <Text size="medium">Generating invoice...</Text>}
          {isNavigating && <Text size="medium">Redirecting...</Text>}
        </div>
      </RedirectModal>
    </>
  );
};
