import { getBaseSocketUrl, Nullable } from '@polygence/common';
import useCurrentUser from '@polygence/common/hooks/selectors/useCurrentUser';
import { WebSocket } from 'partysocket';
import { useEffect, useRef } from 'react';

export const usePolyGptSocketConnection = ({
  closeOnUnmount = true,
}: {
  closeOnUnmount?: boolean;
} = {}) => {
  const currentUser = useCurrentUser();
  const rws = useRef<Nullable<WebSocket>>(null);

  useEffect(() => {
    if (rws.current) {
      return;
    }

    rws.current = new WebSocket(
      `${getBaseSocketUrl()}/polygpt/stream/?token=${currentUser.token ?? ''}`,
      [],
      {
        maxRetries: 3,
        minReconnectionDelay: 1000,
        maxReconnectionDelay: 2000,
        connectionTimeout: 2000,
      },
    );

    return () => {
      if (closeOnUnmount && rws.current) {
        rws.current.close();
        rws.current = null;
      }
    };
  }, [currentUser.token, closeOnUnmount]);

  return rws.current;
};
