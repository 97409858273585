import NiceModal from '@ebay/nice-modal-react';
import { commonHooks } from '@polygence/common';
import { Alert, Button, Icon, Heading, Text } from '@polygence/components';

import logo from 'src/polygpt/PolyPilotLogo.png';
import { PurchasePolyPilotModal } from 'src/polygpt/PurchasePolyPilotModal';
import { useIsProjectLocked } from 'src/polygpt/useIsProjectLocked';
import { useGetPolyPilotProjectDetailsQuery } from 'src/reducers/polyGptReducers';

const UnlockProject = ({ projectId }: { projectId: number }) => {
  const { data: projectDetails, isLoading } = useGetPolyPilotProjectDetailsQuery(projectId);
  const currentUser = commonHooks.useCurrentUser();

  const isProjectUnlocked = Boolean(projectDetails?.unlockedAt);
  const isOwnProject = currentUser.id === projectDetails?.user?.id;
  const isProjectDisabled = useIsProjectLocked(projectId);
  const activeStage = projectDetails?.projectStages?.find((stage) => stage.isActive);
  const doesActiveStageNeedReview = activeStage && activeStage.stage.needsAdminApprove;

  if (isLoading || !projectDetails) {
    return null;
  }

  const openPurchaseModal = () => {
    NiceModal.show(PurchasePolyPilotModal, {
      projectId: projectId,
      paymentIntentId: projectDetails.paymentIntentId,
    }).catch(console.error);
  };

  return (
    !isProjectUnlocked &&
    isOwnProject &&
    doesActiveStageNeedReview && (
      <>
        <Button variant="primary" startIcon={<Icon id="unlock" />} onClick={openPurchaseModal}>
          Unlock Mentor Review
        </Button>
        <Alert
          variant={isProjectDisabled ? 'danger' : 'warning'}
          className="px-5 py-3 rounded my-0 mx-auto"
        >
          <div className="d-flex gap-3 align-items-center">
            <Text size="small">Purchase mentor review to continue your project</Text>
          </div>
        </Alert>
      </>
    )
  );
};

export const PolyPilotSidebarHeader = ({ projectId }: { projectId: number }) => {
  return (
    <div className="d-flex gap-5 align-items-center justify-content-between flex-wrap">
      <div className="d-flex gap-2 align-items-center">
        <img src={logo} width={100} height={100} alt="PolyPilot bird mascot" />
        <Heading as="h2" size="h3">
          PolyPilot
        </Heading>
      </div>
      <UnlockProject projectId={projectId} />
    </div>
  );
};
