import type {
  CounselorProfileId,
  EmptyResponse,
  Nullable,
  Paginated,
  ProjectOutcomeType,
  StudentJourney,
  StudentProfileId,
  Tag,
  UUID,
  UserCard,
  UserId,
  WorkspaceId,
} from '@polygence/common';
import { axiosBaseQuery } from '@polygence/common/api/fetch';
import type { UpdateMentorProfile } from '@polygence/common/types/data/marketplace';
import type {
  AdmissionAdvisorModal,
  CounselorOwnProfile,
  ExternalStudentProfile,
  PSSMatchingDeclineReason,
  PublicStudentProfileForCollegeAdmissionSurvey,
  StudentCollegeChoice,
  HubSpotEmailNotification,
} from '@polygence/common/types/marketplace';
import type { OptionType } from '@polygence/components';
import { createApi } from '@reduxjs/toolkit/query/react';
import type { CamelCasedPropertiesDeep } from 'type-fest';

export interface TagOptionType extends OptionType {
  parentId: Nullable<number>;
}

export interface StudentSnapshot {
  studentInfo: Nullable<StudentSnapshotPage>;
  projectInfo: Nullable<StudentSnapshotPage>;
  timeline: Nullable<StudentSnapshotPage>;
}

export interface StudentSnapshotPage {
  tabs: StudentSnapshotPageTab[];
  workspaceId?: number;
  title?: string;
}

export interface StudentSnapshotPageTab {
  title: string;
  icon: string;
  description?: string;
  content: {
    component: string;
    props: Record<string, unknown>;
  } | null;
}

export const marketplaceApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'marketplace',
  tagTypes: [
    'CollegeAdmissionSurvey',
    'CollegeChoices',
    'CounselorOwnProfile',
    'NotificationSettings',
  ],
  endpoints: (build) => {
    return {
      tags: build.query<
        TagOptionType[],
        { category?: string; type?: 'child' | 'parent'; showPrivate?: boolean }
      >({
        query: ({ category = '', type = '', showPrivate = false }) => ({
          url: '/api/tags/',
          method: 'GET',
          params: new URLSearchParams({
            category,
            type,
            ...(showPrivate ? { showPrivate: 'true' } : {}),
          }),
        }),
        transformResponse: (response: Tag[]) => {
          return response.map(({ id, parent, name }) => ({
            label: parent.name ? `${parent.name} - ${name}` : name,
            value: id,
            parentId: parent?.id,
          }));
        },
      }),
      projectOutcomeTypes: build.query<OptionType[], void>({
        query: () => ({
          url: '/api/project-outcome-types/',
          method: 'GET',
        }),
        transformResponse: (response: ProjectOutcomeType[]) => {
          return response.map(({ id, name }) => ({
            label: name,
            value: id,
          }));
        },
      }),
      userCards: build.query<UserCard, { userId: UserId; workspaceId?: WorkspaceId }>({
        query: ({ userId, workspaceId }) => ({
          url: `/api/users/${userId}/card/`,
          method: 'GET',
          params: workspaceId ? new URLSearchParams({ workspace_id: String(workspaceId) }) : {},
        }),
      }),
      studentSnapshot: build.query<
        StudentSnapshot,
        { studentId: StudentProfileId; workspaceId?: WorkspaceId }
      >({
        query: ({ studentId, workspaceId }) => ({
          url: `/api/students/${studentId}/student-snapshot/`,
          method: 'GET',
          params: new URLSearchParams({ workspaceId: String(workspaceId) }),
        }),
      }),
      studentJourney: build.query<StudentJourney, StudentProfileId>({
        query: (studentId) => ({
          url: `/api/students/${studentId}/journey/`,
          method: 'GET',
        }),
      }),
      counselorProfile: build.query<CounselorOwnProfile, void>({
        query: () => `/api/counselorprofile/`,
        providesTags: ['CounselorOwnProfile'],
      }),
      updateCounselorProfile: build.mutation<
        CounselorOwnProfile,
        { payload: Partial<CounselorOwnProfile> }
      >({
        query: ({ payload }) => ({
          url: '/api/counselorprofile/',
          method: 'PATCH',
          data: { partial: true, ...payload },
        }),
        invalidatesTags: ['NotificationSettings'],
      }),
      counselorStudents: build.query<
        Paginated<ExternalStudentProfile>,
        {
          counselorProfileId: CounselorProfileId;
          q?: string;
          date_before?: string;
          date_after?: string;
          is_active?: boolean;
          search?: string;
          page?: number;
          page_size?: number;
        }
      >({
        query: ({ counselorProfileId, ...params }) => ({
          url: `/api/counselors/${counselorProfileId}/students/`,
          method: 'GET',
          params,
        }),
      }),
      updateMentorProfile: build.mutation<
        UpdateMentorProfile,
        { payload: Partial<UpdateMentorProfile> }
      >({
        query: ({ payload }) => ({
          url: `/api/mentorprofile/`,
          method: 'PATCH',
          data: {
            partial: true,
            ...payload,
          },
        }),
      }),
      admissionAdvisorModal: build.query<AdmissionAdvisorModal, { aaEmail?: string }>({
        query: ({ aaEmail }) => ({
          url: `/api/admission_advisor_modal/${aaEmail ?? 'default'}/`,
          method: 'GET',
        }),
      }),
      getPublicCollegeAdmissionSurvey: build.query<
        PublicStudentProfileForCollegeAdmissionSurvey,
        { uuid: UUID }
      >({
        query: ({ uuid }) => ({
          url: `/api/public/students/${uuid}/resources/college_admission_survey/`,
          method: 'GET',
        }),
        providesTags: ['CollegeAdmissionSurvey'],
      }),
      updatePublicCollegeAdmissionSurvey: build.mutation<
        EmptyResponse,
        {
          uuid: UUID;
          payload: { featured_us_as: (string | number)[] };
        }
      >({
        query: ({ uuid, payload }) => {
          return {
            url: `/api/public/students/${uuid}/resources/college_admission_survey/`,
            method: 'PATCH',
            data: payload,
          };
        },
        invalidatesTags: ['CollegeAdmissionSurvey'],
      }),
      getTopColleges: build.query<
        StudentCollegeChoice[],
        {
          profileId?: StudentProfileId;
          profileUuid?: UUID;
        }
      >({
        query: ({ profileId, profileUuid }) => {
          if (profileId) {
            return {
              url: `/zeus/student-college-choices/${profileId}/`,
              method: 'GET',
            };
          } else if (profileUuid) {
            return {
              url: `/api/public/students/${profileUuid}/resources/college_choices/`,
              method: 'GET',
            };
          }
          return {
            url: '/api/student-college-choices/',
            method: 'GET',
          };
        },
        providesTags: ['CollegeChoices'],
      }),
      updateTopColleges: build.mutation<
        EmptyResponse,
        { profileId?: StudentProfileId; profileUuid?: UUID; payload: StudentCollegeChoice[] }
      >({
        query: ({ profileId, profileUuid, payload }) => {
          if (profileId) {
            return {
              url: `/zeus/student-college-choices/${profileId}/`,
              method: 'POST',
              data: payload,
            };
          } else if (profileUuid) {
            return {
              url: `/api/public/students/${profileUuid}/resources/college_choices/`,
              method: 'POST',
              data: payload,
            };
          }
          return {
            url: '/api/student-college-choices/',
            method: 'POST',
            data: payload,
          };
        },
        invalidatesTags: ['CollegeChoices'],
        onQueryStarted: ({ profileId, profileUuid, payload }, { dispatch }) => {
          dispatch(
            marketplaceApi.util.updateQueryData(
              'getTopColleges',
              {
                profileId,
                profileUuid,
              },
              (draft) => {
                Object.assign(draft, payload);
              },
            ),
          );
        },
      }),
      premiumShowcasingSupportMatchingRequest: build.query<EmptyResponse, { uuid: UUID }>({
        query: ({ uuid }) => {
          return `/api/premium-showcasing-support/${uuid}/`;
        },
      }),
      acceptPremiumShowcasingSupportMatchingRequest: build.mutation<EmptyResponse, { uuid: UUID }>({
        query: ({ uuid }) => {
          return {
            url: `/api/premium-showcasing-support/${uuid}/accept/`,
            method: 'POST',
          };
        },
      }),
      declinePremiumShowcasingSupportMatchingRequest: build.mutation<
        EmptyResponse,
        { uuid: UUID; payload: { matchRating?: number; reason?: PSSMatchingDeclineReason } }
      >({
        query: ({ uuid, payload }) => {
          return {
            url: `/api/premium-showcasing-support/${uuid}/decline/`,
            method: 'POST',
            data: payload,
          };
        },
      }),
      notificationSettings: build.query<
        CamelCasedPropertiesDeep<{
          emails: HubSpotEmailNotification[];
          notification_settings: {
            subscribe_to_partner_benefit_opportunities: boolean;
            subscribe_to_marketing_emails: boolean;
          };
        }>,
        void
      >({
        query: () => {
          return '/api/notification-settings/';
        },
        providesTags: ['NotificationSettings'],
      }),
      updateEmailSubscription: build.mutation<
        EmptyResponse,
        {
          payload: CamelCasedPropertiesDeep<{
            hubspot_email_template_email_id: string;
            subscribed: boolean;
          }>;
        }
      >({
        query: ({ payload }) => ({
          url: '/api/email-subscription/update/',
          method: 'POST',
          data: payload,
        }),
        invalidatesTags: ['NotificationSettings'],
      }),
    };
  },
});

export const {
  useTagsQuery,
  useUserCardsQuery,
  useStudentSnapshotQuery,
  useProjectOutcomeTypesQuery,
  usePrefetch,
  useStudentJourneyQuery,
  useCounselorProfileQuery,
  useUpdateCounselorProfileMutation,
  useCounselorStudentsQuery,
  useLazyCounselorStudentsQuery,
  useUpdateMentorProfileMutation,
  useAdmissionAdvisorModalQuery,
  useGetPublicCollegeAdmissionSurveyQuery,
  useUpdatePublicCollegeAdmissionSurveyMutation,
  useGetTopCollegesQuery,
  useUpdateTopCollegesMutation,
  usePremiumShowcasingSupportMatchingRequestQuery,
  useAcceptPremiumShowcasingSupportMatchingRequestMutation,
  useDeclinePremiumShowcasingSupportMatchingRequestMutation,
  useNotificationSettingsQuery,
  useUpdateEmailSubscriptionMutation,
} = marketplaceApi;
