import type { ShowcasingSpecialty } from '@polygence/common/types/marketplace';
import type { OptionType } from '@polygence/components';
import type { MultiValue } from 'react-select';

import { useUpdateMentorProfileMutation } from 'src/reducers/marketplaceReducer';

export interface ShowcasingSpecialtyOption {
  value: ShowcasingSpecialty;
  label: string;
}

export const options: ShowcasingSpecialtyOption[] = [
  { value: 'journal_publications', label: 'Journal Publications' },
  { value: 'science_fairs', label: 'Science Fairs' },
  { value: 'conferences_presentations', label: 'Conferences / Presentations' },
  { value: 'app_development_design', label: 'App Dev / App Design' },
  { value: 'websites', label: 'Websites' },
  { value: 'online_venue', label: 'Online Venue (i.e. Medium, YouTube, etc.)' },
  { value: 'self_publishing', label: 'Self-Publishing / Creative Outlets' },
];

export const useShowcasingSpecialties = (showcasingSpecialties: ShowcasingSpecialty[]) => {
  const [updateMentorProfile] = useUpdateMentorProfileMutation();

  const initialValues = options.filter((option) => showcasingSpecialties.includes(option.value));

  const handleChange = async (newValues: MultiValue<OptionType>) => {
    const showcasingSpecialties = newValues.map(({ value }) => value) as ShowcasingSpecialty[];
    await updateMentorProfile({ payload: { showcasingSpecialties } });
  };

  return { handleChange, initialValues, options };
};
