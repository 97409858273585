import { OwnMentorProfile } from '@polygence/common';
import * as marketplaceApi from '@polygence/common/api/marketplace';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { Availability } from 'src/components/Availability';
import styles from 'src/components/Tiles/MentorAvailabilityTile/MentorAvailabilityTile.module.scss';
import tileIllustrations from 'src/components/Tiles/tile-illustrations';
import tileStyles from 'src/components/Tiles/tilesDashboard.module.scss';

export const MentorAvailabilityTile = () => {
  const [mentor, setMentor] = useState<Partial<OwnMentorProfile>>();

  useEffect(() => {
    marketplaceApi
      .getMentorProfile()
      .then(({ data }) => {
        if (data) {
          setMentor(data);
        }
      })
      .catch(console.error);
  }, []);

  return (
    <div
      className={classNames(tileStyles['tile'], 'd-flex flex-column justify-content-between p-6')}
    >
      {mentor && <Availability mentor={mentor} />}
      <img
        className={classNames(styles['illustration'], 'pb-3')}
        src={tileIllustrations['mentor-availability']}
        alt="Mentor Availability"
      />
    </div>
  );
};
