import { ApplicationSectionIds } from 'src/constants/application-sections';
import {
  contentSpacer,
  headingProps,
  headingSpacer,
  illustrationSize,
  textFieldSpacer,
} from 'src/students/student-application/common-section-components';
import type { ApplicationComponentSection } from 'src/students/student-application/student-application-components';

export const PARENT_SECTION_A: ApplicationComponentSection = {
  id: ApplicationSectionIds.PARENT_SECTION_ID,
  elements: [
    {
      component: 'ThreeColumnLayout',
      props: {
        children: [
          [{ component: 'AdmissionAdvisorModalInfo' }],
          [
            {
              component: 'CenteredIllustration',
              props: {
                name: 'SpaceRocketKissingEarth',
                size: illustrationSize,
              },
            },
            headingSpacer,
            {
              component: 'ComponentsHeading',
              props: {
                children: 'Help us get to know your parents',
                ...headingProps,
              },
            },
            contentSpacer,
            {
              component: 'ComponentsText',
              props: {
                children:
                  'When you’re ready, we’ll coordinate with them on billing and payment details so you don’t have to.',
                className: 'mb-8',
                size: 'medium',
              },
            },
            {
              component: 'InputField',
              tag: 'profile',
              props: { label: 'Parent first name', name: 'parentFirst' },
              validation: {
                required: true,
              },
            },
            textFieldSpacer,
            {
              component: 'InputField',
              tag: 'profile',
              props: { label: 'Parent last name', name: 'parentLast' },
              validation: {
                required: true,
              },
            },
            textFieldSpacer,
            {
              component: 'EmailInputField',
              tag: 'profile',
              props: { label: 'Parent email', name: 'parentEmail' },
              validation: {
                required: true,
                email: true,
                cantBeSameAs: {
                  dataField: 'ownEmail',
                  errorMsg: "You can't use your own email.",
                },
              },
            },
            textFieldSpacer,
            {
              component: 'ComponentsPhoneInput',
              tag: 'profile',
              props: { label: 'Parent phone number (Optional)', name: 'parentPhone' },
              validation: {
                phoneInput: { required: false },
                cantBeSameAs: {
                  dataField: 'ownPhoneNumber',
                  errorMsg: "You can't use your own phone number.",
                },
              },
            },
            contentSpacer,
            {
              component: 'ComponentsText',
              props: {
                children: 'Note: We’ll only contact your parents when you’re ready.',
                size: 'medium',
                alignment: 'center',
                fontWeight: 'bold',
              },
            },
          ],
        ],
      },
    },
  ],
};

export const PARENT_SECTION_B: ApplicationComponentSection = {
  id: ApplicationSectionIds.PARENT_SECTION_ID,
  elements: [
    {
      component: 'ThreeColumnLayout',
      props: {
        children: [
          [{ component: 'AdmissionAdvisorModalInfo' }],
          [
            {
              component: 'CenteredIllustration',
              props: {
                name: 'SpaceRocketKissingEarth',
                size: illustrationSize,
              },
            },
            headingSpacer,
            {
              component: 'ComponentsHeading',
              props: {
                children: 'Help us get to know your parents',
                ...headingProps,
              },
            },
            headingSpacer,
            {
              component: 'ComponentsText',
              props: {
                children:
                  'When you’re ready, we’ll coordinate with them on billing and payment details so you don’t have to.',
                className: 'mb-8',
                size: 'medium',
                alignment: 'center',
              },
            },
            {
              component: 'InputField',
              tag: 'profile',
              props: { label: 'Parent first name', name: 'parentFirst' },
              validation: {
                required: true,
              },
            },
            textFieldSpacer,
            {
              component: 'InputField',
              tag: 'profile',
              props: { label: 'Parent last name', name: 'parentLast' },
              validation: {
                required: true,
              },
            },
            textFieldSpacer,
            {
              component: 'EmailInputField',
              tag: 'profile',
              props: { label: 'Parent email', name: 'parentEmail' },
              validation: {
                required: true,
                email: true,
                cantBeSameAs: {
                  dataField: 'ownEmail',
                  errorMsg: "You can't use your own email address.",
                },
              },
            },
            textFieldSpacer,
            {
              component: 'ComponentsPhoneInput',
              tag: 'profile',
              props: { label: 'Parent phone number (Optional)', name: 'parentPhone' },
              validation: {
                phoneInput: { required: false },
                cantBeSameAs: {
                  dataField: 'ownPhoneNumber',
                  errorMsg: "You can't use your own phone number.",
                },
              },
            },
            textFieldSpacer,
            {
              component: 'ComponentsText',
              props: {
                children: 'Note: We’ll only contact your parents when you’re ready.',
                size: 'medium',
                fontWeight: 'semibold',
                alignment: 'center',
              },
            },
          ],
        ],
      },
    },
  ],
};
