import type { Nullable } from '@polygence/common';
import type { MentorProfileId } from '@polygence/common/types/common';
import type { ShowcasingSpecialty, Tag } from '@polygence/common/types/marketplace';
import { Heading, Spacer } from '@polygence/components';

import { Availability } from 'src/components/Availability';
import { DisplayTags } from 'src/components/admin/components/aux/DisplayTags';
import { ShowcasingSpecialties } from 'src/components/usersettings/ShowcasingSpecialties';

export interface MentoringInfoProps {
  id: MentorProfileId;
  tags?: Tag[];
  available?: boolean;
  concurrentStudents?: Nullable<number>;
  unavailableUntil?: Nullable<string>;
  unavailableSetAutomatically?: boolean;
  unavailableSetAt?: Nullable<string>;
  isShowcasingSpecialist?: boolean;
  showcasingSpecialties?: ShowcasingSpecialty[];
}

const MentoringInfo = ({
  id,
  tags,
  available,
  concurrentStudents,
  unavailableUntil,
  unavailableSetAutomatically,
  unavailableSetAt,
  isShowcasingSpecialist,
  showcasingSpecialties,
}: MentoringInfoProps) => {
  const subjectTags = tags?.filter((tag) => {
    return tag['category'] === 'subject_tag';
  });
  return (
    <>
      <h2 className="mt-5">Mentoring information</h2>
      <Heading size="p" alignment="left">
        Your subject tags
      </Heading>
      <Spacer size={3} />
      <DisplayTags
        tags={subjectTags}
        category="subject_tag"
        label=""
        color="info"
        editable="inline"
        changeUrl={`/api/mentors/${id}/tags/`}
        minTagCount={3}
        maxTagCount={5}
        forceReload={() => window.location.reload()}
      />
      <Availability
        mentor={{
          available,
          concurrentStudents,
          unavailableUntil,
          unavailableSetAutomatically,
          unavailableSetAt,
        }}
      />
      {isShowcasingSpecialist && showcasingSpecialties && (
        <ShowcasingSpecialties showcasingSpecialties={showcasingSpecialties} />
      )}
    </>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default MentoringInfo;
