import { TRANSFORMERS, $convertToMarkdownString } from '@lexical/markdown';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Icon } from '@polygence/components';
import {
  KEY_ENTER_COMMAND,
  COMMAND_PRIORITY_HIGH,
  createCommand,
  LexicalCommand,
  CLEAR_EDITOR_COMMAND,
  COMMAND_PRIORITY_NORMAL,
} from 'lexical';
import { useEffect, useCallback } from 'react';

import { SHORT_LINK } from 'src/components/LexicalEditor/plugins/MarkdownTransformers';

export const SEND_MESSAGE_COMMAND: LexicalCommand<string> = createCommand();

const SendMessagePlugin = ({
  disabled,
  onSend,
  sendOnEnter = false,
}: {
  disabled: boolean;
  onSend: (message: string) => void;
  sendOnEnter?: boolean;
}) => {
  const [editor] = useLexicalComposerContext();

  const sendMessage = useCallback(() => {
    if (disabled || editor.isReadOnly()) {
      return;
    }

    editor.getEditorState().read(() => {
      const rawMarkdown = $convertToMarkdownString([SHORT_LINK, ...TRANSFORMERS]);
      const mdWithCorrectLineBreaks = rawMarkdown.replaceAll(`\n`, `  \n`);
      editor.dispatchCommand(SEND_MESSAGE_COMMAND, mdWithCorrectLineBreaks);
    });
  }, [editor, disabled]);

  useEffect(
    function sendMessageOnEnter() {
      return editor.registerCommand<KeyboardEvent>(
        KEY_ENTER_COMMAND,
        (keyboardEvent) => {
          if (disabled || !keyboardEvent) {
            return false;
          }

          const { ctrlKey, metaKey, shiftKey } = keyboardEvent;
          if (sendOnEnter && !shiftKey) {
            sendMessage();
            return false;
          }

          if (ctrlKey || metaKey) {
            sendMessage();
            return true;
          }

          return false;
        },
        COMMAND_PRIORITY_HIGH,
      );
    },
    [editor, sendMessage, disabled, sendOnEnter],
  );

  useEffect(
    function handleOnSend() {
      return editor.registerCommand<string>(
        SEND_MESSAGE_COMMAND,
        (message) => {
          onSend(message);
          editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);

          return false;
        },
        COMMAND_PRIORITY_NORMAL,
      );
    },
    [editor, onSend],
  );

  return (
    <button
      type="button"
      onClick={sendMessage}
      title="Send message"
      aria-label="Send message"
      className="toolbar-icon toolbar-item send-button"
      disabled={disabled || editor.isReadOnly()}
    >
      <Icon id="send" className="send-icon" />
    </button>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default SendMessagePlugin;
