import type { ShowcasingSpecialty } from '@polygence/common/types/marketplace';
import { Heading } from '@polygence/components';
import { Select } from '@polygence/components';

import { useShowcasingSpecialties } from 'src/components/usersettings/ShowcasingSpecialties/useShowcasingSpecialties';

export const ShowcasingSpecialties = ({
  showcasingSpecialties,
}: {
  showcasingSpecialties: ShowcasingSpecialty[];
}) => {
  const { handleChange, initialValues, options } = useShowcasingSpecialties(showcasingSpecialties);

  return (
    <div className="my-8">
      <Heading size="p" alignment="left" id="showcasing-specialty" className="mb-5">
        Showcasing Specialty
      </Heading>
      <Select
        isMulti
        isClearable
        placeholder="Select Specialties..."
        options={options}
        onChange={(newValues) => void handleChange(newValues)}
        defaultValue={initialValues}
      />
    </div>
  );
};
