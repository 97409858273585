import { FLAGSHIP_NAME } from '@polygence/common/constants/productNames';
import { Spacer } from '@polygence/components';
import { useMemo } from 'react';
import { useParams } from 'react-router';

import { PaymentSuccessDefaultMessage } from 'src/payment/PaymentSuccess/PaymentSuccessDefaultMessage';
import { PaymentSuccessTokenWorkflowMessage } from 'src/payment/PaymentSuccess/PaymentSuccessTokenWorkflowMessage';
import { useGetCartQuery } from 'src/reducers/paymentReducer';

export const PaymentSuccessPage = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { data: cart } = useGetCartQuery(uuid);

  const containsFlagship = useMemo(
    () => !!cart?.addedProducts.some(({ name }) => name === FLAGSHIP_NAME),
    [cart],
  );

  return (
    <div className="py-4">
      <Spacer size={12} />
      <div className="mt-auto px-6 pb-6 flex-column d-flex flex-wrap justify-content-center align-items-center">
        {cart?.tokenWorkflowEnabled ? (
          <PaymentSuccessTokenWorkflowMessage />
        ) : (
          <PaymentSuccessDefaultMessage
            containsFlagship={containsFlagship}
            cartUserData={cart?.user}
          />
        )}
      </div>
    </div>
  );
};
