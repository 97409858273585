import { useEffect, useState } from 'react';

const useExternalScript = (
  scriptUrl: string,
  scriptId: string,
  callback?: VoidFunction,
  cleanUpCallback?: VoidFunction,
) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const existingScript = document.getElementById(scriptId);
    if (!existingScript) {
      const script = document.createElement('script');
      // eslint-disable-next-line fp/no-mutation -- autodisabled
      script.src = scriptUrl;
      // eslint-disable-next-line fp/no-mutation -- autodisabled
      script.id = scriptId;
      document.body.appendChild(script);

      // eslint-disable-next-line fp/no-mutation -- autodisabled
      script.onload = () => {
        setReady(true);
        if (callback) {
          callback();
        }
      };
    }

    if (existingScript) {
      setReady(true);
    }

    if (callback) {
      callback();
    }

    return () => {
      if (cleanUpCallback) {
        cleanUpCallback();
      }
    };
  }, [scriptUrl, scriptId, callback, cleanUpCallback]);

  return ready;
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default useExternalScript;
