import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { MentorProfileData } from '../../types/data/marketplace';
import type { UserState } from '../../types/user';

const initialState: UserState = {
  loggedIn: false,

  approved: false,
  channel: 'standard',
  calendarConnected: false,
  screened: false,
  hasActiveProject: false,
  otherInfo: {},
  pendingPolicies: [],
  eligibleForReferral: false,
  referralUrl: '',
  referralCode: null,
  profilePicture: null,
  expoFcmToken: null,
  profileComplete: false,
  missingDesiredFieldsList: [],
  firstName: '',
  lastName: '',
  timeZone: '',
  email: '',
  opportunitiesEnabled: false,
  teacherFeedbackGroup: null,
  discordEnabled: false,
  scholarGroup: null,
  socialAuthGoogleConnected: false,
  hermesEnabled: false,
  timeZoneMismatch: false,
  missingDemographyData: false,
  welcomeVideoSeenAt: null,

  userType: 'student',
  isAdmin: false,
  isIntern: false,
  isTest: false,
  isVerified: false,
  completed: false,
  roles: [],
  userStatus: '',
  phoneNumber: '',
  id: -1,
  profileId: -1,

  token: null,

  utilities: {
    isStudent: true,
    isMentor: false,
    isParent: false,
    isCounselor: false,
  },

  mentorData: {
    available: false,
    unavailableUntil: '',
    concurrentStudents: 0,
  },
  isStripeOnboardingAllowed: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loadUser: (state, { payload }: PayloadAction<Omit<UserState, 'loggedIn'>>) => {
      return {
        loggedIn: true,
        ...payload,
        mentorData: { ...state.mentorData },
        utilities: {
          isStudent: payload.userType === 'student',
          isMentor: payload.userType === 'mentor',
          isParent: payload.userType === 'parent',
          isCounselor: payload.userType === 'counselor',
        },
      };
    },
    removeUser: () => {
      return { ...initialState };
    },
    loadMentorData: (state, { payload }: PayloadAction<Partial<MentorProfileData>>) => {
      state.mentorData = { ...state.mentorData, ...payload };
    },
    updateUser: (state, { payload }: PayloadAction<Partial<UserState>>) => {
      return { ...state, ...payload };
    },
  },
});

const { actions, reducer } = userSlice;

export const userActions = actions;
export const userReducer = reducer;
