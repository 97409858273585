import { CartDiscount } from '@polygence/common/types/payment';
import { pluralize } from '@polygence/common/utils/pluralize';
import { cn } from '@polygence/components';
import { useState } from 'react';
import { useInterval } from 'react-use';

import { dayjs } from 'src/utils/dayjsCustom';

const ONE_SECOND = 1000;
const ONE_HOUR = ONE_SECOND * 60 * 60;

const CountDown = ({ expiresAt }: { expiresAt: string }) => {
  const [date, setDate] = useState(new Date());

  const expiresInSeconds = dayjs(expiresAt).diff(dayjs(date), 'seconds');
  const expiresInMinutes = Math.floor(expiresInSeconds / 60);
  const expiresInHours = Math.floor(expiresInMinutes / 60);
  const expiresInDays = Math.floor(expiresInHours / 24);
  const remainingHours = expiresInHours % 24;
  const remainingMinutes = expiresInMinutes % 60;
  const remainingSeconds = expiresInSeconds % 60;
  const lessThanADay = expiresInHours < 24;

  useInterval(
    () => {
      setDate(new Date());
    },
    lessThanADay ? ONE_SECOND : ONE_HOUR,
  );

  if (lessThanADay) {
    return (
      <>
        {remainingHours.toString().padStart(2, '0')}:{remainingMinutes.toString().padStart(2, '0')}:
        {remainingSeconds.toString().padStart(2, '0')}
      </>
    );
  }
  return (
    <>
      {expiresInDays} {pluralize('day', expiresInDays)}
      {remainingHours !== 0 && ` and ${remainingHours} ${pluralize('hour', remainingHours)}`}
    </>
  );
};

export const ExpiringDiscounts = ({ expiringDiscounts }: { expiringDiscounts: CartDiscount[] }) => {
  if (expiringDiscounts.length === 0) {
    return null;
  }
  const highestAmountDiscount =
    expiringDiscounts.length === 1
      ? expiringDiscounts[0]
      : expiringDiscounts.sort((a, b) => b.appliedAmountInCents - a.appliedAmountInCents)[0];
  if (
    !highestAmountDiscount ||
    !highestAmountDiscount.expiresAt ||
    dayjs(highestAmountDiscount.expiresAt).isBefore(dayjs())
  ) {
    return null;
  }
  return (
    <div
      className={cn(
        'tw-relative tw-mb-5 tw-rounded-2xl tw-bg-gray-700',
        'tw-px-5 tw-py-4 tw-text-gray-100',
      )}
    >
      <div className="tw-font-bold">{highestAmountDiscount?.displayName}</div>
      <div className="tw-mr-20">
        expires in{' '}
        <span className="tw-text-lg tw-font-bold tw-tabular-nums">
          <CountDown expiresAt={highestAmountDiscount.expiresAt} />
        </span>
      </div>
      <div
        className={cn(
          'tw-absolute -tw-top-2 tw-right-4 tw-h-24 tw-w-24',
          'tw-flex tw-items-center tw-justify-center',
          'tw-rounded-full tw-border-2 tw-border-white tw-ring-2 tw-ring-red-500 tw-ring-offset-2',
          'tw-bg-red-500 tw-text-gray-100',
        )}
      >
        <p className="tw-m-0 tw-text-3xl">
          -${Math.round(highestAmountDiscount.appliedAmountInCents / 100)}
        </p>
      </div>
    </div>
  );
};
