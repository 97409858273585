/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable fp/no-mutation */
import NiceModal from '@ebay/nice-modal-react';
import { commonHooks } from '@polygence/common';
import { Button, Collapsible, Icon, IconVariable, Text, Tooltip } from '@polygence/components';
import classNames from 'classnames';
import { useEffect, useMemo } from 'react';

import { usePolyGPTContext } from 'src/polygpt/PolyGPTContext';
import styles from 'src/polygpt/PolyPilotStageTracker.module.scss';
import { PurchasePolyPilotModal } from 'src/polygpt/PurchasePolyPilotModal';
import { UploadProjectFileModal } from 'src/polygpt/UploadProjectFileModal';
import { useIsPolyGptAdmin } from 'src/polygpt/debug-features/useIsPolyGptAdmin';
import { useIsPolyPilotMentor } from 'src/polygpt/debug-features/useIsPolyPilotMentor';
import { useIsPolyPilotTester } from 'src/polygpt/debug-features/useIsPolyPilotTester';
import { MentorFeedbackModal } from 'src/polygpt/debug-features/waiting-for-approval/MentorFeedbackModal';
import { polyGptApi, useGetPolyPilotProjectDetailsQuery } from 'src/reducers/polyGptReducers';
import { useAppDispatch } from 'src/store';
import type { ProjectStage, StageGroupDisplay } from 'src/types/polygence/polygpt';

const PolyPilotStageGroup = ({
  stages,
  stageGroup,
  projectId,
}: {
  stages: ProjectStage[];
  stageGroup: StageGroupDisplay;
  projectId: number;
}) => {
  const isActive = stages.some((stage) => stage.isActive);
  const firstLockedStageIndex = stages.findIndex((stage) => !stage.isUnlocked);
  const indexToSplitAt = firstLockedStageIndex > -1 ? firstLockedStageIndex : stages.length;

  const unlockedStages = stages.slice(0, indexToSplitAt);
  const lockedStages = stages.slice(indexToSplitAt, stages.length);

  const isStageGroupLocked = unlockedStages.length === 0;

  return (
    <Collapsible
      summary={
        <div className={classNames({ 'opacity-50': isStageGroupLocked })}>
          {isStageGroupLocked && (
            <Icon id="lock" size="xs" color="var(--grayscale-6)" strokeWidth={3} className="me-2" />
          )}
          {isActive && (
            <Icon id="chevron-right" size="sm" color="var(--grayscale-7)" className="ms-n3" />
          )}
          {stageGroup.displayName}
        </div>
      }
      rootClassNames={styles['collapsible']}
      defaultOpen={isActive}
    >
      <div className="d-flex flex-column gap-3 pb-4 pt-2">
        {unlockedStages.map((stage) => (
          <PolyPilotStage key={stage.id} projectId={projectId} projectStage={stage} />
        ))}
        {lockedStages.length > 0 && (
          <div className="position-relative">
            <div className={styles['lockOverlay']}>
              <div className="d-flex align-items-center gap-3">
                <Icon size="lg" id="lock" />
                <Text size="small" className="text-uppercase" fontWeight="semibold">
                  Locked content
                </Text>
              </div>
            </div>
            {
              // eslint-disable-next-line sonarjs/no-identical-functions
              lockedStages.map((stage) => (
                <PolyPilotStage key={stage.id} projectId={projectId} projectStage={stage} />
              ))
            }
          </div>
        )}
      </div>
    </Collapsible>
  );
};

type PolyPilotAddonKind =
  | 'waitingForApprove'
  | 'waitingForMentorMatching'
  | 'upcomingCheckpoint'
  | 'activeCheckpoint'
  | 'waitingForUpload'
  | 'purchaseToUnlock';

const getAddonKind = (
  projectStage: ProjectStage,
  isMentorAssigned: boolean,
  isProjectUnlocked: boolean,
): PolyPilotAddonKind | null => {
  const isCompleted = Boolean(projectStage.completedAt) && Boolean(projectStage.approvedAt);
  const isWaitingForApprove =
    projectStage.stage.needsAdminApprove &&
    Boolean(projectStage.completedAt) &&
    !projectStage.approvedAt;
  const isWaitingForUpload =
    projectStage.stage.type === 'file' && projectStage.isActive && !projectStage.completedAt;
  const needsAdminApprove = projectStage.stage.needsAdminApprove;
  const purchaseToUnlock =
    projectStage.stage.needsAdminApprove && projectStage.isUnlocked && !isProjectUnlocked;

  if (isCompleted) {
    return null;
  }

  if (purchaseToUnlock) {
    return 'purchaseToUnlock';
  }

  if (isWaitingForApprove) {
    return isMentorAssigned ? 'waitingForApprove' : 'waitingForMentorMatching';
  }

  if (isWaitingForUpload) {
    return 'waitingForUpload';
  }

  if (needsAdminApprove) {
    return projectStage.isActive ? 'activeCheckpoint' : 'upcomingCheckpoint';
  }

  return null;
};

const iconsPerAddonKind: Record<PolyPilotAddonKind, IconVariable> = {
  activeCheckpoint: 'user',
  upcomingCheckpoint: 'user',
  waitingForUpload: 'upload',
  waitingForApprove: 'clock',
  waitingForMentorMatching: 'clock',
  purchaseToUnlock: 'lock',
};

const tooltipPerAddonKind: Record<PolyPilotAddonKind, string> = {
  activeCheckpoint:
    'In order to provide you with the best experience, one of our mentors will be reviewing your progress before you can move on to the next stage.',
  upcomingCheckpoint:
    'In order to provide you with the best experience, one of our mentors will be reviewing your progress before you can move on to the next stage.',
  waitingForUpload:
    'Before you can move to the next stage, you have to upload the requested document, which will be reviewed by our mentor.',
  waitingForApprove:
    'In order to provide you with the best experience, one of our mentors is reviewing your progress before you can move on to the next stage. This process can take up to 48 hours.',
  waitingForMentorMatching:
    'It may take up to two weeks to receive mentor feedback at this checkpoint, as this includes the time to match you with an ideal mentor based on your project topic you have discussed with PolyPilot.',
  purchaseToUnlock: 'Purchase PolyPilot to get access to mentor feedback.',
};

const MENTOR_FEEDBACK_CHECKPOINT_LABEL = 'Mentor Feedback Checkpoint';
const WAITING_FOR_MENTOR_FEEDBACK_LABEL = 'Waiting For Mentor Feedback';
const PURCHASE_TO_UNLOCK_LABEL = 'Mentor Feedback Locked';
const labelPerAddonKind: Record<PolyPilotAddonKind, string> = {
  activeCheckpoint: MENTOR_FEEDBACK_CHECKPOINT_LABEL,
  upcomingCheckpoint: MENTOR_FEEDBACK_CHECKPOINT_LABEL,
  waitingForUpload: MENTOR_FEEDBACK_CHECKPOINT_LABEL,
  waitingForApprove: WAITING_FOR_MENTOR_FEEDBACK_LABEL,
  waitingForMentorMatching: WAITING_FOR_MENTOR_FEEDBACK_LABEL,
  purchaseToUnlock: PURCHASE_TO_UNLOCK_LABEL,
};

const PolyPilotStageAddon = ({
  projectStage,
  projectId,
}: {
  projectStage: ProjectStage;
  projectId: number;
}) => {
  const currentUser = commonHooks.useCurrentUser();
  const { websocketConnection } = usePolyGPTContext();

  const { isMentorAssigned, isProjectUnlocked, user, paymentIntentId } =
    useGetPolyPilotProjectDetailsQuery(projectId, {
      selectFromResult: (res) => ({
        isMentorAssigned: Boolean(res.data?.mentorFirstName),
        isProjectUnlocked: Boolean(res.data?.unlockedAt),
        user: res.data?.user,
        paymentIntentId: res.data?.paymentIntentId,
      }),
    });

  const addonKind = getAddonKind(projectStage, isMentorAssigned, isProjectUnlocked);
  const isPolyGptAdmin = useIsPolyGptAdmin();
  const isPolyPilotMentor = useIsPolyPilotMentor();
  const isPolyPilotTester = useIsPolyPilotTester();
  const isOwnProject = currentUser.id === user?.id;

  const openMentorFeedbackModal = () => {
    NiceModal.show(`mentor-feedback-${projectStage.id}`).catch(console.error);
  };

  const openUploadProjectFileModal = () => {
    NiceModal.show(UploadProjectFileModal, { projectId, projectStage, websocketConnection }).catch(
      console.error,
    );
  };

  const openPurchaseModal = () => {
    NiceModal.show(PurchasePolyPilotModal, {
      projectId: projectId,
      paymentIntentId: paymentIntentId,
    }).catch(console.error);
  };

  if (!addonKind) {
    return null;
  }

  return (
    <>
      {(addonKind === 'waitingForUpload' ||
        (projectStage.isActive && projectStage.stage.type === 'file')) && (
        <div className={styles['fileUpload']}>
          <div className={styles['container']}>
            <Text size="small">
              {!projectStage.completedAt ? 'Missing documents' : 'Edit documents'}:
            </Text>
            <div className="d-flex gap-3 justify-content-between">
              <div className="d-flex align-items-center">
                {!projectStage.completedAt ? (
                  <Icon id="alert-triangle" size="sm" color="var(--grayscale-6)" className="me-2" />
                ) : (
                  <Icon id="check-circle" size="sm" color="var(--grayscale-6)" className="me-2" />
                )}
                <Text size="small" fontWeight="bold">
                  {projectStage.stage.displayFileName}
                </Text>
              </div>
              <Button
                variant="tertiary"
                onClick={openUploadProjectFileModal}
                size="sm"
                className="ms-auto"
                endIcon={<Icon id="upload" />}
              >
                Upload
              </Button>
            </div>
          </div>
        </div>
      )}
      <div className={classNames(styles['stageAddon'], styles[addonKind])}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <Tooltip placement="top-start" tip={tooltipPerAddonKind[addonKind]}>
            <div className="d-flex align-items-center">
              <Icon
                size="sm"
                id={iconsPerAddonKind[addonKind]}
                className="me-3"
                color="var(--icon-color)"
              />
              <Text size="small" fontWeight="semibold" className="text-uppercase">
                {labelPerAddonKind[addonKind]}
              </Text>
            </div>
          </Tooltip>
          {addonKind === 'waitingForApprove' &&
            (isPolyGptAdmin || isPolyPilotMentor || isPolyPilotTester) && (
              <>
                <Button variant="tertiary" size="sm" onClick={() => openMentorFeedbackModal()}>
                  Feedback
                </Button>
              </>
            )}
          {addonKind === 'purchaseToUnlock' && isOwnProject && (
            <>
              <Button variant="primary" size="sm" onClick={openPurchaseModal}>
                UNLOCK
              </Button>
            </>
          )}
        </div>
        <MentorFeedbackModal
          id={`mentor-feedback-${projectStage.id}`}
          projectId={projectId}
          projectStage={projectStage}
        />
      </div>
    </>
  );
};

const PolyPilotStage = ({
  projectStage,
  projectId,
}: {
  projectStage: ProjectStage;
  projectId: number;
}) => {
  const { messageContainerRef } = usePolyGPTContext();

  const onClick = () => {
    if (!projectStage.firstMessageId || !messageContainerRef || !messageContainerRef.current) {
      return;
    }

    const message = messageContainerRef.current.querySelector<HTMLDivElement>(
      `#message-${projectStage.firstMessageId}`,
    );

    if (message) {
      messageContainerRef.current.scrollTop = message.offsetTop - 65;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === 'Space' || e.key === ' ') {
      onClick();
    }
  };

  const isCompleted = Boolean(projectStage.completedAt) && Boolean(projectStage.approvedAt);

  return (
    <div
      className={classNames(styles['wrapper'], {
        [styles['active'] as string]: projectStage.isActive,
        [styles['completed'] as string]: isCompleted,
        [styles['link'] as string]: projectStage.firstMessageId,
        'opacity-50': !projectStage.isUnlocked,
      })}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        {...(projectStage.firstMessageId ? { role: 'button', tabIndex: 0 } : {})}
        className={styles['polyPilotStage']}
        onKeyDown={handleKeyDown}
        onClick={onClick}
      >
        <Text size="small" alignment="left" fontWeight={projectStage.isActive ? 'bold' : 'normal'}>
          {projectStage.stage.displayName}
        </Text>
      </div>
      <PolyPilotStageAddon projectStage={projectStage} projectId={projectId} />
    </div>
  );
};

export const PolyPilotStageTracker = ({ projectId }: { projectId: number }) => {
  const { data: project, isLoading } = useGetPolyPilotProjectDetailsQuery(projectId);
  const { websocketConnection } = usePolyGPTContext();
  const dispatch = useAppDispatch();

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (!websocketConnection) {
      return;
    }

    function handleSocketMessage(event: MessageEvent) {
      const data = JSON.parse(event.data);

      if (data.project_id !== projectId) {
        return;
      }

      if (data.type === 'polygpt.project.stage_completed') {
        dispatch(polyGptApi.util.invalidateTags([{ type: 'ProjectDetails', id: projectId }]));
      }

      if (data.type === 'polygpt.message.finish') {
        const conversationIds =
          project?.projectStages?.map((stage) => stage.conversationId).filter(Boolean) ?? [];

        const conversationIdFromMessage = data['conversation_id'] ? data['conversation_id'] : null;

        if (!conversationIdFromMessage) {
          return;
        }

        // this means our projectDetails got outdated somehow, time to refetch
        if (
          !conversationIds.includes(conversationIdFromMessage) ||
          !project?.projectStages?.find((stage) => stage.conversationId)?.isActive
        ) {
          dispatch(polyGptApi.util.invalidateTags([{ type: 'ProjectDetails', id: projectId }]));
        }
      }
    }

    websocketConnection.addEventListener('message', handleSocketMessage);

    return () => {
      websocketConnection.removeEventListener('message', handleSocketMessage);
    };
  }, [websocketConnection, projectId, project?.projectStages, dispatch]);

  const groupedStages = useMemo(() => {
    if (!project?.projectStages) {
      return [];
    }

    const stagesPerStageGroups = project.projectStages.reduce(
      (
        acc: Record<number, { stages: ProjectStage[]; stageGroup: StageGroupDisplay }>,
        projectStage: ProjectStage,
      ) => {
        const stageGroup = projectStage.stage.stageGroup;
        if (!acc[stageGroup.id]) {
          acc[stageGroup.id] = {
            stageGroup,
            stages: [],
          };
        }

        acc[stageGroup.id]?.stages?.push(projectStage);
        return acc;
      },
      {},
    );

    return Object.values(stagesPerStageGroups).sort(
      (a, b) => a.stageGroup.order - b.stageGroup.order,
    );
  }, [project?.projectStages]);

  if (isLoading || !project) {
    return null;
  }

  return (
    <div className="d-flex flex-column gap-2 pt-4 pe-3 overflow-auto flex-grow-1">
      {groupedStages.map(({ stageGroup, stages }) => {
        return (
          <PolyPilotStageGroup
            key={stageGroup.id}
            projectId={project.id}
            stageGroup={stageGroup}
            stages={stages}
          />
        );
      })}
    </div>
  );
};
