import type { PaymentIntentInvoice } from '@polygence/common/types/payment';
import { PaymentIntentTypes } from '@polygence/common/types/payment';
import { Card, Heading, Spacer, Text } from '@polygence/components';
import classNames from 'classnames';
import type { CSSProperties, ReactNode } from 'react';

import { AmountOrPlaceholder } from 'src/payment/PaymentIntentV2/AmountOrPlaceholder';
import styles from 'src/payment/PaymentIntentV2/PaymentTypeSelector.module.scss';

export const PaymentTypeSelector = ({
  paymentIntent,
  onChange,
  children,
}: {
  paymentIntent: PaymentIntentInvoice;
  onChange: (type: PaymentIntentTypes) => void;
  children?: ReactNode;
}) => {
  const {
    type,
    featureTypeChangeByUser,
    baseAmount,
    installmentAmount,
    installmentCount,
    riskPremium,
    pitchedProducts,
  } = paymentIntent;

  const paymentTypes = [PaymentIntentTypes.ONE_TIME, PaymentIntentTypes.SUBSCRIPTION];
  const availablePaymentTypes = paymentTypes.filter((t) => featureTypeChangeByUser || t === type);

  return (
    <div className="d-flex flex-column gap-6">
      {children}
      {availablePaymentTypes.length > 1 ? (
        <Heading as="h2" size="h5" alignment="center">
          Choose your payment method:
        </Heading>
      ) : (
        <Spacer size={{ md: 9 }} />
      )}
      <div
        className={classNames(
          {
            [styles['noProducts'] as string]: pitchedProducts.length === 0,
          },
          'd-flex flex-column flex-md-row flex-lg-column gap-4',
        )}
      >
        {availablePaymentTypes.includes(PaymentIntentTypes.ONE_TIME) && (
          <>
            <input
              className={styles['paymentTypeInput']}
              id={PaymentIntentTypes.ONE_TIME}
              type="radio"
              name="payment_type"
              onChange={() => onChange(PaymentIntentTypes.ONE_TIME)}
              value={PaymentIntentTypes.ONE_TIME}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={PaymentIntentTypes.ONE_TIME} className={styles['paymentTypeLabel']}>
              <Card
                body
                isActive={type === PaymentIntentTypes.ONE_TIME}
                // making it appear clickable by attaching an onClick handler
                onClick={() => void 0}
              >
                <div className="d-flex flex-column align-items-center gap-3 justify-items-center">
                  <Heading as="h3" size="h5">
                    One-Time Payment
                  </Heading>
                  <AmountOrPlaceholder size="large" fontWeight="light" amount={baseAmount} />
                  <ul
                    // eslint-disable-next-line react/forbid-dom-props
                    style={{ fontSize: 'var(--font-size-small)' } as CSSProperties}
                    className="d-inline-block mb-0"
                  >
                    <li>Pay in a single payment now</li>
                    {riskPremium > 0 && <li>You save {riskPremium}% with this option</li>}
                  </ul>
                </div>
              </Card>
            </label>
          </>
        )}

        {availablePaymentTypes.includes(PaymentIntentTypes.SUBSCRIPTION) && (
          <>
            <input
              className={styles['paymentTypeInput']}
              id={PaymentIntentTypes.SUBSCRIPTION}
              type="radio"
              name="payment_type"
              onChange={() => onChange(PaymentIntentTypes.SUBSCRIPTION)}
              value={PaymentIntentTypes.SUBSCRIPTION}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={PaymentIntentTypes.SUBSCRIPTION} className={styles['paymentTypeLabel']}>
              <Card
                body
                isActive={type === PaymentIntentTypes.SUBSCRIPTION}
                // making it appear clickable by attaching an onClick handler
                onClick={() => void 0}
              >
                <div className="d-flex flex-column align-items-center gap-3 justify-items-center">
                  <Heading as="h3" size="h5">
                    Monthly Installment Payment
                  </Heading>
                  <div className="d-flex gap-4 justify-content-center align-items-center">
                    <AmountOrPlaceholder
                      size="large"
                      fontWeight="light"
                      amount={installmentAmount}
                    />
                    <Text size="large" fontWeight="light">
                      x {installmentCount}
                    </Text>
                  </div>
                  <ul
                    // eslint-disable-next-line react/forbid-dom-props
                    style={{ fontSize: 'var(--font-size-small)' } as CSSProperties}
                    className="d-inline-block mb-0"
                  >
                    <li>Pay in smaller installments over {installmentCount} consecutive months</li>
                    <li>Automatically charged each month</li>
                  </ul>
                </div>
              </Card>
            </label>
          </>
        )}
      </div>
    </div>
  );
};
