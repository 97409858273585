import { Button, Icon, Text } from '@polygence/components';
import { useState } from 'react';
import { Redirect, useRouteMatch } from 'react-router';

import { PageLoad } from 'src/components/PageLoad';
import { usePolyGPTContext } from 'src/polygpt/PolyGPTContext';
import { WelcomeHeader } from 'src/polygpt/WelcomeHeader';
import { useListPolyPilotProjectsQuery } from 'src/reducers/polyGptReducers';

export const CreateNewProject = () => {
  const { websocketConnection } = usePolyGPTContext();
  const match = useRouteMatch();
  const [isCreateInProgress, setIsCreateInProgress] = useState(false);

  const { data: projects = [], isLoading } = useListPolyPilotProjectsQuery();

  const handleCreateNewProject = () => {
    if (!websocketConnection || isCreateInProgress) {
      return;
    }

    websocketConnection.send(
      JSON.stringify({
        type: 'polygpt.create_project',
      }),
    );

    setIsCreateInProgress(true);
  };

  if (isLoading) {
    return <PageLoad />;
  }

  if (!isLoading && projects.length > 0 && projects[0]) {
    return <Redirect to={`${match.url}/${projects[0].uuid}`} />;
  }

  return (
    <div className="h-100 d-flex flex-column justify-content-start align-items-center container overflow-auto pt-5 pt-lg-9">
      <WelcomeHeader />
      <div className="row">
        <div className="col-lg-8 p-4 animated">
          <Text size="medium">
            With the wisdom of Polygence's expert mentors (who've led 6,000+ students to success),
            PolyPilot is here to guide you through your own literature review research project.
          </Text>
          <Text size="medium">
            Here's how PolyPilot propels your project to greater heights:
            <ul className="mt-4">
              <li>Tailored topic brainstorming</li>
              <li>Direct links to resources</li>
              <li>Customized project planning</li>
              <li>Insightful feedback on drafts</li>
              <li>Constant support and motivation</li>
            </ul>
          </Text>
          <Text size="medium">
            🚨 Stay on course with "Mentor Checkpoints:” crucial stages where your progress is
            reviewed by a real Polygence mentor, ensuring you're on the path to success.
          </Text>
          <Text size="medium">
            ✏️ Stuck or need more? Speak up! PolyPilot adapts to your needs like any human mentor
            would—ask for help, clarification, or express concerns anytime.
          </Text>
          <Text size="medium">
            🗣️ Give small or big feedback anytime to Polygence by clicking the Support button in the
            bottom left of your screen.
          </Text>
        </div>
        <div className="col-lg-4 d-flex flex-column align-items-center justify-content-center p-4 pb-8">
          <Text size="medium" alignment="center">
            Let's dive in!
          </Text>
          <Button
            variant="primary"
            className="mt-4"
            onClick={handleCreateNewProject}
            disabled={isCreateInProgress}
            endIcon={isCreateInProgress ? <Icon id="loader" className="rotate" /> : <></>}
          >
            Create new project
          </Button>
        </div>
      </div>
    </div>
  );
};
