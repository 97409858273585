/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { externalLink } from '@polygence/common';
import {
  Button,
  Heading,
  Icon,
  InputField,
  Sheet,
  SheetContent,
  SheetTrigger,
  ToggleSwitch,
  Tooltip,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@polygence/components';
import { FormEvent, useEffect, useState } from 'react';
import type { ChangeEvent } from 'react';

import { Loader } from 'src/components/Loader';
import { ReactMarkdown } from 'src/components/aux/markdown/ReactMarkdown';
import { SyntaxHighlighter } from 'src/components/aux/markdown/SyntaxHighlighter';
import { usePolyGPTContext } from 'src/polygpt/PolyGPTContext';

const markdownComponents = {
  pre: SyntaxHighlighter,
};

export interface ResearchPaper {
  title: string;
  abstract: string;
  authors: string;
  url: string;
  year: number;
  raw_data: Record<string, unknown>;
}

export const ResearchPaperSearch = () => {
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [includePaid, setIncludePaid] = useState(false);
  const [data, setData] = useState<Record<string, ResearchPaper[]>>({});

  const { websocketConnection } = usePolyGPTContext();

  const dataArray = Object.entries(data).map(([key, value]) => ({ name: key, papers: value }));

  useEffect(() => {
    if (!websocketConnection) {
      return;
    }

    function handleSocketMessage(event: MessageEvent) {
      const data = JSON.parse(event.data);

      if (data.type === 'polygpt.search_papers.result') {
        setData(data.message as Record<string, ResearchPaper[]>);
        setIsLoading(false);
      }
    }

    websocketConnection.addEventListener('message', handleSocketMessage);

    return () => {
      websocketConnection.removeEventListener('message', handleSocketMessage);
    };
  }, [websocketConnection]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const toggleIncludePaid = (checked: boolean) => {
    setIncludePaid(checked);
  };

  const handleSearch = (e: FormEvent) => {
    e.preventDefault();

    if (!websocketConnection || isLoading || !inputValue) {
      return;
    }

    websocketConnection.send(
      JSON.stringify({
        type: 'polygpt.search_papers',
        query: inputValue,
        include_paid: includePaid,
      }),
    );

    setData({});
    setIsLoading(true);
  };

  return (
    <Sheet modal={false}>
      <SheetTrigger asChild>
        <Button variant="link" size="sm" endIcon={<Icon id="search" />}>
          Search papers
        </Button>
      </SheetTrigger>
      <SheetContent className="d-flex flex-column gap-4">
        <form className="d-flex gap-3 w-75 mx-auto" onSubmit={handleSearch}>
          <label
            className="d-flex flex-column gap-2 align-items-center form-label text-nowrap"
            htmlFor="includePaid"
          >
            Include paid
            <ToggleSwitch
              aria-label="include paid"
              name="includePaid"
              checked={includePaid}
              onCheckedChange={toggleIncludePaid}
            />
          </label>
          <InputField
            name="search"
            placeholder="Search query"
            onChange={handleInputChange}
            value={inputValue}
          />
          <Button
            variant="primary"
            type="submit"
            endIcon={<Icon id="search" />}
            disabled={!inputValue || isLoading}
          >
            Search
          </Button>
        </form>
        <div>
          {dataArray.map(({ name, papers }) => (
            <div key={name} className="mb-7">
              <Heading size="h5" alignment="left" className="mb-3">
                {name}
              </Heading>
              <table className="w-100">
                <thead>
                  <tr className="border-bottom">
                    <th className="p-3">Title</th>
                    <th className="p-3">Authors</th>
                    <th className="p-3">Year</th>
                    <th className="p-3">Link</th>
                    <th className="p-3">Data</th>
                  </tr>
                </thead>
                <tbody>
                  {papers.map(({ title, abstract, authors, url, year, raw_data }) => (
                    <tr key={`${name}_${title}`} className="border-bottom">
                      <Tooltip tip={abstract} placement="left">
                        <td className="p-3">{title}</td>
                      </Tooltip>
                      <td className="p-3">{authors}</td>
                      <td className="p-3">{year}</td>
                      <td className="p-3">
                        <Button
                          variant="link"
                          href={url}
                          endIcon={<Icon id="external-link" />}
                          {...externalLink}
                        />
                      </td>
                      <td className="p-3">
                        <Popover>
                          <PopoverTrigger asChild>
                            <Button variant="link" endIcon={<Icon id="code" />} />
                          </PopoverTrigger>
                          <PopoverContent
                            style={{ width: '600px', height: '400px', overflow: 'auto' }}
                          >
                            <ReactMarkdown components={markdownComponents}>
                              {`\`\`\`\n${JSON.stringify(raw_data, null, 2)}\n\`\`\``}
                            </ReactMarkdown>
                          </PopoverContent>
                        </Popover>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
          {isLoading && <Loader className="mx-auto" />}
        </div>
      </SheetContent>
    </Sheet>
  );
};
