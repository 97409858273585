import { getStoreWith } from '@polygence/common';
import type { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { adminApi } from 'src/reducers/adminReducers';
import { backendApi } from 'src/reducers/backendReducer';
import { collegeCreditsApi } from 'src/reducers/collegeCreditsReducer';
import { feedbackRequestApi } from 'src/reducers/feedbackRequestReducers';
import { guidebookApi } from 'src/reducers/guidebookReducers';
import { hermesProjectApi } from 'src/reducers/hermesProjectReducer';
import { marketplaceApi } from 'src/reducers/marketplaceReducer';
import { partnershipsApi } from 'src/reducers/partnershipsReducer';
import { pathfinderApplicationInterestApi } from 'src/reducers/pathfinderApplicationInterestReducer';
import { paymentApi } from 'src/reducers/paymentReducer';
import { podsApi } from 'src/reducers/podsReducers';
import { polyGptApi } from 'src/reducers/polyGptReducers';
import { productApi } from 'src/reducers/productReducer';
import { projectIdeaGeneratorApi } from 'src/reducers/projectIdeaGeneratorReducer';
import { showcasingApi } from 'src/reducers/showcasingReducers';
import { siteContentApi } from 'src/reducers/siteContentReducers';
import { surveyApi } from 'src/reducers/surveyReducer';

export const reducers = {
  [adminApi.reducerPath]: adminApi.reducer,
  [backendApi.reducerPath]: backendApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [guidebookApi.reducerPath]: guidebookApi.reducer,
  [partnershipsApi.reducerPath]: partnershipsApi.reducer,
  [pathfinderApplicationInterestApi.reducerPath]: pathfinderApplicationInterestApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  [showcasingApi.reducerPath]: showcasingApi.reducer,
  [marketplaceApi.reducerPath]: marketplaceApi.reducer,
  [surveyApi.reducerPath]: surveyApi.reducer,
  [feedbackRequestApi.reducerPath]: feedbackRequestApi.reducer,
  [polyGptApi.reducerPath]: polyGptApi.reducer,
  [projectIdeaGeneratorApi.reducerPath]: projectIdeaGeneratorApi.reducer,
  [collegeCreditsApi.reducerPath]: collegeCreditsApi.reducer,
  [siteContentApi.reducerPath]: siteContentApi.reducer,
  [hermesProjectApi.reducerPath]: hermesProjectApi.reducer,
  [podsApi.reducerPath]: podsApi.reducer,
};

export const middlewares = [
  adminApi.middleware,
  backendApi.middleware,
  productApi.middleware,
  guidebookApi.middleware,
  partnershipsApi.middleware,
  paymentApi.middleware,
  pathfinderApplicationInterestApi.middleware,
  showcasingApi.middleware,
  marketplaceApi.middleware,
  surveyApi.middleware,
  feedbackRequestApi.middleware,
  polyGptApi.middleware,
  projectIdeaGeneratorApi.middleware,
  collegeCreditsApi.middleware,
  siteContentApi.middleware,
  hermesProjectApi.middleware,
  podsApi.middleware,
];

export const store = getStoreWith({}, reducers, middlewares);
export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector = useSelector.withTypes<RootState>();
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
export const useAppDispatch = useDispatch.withTypes<ThunkAppDispatch>();

const isSocketReady = () => {
  return store.getState()?.hermes?.status === 'connected';
};

declare global {
  interface Window {
    Cypress: unknown;
    socketReady: boolean;
  }
}

if (window.Cypress) {
  store.subscribe(() => {
    if (isSocketReady()) {
      window.socketReady = true;
    }
  });
}
