import type { UUID } from '@polygence/common';
import { Button, Heading, Icon, Text, Tooltip } from '@polygence/components';
import classNames from 'classnames';
import type { CSSProperties } from 'react';
import { useRouteMatch } from 'react-router';

import DateWrapper, { DateFormat, TimeFormat } from 'src/components/aux/dateWrapper';
import { usePolyGPTContext } from 'src/polygpt/PolyGPTContext';
import styles from 'src/polygpt/PolyPilotProjectList.module.scss';
import { useListPolyPilotProjectsQuery } from 'src/reducers/polyGptReducers';
import { dayjs } from 'src/utils/dayjsCustom';

const getProjectDateFormat = (dateString: string) => {
  const date = new DateWrapper(dateString);
  const now = dayjs();

  if (now.diff(date.date, 'days') < 1) {
    return date.format(TimeFormat.FORMAT_3);
  } else {
    return date.format(DateFormat.FORMAT_10);
  }
};

export const PolyPilotProjectList = ({
  selectProject,
}: {
  selectProject: (id: number | UUID) => void;
}) => {
  const match = useRouteMatch<{ projectId: string }>('/polypilot/:projectId');

  const { data: projects = [] } = useListPolyPilotProjectsQuery();
  const { websocketConnection } = usePolyGPTContext();

  const handleCreateNewProject = () => {
    if (!websocketConnection) {
      return;
    }

    websocketConnection.send(
      JSON.stringify({
        type: 'polygpt.create_project',
      }),
    );
  };

  if (!projects.length) {
    return null;
  }

  return (
    <div className={classNames('d-flex flex-column gap-2 align-items-center', styles['container'])}>
      <div className="d-flex justify-content-between w-100 px-3 pt-5 pb-3">
        <Heading size="h5" alignment="left" className="my-5">
          My PolyPilot projects
        </Heading>
        <Tooltip tip="Start a new project" placement="top">
          <Button
            startIcon={<Icon id="plus" />}
            onClick={handleCreateNewProject}
            variant="secondary"
            size="sm"
            className="p-3 align-self-center"
            style={{ '--btn-transform-hover': 'none' } as CSSProperties}
          />
        </Tooltip>
      </div>
      {projects.map(({ id, uuid, title, createdAt }) => {
        return (
          <Button
            key={id}
            className={classNames(styles['projectButton'], {
              [styles['active'] as string]:
                match?.params?.projectId === String(id) || match?.params?.projectId === uuid,
            })}
            variant="link"
            size="sm"
            onClick={() => selectProject(uuid)}
            endIcon={
              <Text as="span" size="small" className="text-muted">
                {getProjectDateFormat(createdAt)}
              </Text>
            }
          >
            {title}
          </Button>
        );
      })}
    </div>
  );
};
