import type { Paginated, SearchParams } from '@polygence/common';
import { kebabCase } from 'lodash';

import { adminApiBase } from 'src/reducers/adminReducers';
import type {
  ActivePolyPilotProject,
  PolyPilotProjectWithCompletedStage,
  PromptContext,
  Stage,
  StageGroup,
  StageVersion,
  StageVersionRetrieve,
  ValidationDeliverable,
  ValidationDeliverableOutcome,
} from 'src/types/polygence/polygpt';

const adminApiWithTags = adminApiBase.enhanceEndpoints({
  addTagTypes: [
    'StageVersion',
    'StageVersionList',
    'ProjectsAwaitingReview',
    'ActivePolyPilotProjects',
  ],
});

export const polyPilotAdminApi = adminApiWithTags.injectEndpoints({
  endpoints: (build) => ({
    listStageVersions: build.query<StageVersion[], void>({
      query: () => ({
        url: `/polygpt/stage-versions/`,
        method: 'GET',
      }),
      providesTags: ['StageVersionList'],
    }),
    getActiveStageVersion: build.query<{ activeStageVersion: string }, void>({
      query: () => ({
        url: `/polygpt/stage-versions/active/`,
        method: 'GET',
      }),
    }),
    getStageVersion: build.query<StageVersionRetrieve, number>({
      query: (id) => ({
        url: `/polygpt/stage-versions/${id}/`,
        method: 'GET',
      }),
      providesTags: ['StageVersion'],
    }),
    createStageVersion: build.mutation<StageVersion, { name: string; copyFrom?: number }>({
      query: ({ name, copyFrom }) => ({
        url: `/polygpt/stage-versions/`,
        method: 'POST',
        data: { name },
        params: { copy_from: copyFrom },
      }),
      invalidatesTags: ['StageVersionList'],
    }),
    reorderGroupsStagesPrompts: build.mutation<
      boolean,
      { model: 'StageGroups' | 'Stages' | 'PromptContexts'; orderedIds: number[] }
    >({
      async queryFn(arg, _api, _extraOptions, baseQuery) {
        const { model, orderedIds } = arg;

        const baseUrl = `/polygpt/${kebabCase(model)}/`;

        const promises = orderedIds.map((id, index) => {
          return baseQuery({
            url: `${baseUrl}${id}/`,
            method: 'PATCH',
            data: { [model === 'Stages' ? 'number' : 'order']: index + 1 },
          });
        });

        const results = await Promise.allSettled(promises);

        const isSuccess = results.every((result) => result.status === 'fulfilled');

        return isSuccess ? { data: true } : { error: 'Failed to update order.' };
      },
      invalidatesTags: ['StageVersion'],
    }),
    createStageGroup: build.mutation<
      StageGroup,
      [number, { name: string; order: number; displayName: string }]
    >({
      query: ([versionId, { name, order, displayName }]) => ({
        url: `/polygpt/stage-groups/`,
        method: 'POST',
        data: { name, displayName, order, version: versionId },
      }),
      invalidatesTags: ['StageVersion'],
    }),
    updateStageGroup: build.mutation<StageGroup, [number, { name: string; displayName: string }]>({
      query: ([id, { name, displayName }]) => ({
        url: `/polygpt/stage-groups/${id}/`,
        method: 'PATCH',
        data: { name, displayName },
      }),
      invalidatesTags: ['StageVersion'],
    }),
    deleteStageGroup: build.mutation<void, number>({
      query: (id) => ({
        url: `/polygpt/stage-groups/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['StageVersion'],
    }),
    createStage: build.mutation<
      Stage,
      [
        number,
        number | null,
        {
          name: string;
          displayName: string;
          number: number;
          needsAdminApprove: boolean;
          validationDeliverable?: ValidationDeliverable;
        },
      ]
    >({
      query: ([
        versionId,
        groupId,
        { name, number, displayName, needsAdminApprove, validationDeliverable },
      ]) => ({
        url: `/polygpt/stages/`,
        method: 'POST',
        data: {
          name,
          displayName,
          number,
          stageGroup: groupId,
          version: versionId,
          needsAdminApprove,
          validationDeliverable,
        },
      }),
      invalidatesTags: ['StageVersion'],
    }),
    updateStage: build.mutation<
      Stage,
      [
        number,
        {
          name: string;
          displayName: string;
          needsAdminApprove: boolean;
          validationDeliverable?: ValidationDeliverable;
        },
      ]
    >({
      query: ([id, { name, displayName, needsAdminApprove, validationDeliverable }]) => ({
        url: `/polygpt/stages/${id}/`,
        method: 'PATCH',
        data: { name, displayName, needsAdminApprove, validationDeliverable },
      }),
      invalidatesTags: ['StageVersion'],
    }),
    deleteStage: build.mutation<void, number>({
      query: (id) => ({
        url: `/polygpt/stages/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['StageVersion'],
    }),
    createPromptContext: build.mutation<
      PromptContext,
      [number, { name: string; content: string; order: number }]
    >({
      query: ([stageId, { name, content, order }]) => ({
        url: `/polygpt/prompt-contexts/`,
        method: 'POST',
        data: { name, content, order, stage: stageId },
      }),
      invalidatesTags: ['StageVersion'],
    }),
    updatePromptContext: build.mutation<
      PromptContext,
      [number, Partial<{ name: string; content: string }>]
    >({
      query: ([id, payload]) => ({
        url: `/polygpt/prompt-contexts/${id}/`,
        method: 'PATCH',
        data: payload,
      }),
      invalidatesTags: ['StageVersion'],
    }),
    deletePromptContext: build.mutation<void, number>({
      query: (id) => ({
        url: `/polygpt/prompt-contexts/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['StageVersion'],
    }),
    listProjectsWithCompletedStage: build.query<
      Paginated<PolyPilotProjectWithCompletedStage>,
      SearchParams
    >({
      query: (params) => ({
        url: '/polygpt/projects/completed-stage/',
        method: 'GET',
        params: params,
      }),
      providesTags: ['ProjectsAwaitingReview'],
    }),
    getValidationDeliverableOutcome: build.query<ValidationDeliverableOutcome, number>({
      query: (projectStageId) => ({
        url: `/polygpt/validation-deliverable-outcomes/${projectStageId}`,
        method: 'GET',
      }),
    }),
    listActiveProjects: build.query<Paginated<ActivePolyPilotProject>, SearchParams>({
      query: (params) => ({
        url: '/polygpt/projects/active/',
        method: 'GET',
        params: params,
      }),
      providesTags: ['ActivePolyPilotProjects'],
    }),
    updatePolypilotProject: build.mutation<
      ActivePolyPilotProject,
      [number, Partial<ActivePolyPilotProject>]
    >({
      query: ([id, payload]) => ({
        url: `/polygpt/projects/${id}/`,
        method: 'PATCH',
        data: payload,
      }),
      invalidatesTags: ['ActivePolyPilotProjects'],
    }),
  }),
});

export const {
  useListStageVersionsQuery,
  useGetStageVersionQuery,
  useReorderGroupsStagesPromptsMutation,
  useCreateStageGroupMutation,
  useUpdateStageGroupMutation,
  useDeleteStageGroupMutation,
  useCreateStageMutation,
  useUpdateStageMutation,
  useDeleteStageMutation,
  useCreatePromptContextMutation,
  useUpdatePromptContextMutation,
  useDeletePromptContextMutation,
  useCreateStageVersionMutation,
  useGetActiveStageVersionQuery,
  useListProjectsWithCompletedStageQuery,
  useGetValidationDeliverableOutcomeQuery,
  useListActiveProjectsQuery,
  useUpdatePolypilotProjectMutation,
} = polyPilotAdminApi;
