/* eslint-disable react/jsx-max-depth */
import * as paymentApi from '@polygence/common/api/payment';
import {
  PaymentIntentInvoice,
  PaymentIntentStatuses,
  PaymentIntentTypes,
} from '@polygence/common/types/payment';
import { Alert, Button, Heading, Icon, Spacer, Text } from '@polygence/components';
import { captureException } from '@sentry/react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import { Loader } from 'src/components/Loader';
import { PageLoad } from 'src/components/PageLoad';
import { useModal } from 'src/components/useModal';
import { AmountOrPlaceholder } from 'src/payment/PaymentIntentV2/AmountOrPlaceholder';
import { PaymentBox } from 'src/payment/PaymentIntentV2/PaymentBox';
import { PaymentButtonMobile } from 'src/payment/PaymentIntentV2/PaymentButtonMobile';
import { PaymentIntentCanceled } from 'src/payment/PaymentIntentV2/PaymentIntentCanceled';
import { PaymentIntentDiscount } from 'src/payment/PaymentIntentV2/PaymentIntentDiscount';
import styles from 'src/payment/PaymentIntentV2/PaymentIntentPageV2.module.scss';
import { PaymentIntentProduct } from 'src/payment/PaymentIntentV2/PaymentIntentProduct';
import { PaymentTypeSelector } from 'src/payment/PaymentIntentV2/PaymentTypeSelector';
import { PaymentPageTerms } from 'src/payment/PaymentPageTerms';
import { trackProceed, trackSelectedProductChange } from 'src/payment/tracking';
import {
  useGetPaymentIntentQuery,
  useUpdatePaymentIntentMutation,
} from 'src/reducers/paymentReducer';
import { navigateToWithTimeout } from 'src/utils/navigateTo';

const mapIdToProductName = (id: number, products: PaymentIntentInvoice['pitchedProducts']) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return products.find((product) => product.id === id)!.name;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const PaymentIntentPageV2 = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const {
    data: paymentIntent,
    isLoading: isQueryInProgress,
    isError,
  } = useGetPaymentIntentQuery(uuid);
  const [updatePaymentIntent, { isLoading: isMutationInProgress }] =
    useUpdatePaymentIntentMutation();
  const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);
  const [isProceedDisabledDebounced, setIsProceedDisabledDebounced] = useState(false);

  const isProceedDisabled =
    isQueryInProgress || isMutationInProgress || isLoadingInvoice || isNavigating;

  const [RedirectModal, openRedirectModal, closeRedirectModal] = useModal();

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        setIsProceedDisabledDebounced(isProceedDisabled);
      },
      isProceedDisabled ? 200 : 50,
    );

    return () => clearTimeout(timeout);
  }, [isProceedDisabled]);

  useEffect(() => {
    if (paymentIntent?.redirectUrl) {
      openRedirectModal();
      navigateToUrl(paymentIntent.redirectUrl);
    } else if (paymentIntent?.firstPaymentReceivedAt && paymentIntent.invoiceUrl) {
      openRedirectModal();
      navigateToUrl(paymentIntent.invoiceUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentIntent]);

  const handleProductSelected = ({ id, selected }: { id: number; selected: boolean }) => {
    const previouslySelectedProducts = paymentIntent?.selectedProducts ?? [];
    if (!paymentIntent || (!selected && previouslySelectedProducts.length === 0)) {
      return;
    }

    const selectedProducts = selected
      ? [...previouslySelectedProducts, id]
      : previouslySelectedProducts.filter((product) => product !== id);

    trackSelectedProductChange({
      uuid: paymentIntent.uuid,
      selectedProducts: selectedProducts.map((id) =>
        mapIdToProductName(id, paymentIntent.pitchedProducts),
      ),
      previousSelectedProducts: previouslySelectedProducts.map((id) =>
        mapIdToProductName(id, paymentIntent.pitchedProducts),
      ),
    });

    void updatePaymentIntent([uuid, { selectedProducts }]);
  };

  const handleTypeChange = (type: PaymentIntentTypes) => {
    void updatePaymentIntent([uuid, { type }]);
  };

  const navigateToUrl = (url: string) => {
    setIsNavigating(true);
    navigateToWithTimeout(url, 1000);
  };

  const handleProceed = () => {
    if (!paymentIntent || isProceedDisabled) {
      return;
    }

    trackProceed({
      uuid: paymentIntent.uuid,
      selectedProducts: paymentIntent.selectedProducts.map((id) =>
        mapIdToProductName(id, paymentIntent.pitchedProducts),
      ),
      pitchedProducts: paymentIntent.pitchedProducts.map((product) => product.name),
      paymentMethod: paymentIntent.type,
    });

    openRedirectModal();
    setIsLoadingInvoice(true);
    void paymentApi
      .proceedToPaymentIntentInvoice(uuid)
      .then(({ data }) => {
        setIsLoadingInvoice(false);
        navigateToUrl(data.invoiceUrl);
      })
      .catch((e) => {
        captureException(e);
        closeRedirectModal();
        toast.error('Something went wrong.');
      })
      .finally(() => {
        setIsLoadingInvoice(false);
      });
  };

  if (isError) {
    return (
      <div className="container pt-11">
        <Alert variant="danger">Something went wrong.</Alert>
      </div>
    );
  }

  if (!paymentIntent) {
    return <PageLoad />;
  }

  if (
    paymentIntent.status === PaymentIntentStatuses.PENDING &&
    paymentIntent.firstPaymentReceivedAt &&
    paymentIntent.type === PaymentIntentTypes.SUBSCRIPTION
  ) {
    return (
      <div className={classNames(styles['pageContainer'], 'pt-11')}>
        <Heading size="h3" alignment="center" fontWeight="bold">
          This invoice is an ongoing subscription.
        </Heading>
        <Text size="medium" alignment="center" className="tw-my-4">
          {paymentIntent.description}
        </Text>
        <RedirectModal backdrop="static" centered>
          <div className="d-flex flex-column gap-3 p-8 justify-content-center align-items-center">
            <Loader />
            {isNavigating && <Text size="medium">Redirecting...</Text>}
          </div>
        </RedirectModal>
        <PaymentPageTerms />
      </div>
    );
  }

  if (paymentIntent.firstPaymentReceivedAt) {
    return (
      <div className={classNames(styles['pageContainer'], 'pt-11')}>
        <Heading size="h3" alignment="center" fontWeight="bold">
          This invoice is already paid.
        </Heading>
        <Text size="medium" alignment="center" className="tw-my-4">
          {paymentIntent.description}
        </Text>
        <RedirectModal backdrop="static" centered>
          <div className="d-flex flex-column gap-3 p-8 justify-content-center align-items-center">
            <Loader />
            {isNavigating && <Text size="medium">Redirecting...</Text>}
          </div>
        </RedirectModal>
        <PaymentPageTerms />
      </div>
    );
  }

  if (paymentIntent.status === PaymentIntentStatuses.CANCELED) {
    return <PaymentIntentCanceled />;
  }

  return (
    <>
      <div className={classNames(styles['pageContainer'], 'pt-11')}>
        <Heading className="mb-4" as="h1" size="h3" alignment="center" fontWeight="bold">
          Please verify your payment amount below
        </Heading>
        <div className="mx-auto w-75">
          <Text size="large" alignment="center" fontWeight="light" className="px-6 px-xl-11">
            {paymentIntent.description}
          </Text>
        </div>
        <Spacer size={10} />
        <div className="container">
          <div
            className={classNames(styles['container'], {
              [styles['hasNoProducts'] as string]: paymentIntent.pitchedProducts.length === 0,
            })}
          >
            <div className={styles['details']}>
              {paymentIntent.pitchedProducts.length > 0 && (
                <PaymentBox className={styles['products']}>
                  <Heading size="h5" as="h2" alignment="left" className="mb-6">
                    Your package includes
                  </Heading>
                  <div className="d-flex flex-column gap-5">
                    {paymentIntent.pitchedProducts.map((product) => {
                      return (
                        <PaymentIntentProduct
                          product={product}
                          key={product.id}
                          isSelected={paymentIntent.selectedProducts?.includes(product.id) ?? false}
                          onChange={handleProductSelected}
                        />
                      );
                    })}
                  </div>
                </PaymentBox>
              )}
              {paymentIntent.discounts.length > 0 && (
                <PaymentBox className={styles['discounts']}>
                  <Heading size="h5" as="h2" alignment="left" className="mb-6">
                    Discounts
                  </Heading>
                  {paymentIntent.discounts.map((discount) => {
                    return (
                      <PaymentIntentDiscount
                        amount={discount.amount}
                        name={discount.name}
                        key={discount.name}
                      />
                    );
                  })}
                </PaymentBox>
              )}
              {(paymentIntent.pitchedProducts.length > 0 || paymentIntent.discounts.length > 0) && (
                <PaymentBox className={styles['total']}>
                  {paymentIntent.type === PaymentIntentTypes.SUBSCRIPTION &&
                    paymentIntent.riskPremium && (
                      <div className="d-flex align-items-center mb-6">
                        <Text size="medium" fontWeight="bold" alignment="left" className="d-inline">
                          Monthly installment interest ({paymentIntent.riskPremium}%)
                        </Text>
                        <div className="ms-auto">
                          <AmountOrPlaceholder
                            amount={paymentIntent.riskPremiumAmount}
                            size="medium"
                          />
                        </div>
                      </div>
                    )}
                  <div className="d-flex align-items-center">
                    <Heading size="h5" as="h2" alignment="left" className="d-inline">
                      Total
                    </Heading>
                    <div className="ms-auto">
                      <AmountOrPlaceholder amount={paymentIntent.amount} size="medium" />
                    </div>
                  </div>
                </PaymentBox>
              )}
            </div>
            <div className={styles['type']}>
              <PaymentTypeSelector paymentIntent={paymentIntent} onChange={handleTypeChange}>
                <div className="d-none d-md-flex justify-content-center">
                  <Button
                    variant="primary"
                    disabled={isProceedDisabledDebounced}
                    onClick={handleProceed}
                    endIcon={<Icon id="chevron-right" />}
                  >
                    Proceed with payment
                  </Button>
                </div>
              </PaymentTypeSelector>
            </div>
          </div>
        </div>
        <PaymentPageTerms />
        <div className="d-block d-md-none">
          <Spacer size={12} />
          <Spacer size={4} />
          <PaymentButtonMobile
            handleProceed={handleProceed}
            isProceedDisabled={isProceedDisabledDebounced}
          />
        </div>
      </div>
      <RedirectModal backdrop="static" centered>
        <div className="d-flex flex-column gap-3 p-8 justify-content-center align-items-center">
          <Loader />
          {isLoadingInvoice && <Text size="medium">Generating invoice...</Text>}
          {isNavigating && <Text size="medium">Redirecting...</Text>}
        </div>
      </RedirectModal>
    </>
  );
};
