import { useIsActiveStagePending } from 'src/polygpt/useIsActiveStagePending';
import { useGetPolyPilotProjectDetailsQuery } from 'src/reducers/polyGptReducers';

export const useIsProjectLocked = (projectId: number) => {
  const isActiveStagePending = useIsActiveStagePending(projectId);
  const { isProjectUnlocked } = useGetPolyPilotProjectDetailsQuery(projectId, {
    selectFromResult: ({ data }) => {
      return {
        isProjectUnlocked: Boolean(data?.unlockedAt),
      };
    },
  });

  return isActiveStagePending && !isProjectUnlocked;
};
