import type { PaymentIntentInvoice } from '@polygence/common/types/payment';
import { Text } from '@polygence/components';

import { AmountOrPlaceholder } from 'src/payment/PaymentIntentV2/AmountOrPlaceholder';

export const PaymentIntentDiscount = ({
  name,
  amount,
}: Partial<PaymentIntentInvoice['discounts'][number]>) => {
  return (
    <div className="d-flex">
      <Text size="medium" alignment="left" className="d-inline" fontWeight="bold">
        {name}
      </Text>
      <div className="d-flex ms-auto">
        -
        <AmountOrPlaceholder amount={amount} />
      </div>
    </div>
  );
};
