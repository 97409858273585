import { useHistory, useLocation } from 'react-router';
import { useSwipeable } from 'react-swipeable';

export const PolyPilotNavigationTabs = {
  ProjectSelector: 'projects',
  Chat: 'chat',
  Details: 'details',
} as const;

export type PolyPilotNavigationTab =
  (typeof PolyPilotNavigationTabs)[keyof typeof PolyPilotNavigationTabs];

const DEFAULT_TAB = PolyPilotNavigationTabs.Chat;

const isValidTab = (tab: string): tab is PolyPilotNavigationTab => {
  return Object.values(PolyPilotNavigationTabs).includes(tab as PolyPilotNavigationTab);
};

export const getTabs = (showProjectSelector: boolean) => {
  const tabs: PolyPilotNavigationTab[] = [
    PolyPilotNavigationTabs.Chat,
    PolyPilotNavigationTabs.Details,
  ];

  if (showProjectSelector) {
    tabs.unshift(PolyPilotNavigationTabs.ProjectSelector);
  }

  return tabs;
};

const getActiveTab = (hash: string, tabs: PolyPilotNavigationTab[]) => {
  if (!hash) {
    return DEFAULT_TAB;
  }

  const tabNameFromHash = hash.startsWith('#') ? hash.substring(1) : hash;

  if (!isValidTab(tabNameFromHash) || !tabs.includes(tabNameFromHash)) {
    return DEFAULT_TAB;
  }

  return tabNameFromHash;
};

export const usePolyPilotMobileNavigation = ({
  showProjectSelector,
}: {
  showProjectSelector: boolean;
}) => {
  const { hash } = useLocation();
  const tabs = getTabs(showProjectSelector);

  const activeTab = getActiveTab(hash, tabs);

  const history = useHistory();

  const onNextIndex = (change: -1 | 1) => {
    const activeTabIndex = tabs.indexOf(activeTab);
    const nextIndex = activeTabIndex + change;
    const isNextInBounds = 0 <= nextIndex && nextIndex <= tabs.length - 1;

    if (isNextInBounds) {
      history.push({ ...history.location, hash: tabs[nextIndex] });
    }
  };

  const swipeControls = useSwipeable({
    onSwipedLeft: () => onNextIndex(1),
    onSwipedRight: () => onNextIndex(-1),
    preventScrollOnSwipe: true,
    trackMouse: false,
    trackTouch: true,
  });

  return { registerSwipeControls: swipeControls, activeTab, tabs };
};
