/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Button, Icon, Text, Tooltip } from '@polygence/components';
import classNames from 'classnames';
import type { ComponentProps, ReactNode } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import rehypeExternalLinks from 'rehype-external-links';
import rehypeMathjax from 'rehype-mathjax/svg';
import remarkMath from 'remark-math';

import { Loader } from 'src/components/Loader';
import { ReactMarkdown } from 'src/components/aux/markdown/ReactMarkdown';
import { SyntaxHighlighter } from 'src/components/aux/markdown/SyntaxHighlighter';
import PolygenceLogoNew from 'src/components/static/images/logo/PolygenceLogoNew.svg';
import 'src/polygpt/PolyGPTMessage.scss';
import { useIsPolyGptAdmin } from 'src/polygpt/debug-features/useIsPolyGptAdmin';
import { useIsPolyPilotTester } from 'src/polygpt/debug-features/useIsPolyPilotTester';
import {
  polyGptApi,
  useCloneProjectMutation,
  useGetPolyPilotProjectDetailsQuery,
} from 'src/reducers/polyGptReducers';
import { useAppDispatch } from 'src/store';
import type { Message, ToolCall } from 'src/types/polygence/polygpt';

const markdownComponents = {
  pre: SyntaxHighlighter,
};

const REMARK_PLUGINS = [remarkMath];
const REHYPE_PLUGINS: ComponentProps<typeof ReactMarkdown>['rehypePlugins'] = [
  rehypeMathjax,
  [rehypeExternalLinks, { target: ['_blank'] }],
];

const toolCallLabels: Record<string, string> = {
  search_research_papers: 'Searching for research papers...',
};

const ToolCallMessage = ({ toolCalls }: { toolCalls: ToolCall[] }) => {
  const toolCallsToDisplay = toolCalls
    .map((toolCall) => ({
      id: toolCallLabels[toolCall.function?.id],
      label: toolCallLabels[toolCall.function?.name],
    }))
    .filter((toolCall) => Boolean(toolCall.label));

  if (toolCallsToDisplay.length === 0) {
    return null;
  }

  return (
    <div className="d-flex flex-column gap-3">
      {toolCallsToDisplay.map((toolCall) => (
        <Text key={toolCall.id} size="small" fontWeight="bold" className="polyGptToolCallMessage">
          {toolCall.label}
        </Text>
      ))}
    </div>
  );
};

const getMessageType = (type: string, name?: string) => {
  if (type !== 'user') {
    return type;
  }

  return name === 'Mentor' ? 'mentor' : 'user';
};

const MentorMessageWrapper = ({
  isMentorMessage,
  projectId,
  children,
}: {
  isMentorMessage: boolean;
  projectId?: number;
  children: ReactNode;
}) => {
  const { data: project } = useGetPolyPilotProjectDetailsQuery(projectId ?? 0, {
    skip: !projectId,
  });

  return isMentorMessage ? (
    <>
      <Text size="small" fontWeight="bold" className="ms-n3 my-3">
        <img src={PolygenceLogoNew} alt="Polygence logo" className="me-2" /> Polygence Mentor
        feedback
      </Text>
      <div className="mentor-inner mb-6">{children}</div>
      {project?.mentorFirstName && (
        <Text size="small" fontWeight="bold" className="mt-n5 mb-0" alignment="right">
          {project.mentorFirstName}
        </Text>
      )}
      {project?.mentorTitle && (
        <Text size="small" fontWeight="normal" className="mb-3" alignment="right">
          {project.mentorTitle}
        </Text>
      )}
    </>
  ) : (
    <>{children}</>
  );
};

export const PolyGPTMessage = ({
  message: messageProp,
  loading = false,
  className,
  projectId,
}: {
  message: Message;
  projectId?: number;
  loading?: boolean;
  className?: string;
}) => {
  const isPolyGptAdmin = useIsPolyGptAdmin();
  const isPolyPilotTester = useIsPolyPilotTester();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { id, type, message, name } = messageProp;
  const [cloneProject] = useCloneProjectMutation();

  const messageType = getMessageType(type, name);

  const handleCloneButtonClick = () => {
    if (id && projectId) {
      cloneProject({ projectId, messageId: id })
        .unwrap()
        .then(({ uuid }) => {
          history.push({ pathname: `/polypilot/${uuid}/` });
          void dispatch(
            polyGptApi.endpoints.listPolyPilotProjects.initiate(undefined, { forceRefetch: true }),
          );
          toast.success('Successfully cloned conversation.');
        })
        .catch(() => {
          toast.error('Clone failed.');
        });
    }
  };

  const isAssistantMessage = type === 'assistant';
  const isToolCallMessage =
    !messageProp.message && messageProp.toolCalls && messageProp.toolCalls?.length > 0;

  if (isToolCallMessage) {
    return <ToolCallMessage toolCalls={messageProp.toolCalls as ToolCall[]} />;
  }

  return (
    <div
      className={classNames('polyGptMessage', messageType, className)}
      id={messageProp.id ? `message-${messageProp.id}` : undefined}
    >
      <MentorMessageWrapper isMentorMessage={messageType === 'mentor'} projectId={projectId}>
        <ReactMarkdown
          components={markdownComponents}
          remarkPlugins={REMARK_PLUGINS}
          rehypePlugins={REHYPE_PLUGINS}
        >
          {message}
        </ReactMarkdown>
      </MentorMessageWrapper>
      {loading && <Loader className="loader" />}
      {(isPolyGptAdmin || isPolyPilotTester) && isAssistantMessage && id && projectId && (
        <div className="cloneButton">
          <Tooltip placement="top" tip="Start a new project from here.">
            <Button
              className="p-2"
              variant="link"
              endIcon={<Icon id="repeat" />}
              size="sm"
              onClick={handleCloneButtonClick}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};
