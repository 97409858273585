/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';
import { Button, Icon, InputField, Modal, Text } from '@polygence/components';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { usePolyGPTContext } from 'src/polygpt/PolyGPTContext';
import { useGetPolyPilotProjectDetailsQuery } from 'src/reducers/polyGptReducers';

export const MentorMessageModal = NiceModal.create(
  ({ projectId }: { projectId: string | number }) => {
    const [message, setMessage] = useState('');
    const modal = useModal();
    const { data: project } = useGetPolyPilotProjectDetailsQuery(projectId);
    const { websocketConnection } = usePolyGPTContext();
    const [isLoading, setIsLoading] = useState(false);

    const projectTitle = project?.title;
    const studentName = project?.user?.fullName;

    useEffect(() => {
      if (!websocketConnection) {
        return;
      }

      function handleMessage(event: MessageEvent) {
        const data = JSON.parse(event.data);

        if (data.type === 'polygpt.mentor_message.success') {
          setIsLoading(false);
          toast.success('Message registered!');
          modal.hide().catch(console.error);
        }

        if (data.type === 'polygpt.mentor_message.error') {
          toast.error('Something went wrong.');
          setIsLoading(false);
        }
      }

      websocketConnection.addEventListener('message', handleMessage);

      return () => websocketConnection.removeEventListener('message', handleMessage);
    }, [websocketConnection, modal]);

    const sendMessage = () => {
      if (isLoading || !websocketConnection) {
        return;
      }

      setIsLoading(true);

      websocketConnection.send(
        JSON.stringify({
          type: 'polygpt.mentor_message',
          user_message: message,
          project_id: projectId,
        }),
      );
    };

    return (
      <Modal {...bootstrapDialog(modal)} body backdrop={isLoading ? 'static' : true}>
        <div className="d-flex flex-column gap-4">
          <section className="bg-grayscale-3 rounded p-4">
            <Text size="medium" fontWeight="bold">
              {projectTitle}
            </Text>
            <Text size="medium" fontWeight="light">
              {studentName}
            </Text>
          </section>
          <section>
            <InputField
              label="Feedback"
              floating={false}
              name="feedback"
              type="textarea"
              rows={15}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </section>
          <section className="d-flex justify-content-end gap-3">
            <Button
              variant="primary"
              endIcon={isLoading ? <Icon id="loader" className="rotate" /> : <></>}
              onClick={() => sendMessage()}
              disabled={isLoading}
            >
              Send
            </Button>
          </section>
        </div>
      </Modal>
    );
  },
);
