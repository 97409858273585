export const addInviteeInformationToCalendlyUrl = (
  calendlyUrl: string,
  inviteeInformation: Partial<{ name: string; email: string }>,
) => {
  const url = new URL(calendlyUrl);
  const entries = Object.entries(inviteeInformation);
  for (const [key, value] of entries) {
    url.searchParams.append(key, value);
  }
  return url.toString();
};
